<div>
  <div class="d-flex flex-row columnTitle">
    <span class="titleText">Users</span>      
  </div>
  <div class="d-flex flex-row columnSeparator"></div>  
    <input style="margin: 5px; width: 96%;" type="text" (keyup)="searchArray($event)"/>
  <div class="d-flex flex-row columnSeparator"></div>  
  <div cdkDropList cdkDropListSortingDisabled class="d-flex flex-column columnListContainer list listContainer" *ngIf="isUsersLoaded" [cdkDropListData]="userArray">
    <div cdkDrag class="userListArray" *ngFor="let ua of userArray" (cdkDragStarted)="dragStarted(ua.userName)" [cdkDragData]="ua">
      <table class="userListItem" (click)="onClick(ua.userName)">
        <tr>
          <td rowspan="3" class="imageCell">
            <img class="profileimage" src="{{ userAssignmentService.ParseImageUrl(ua) }}" />
          </td>
          <td cell="textCell">
            <span class="titleText">{{ ua.userName }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex flex-row columnSeparator"></div>
          </td>
        </tr>
        <tr>
          <td cell="textCell">
            <span class="titleText">{{ ua.role }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="d-flex flex-row quesTopNavCell w-100">
  <div
    class="d-flex flex-column col-2 justify-items-center p-0 quesLogoCell"
    style="align-self: center"
  >
    <img
      class="quesLogo"
      style="width: 135px; align-self: center"
      src="{{ environmentUrl }}/assets/images/queslogofinal.png"
    />
  </div>
  <div class="d-flex flex-column w-100">
    <div ngbDropdown class="d-inline-block ml-auto">
      <img
        style="cursor: pointer"
        class="profileimage"
        src="{{
          avatarImage !== null
            ? 'data:image/JPEG;base64,' + avatarImage
            : environmentUrl + '/assets/images/avatars/noavatar.jpg'
        }}"
        id="accountActions"
        ngbDropdownToggle
      />
      <span>{{ fullName }}</span>
      <div ngbDropdownMenu aria-labelledby="accountActions">
        <button ngbDropdownItem (click)="onAccActClick('myaccount')">
          My Account
        </button>
        <button ngbDropdownItem (click)="onAccActClick('linkmenu')">
          Return to Portal
        </button>
        <button ngbDropdownItem (click)="onSupportClick()">Support</button>
        <button ngbDropdownItem (click)="onLogoutClick()">Log Out</button>
      </div>
    </div>
  </div>
</div>
<kendo-window
  *ngIf="showSupport"
  (close)="onCloseClick()"
  class="panel panel-primary siPanel"
>
  <kendo-window-titlebar>
    <div class="k-window-title">
      <span class="fa fa-support"></span> Support Information
    </div>
    <button kendoWindowMaximizeAction></button>
    <button kendoWindowRestoreAction></button>
  </kendo-window-titlebar>
  <div class="panel-body">
    <div class="siTextRow m-2">
      <span class="siTextRow m-2"> Email: </span>
      <a
        class="queslink supportInformation"
        href="mailto:support@quantawestllc.com"
        target="_blank"
        >Support@QuantaWestLLC.com</a
      >
    </div>
    <div class="siTextRow m-2">
      Contact: <a class="queslink" href="tel:+18666111136">+1 (866) 611-1136</a>
    </div>
    <div class="siTextRow m-2">
      Service Desk:
      <a
        class="queslink supportInformation"
        href="https://support.quantawestllc.com/"
        target="_blank"
        >https://support.quantawestllc.com/</a
      >
    </div>
    <div class="siTextRow m-2">Support Hours: Monday-Friday 6:00am-6:00pm</div>
    <div class="m-2" style="padding-top: 10px">
      <button type="button" class="btn btn-download" (click)="onCloseClick()">
        Close
      </button>
    </div>
  </div>
</kendo-window>

<div class="d-flex flex-row justify-content-center align-items-center h-100">
  <div class="loginContainer">
    <img
      class="qgatelogo"
      src="{{ environmentUrl }}/assets/images/qgateLogo.png"
    />
    <div class="panel panel-primary loginPanel">
      <div class="panel-body">
        <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="unTxt" class="control-label loginText">Username</label>
            <div>
              <input
                #unTxt="ngModel"
                class="form-control"
                name="unTxt"
                placeholder="Username"
                type="text"
                required
                [(ngModel)]="userCredentials.userName"
              />
            </div>
            <div
              class="alert alert-danger"
              [hidden]="unTxt.valid || unTxt.untouched"
            >
              User name is required!
            </div>
          </div>
          <div class="form-group">
            <label for="pwTxt" class="control-label loginText">Password</label>
            <div>
              <input
                #pwTxt="ngModel"
                class="form-control"
                minlength="6"
                name="pwTxt"
                type="password"
                placeholder="Password"
                required
                [(ngModel)]="userCredentials.password"
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="pwTxt.invalid && pwTxt.dirty && !pwTxt.errors.minlength"
            >
              Password is required!
            </div>
            <div class="alert alert-warning" *ngIf="pwTxt.errors?.minlength">
              Requires a minimum of 6 to 12 characters.
            </div>
          </div>
          <div class="form-group" [hidden]="!recaptchaShow">
            <div #recaptcha></div>
          </div>
          <div class="d-flex align-items-center">
            <div class="checkbox checkBoxTextAdjust ml-2">
              <input id="remember" type="checkbox" name="remember" />
            </div>
            <div class="loginText ml-2">Remember Me</div>
            <div class="ml-auto mr-2">
              <button
                type="submit"
                class="btn btn-primary btn-focushover loginButton loginText"
                [disabled]="!loginForm.form.valid"
              >
                Log In
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="sdTab">
  <img src="{{ environmentUrl }}/assets/images/sdicon.png" /><span class="ml-3"
    >Service Desk</span
  >
</div>

import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { takeWhile } from 'rxjs/internal/operators';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'my-account',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyAccountComponent implements OnInit {

  userName:string;
  private alive:boolean = true;
  constructor(private appService:AppService) { }

  ngOnInit() {
    this.GetUserProfile();
  }


  GetUserProfile = () => {
    this.appService.GetUserProfileObservable()
      .pipe(takeWhile(() =>this.alive))
      .subscribe((profile: UserProfile) => this.userName = profile.userName)}
  
  ngOnDestroy(): void {
    this.alive = false;
  }
}

import { AppService } from '../../app.service';
import { Component, OnInit } from '@angular/core';
import { NewUserService } from '../../newuser/newuser.service';
import { Router } from '@angular/router';
import { Toastr } from '../../common/toastr.service';

@Component({
  selector: "side-nav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  constructor(
    private router: Router,
    private appSrv: AppService,
    private toastr: Toastr,
    private userService: NewUserService
  ) {}

  public menuItems: Array<Object> = [
    {
      icon: "fa-home",
      text: "Dashboard",
      adminOnly: false,
    },
    /* { 
      icon : "fa-registered",
      text : "Register User",
      adminOnly : true
    }, */
    {
      icon: "fa-address-card",
      text: "Manage Users",
      adminOnly: true,
    },
    {
      icon: "fa-user-circle",
      text: "Account",
      adminOnly: false,
    },
    {
      icon: "fa-user-plus",
      text: "New user",
      adminOnly: true,
    },
    {
      icon: "fa-info-circle",
      text: "Service Desk",
      use: function () {
        window.open("https://support.quantawestllc.com/", "_blank");
      },
      adminOnly: false,
    },
    /* { 
      icon : "fa-question-circle",
      text : "User Assignment",
      adminOnly : true
    },
    { 
      icon : "fa-question-circle",
      text : "Role Assignment",
      adminOnly : true
    }, */
  ];
  public userProfile: any;
  public isWebAdmin: boolean = false;

  ngOnInit() {
    this.appSrv
      .GetUserProfileObservable()
      .subscribe((upo) => (this.userProfile = upo));
    setTimeout(() => {
      this.getCurrentUser();
    }, 100);
  }

  goToSupportDesk() {
    window.open("https://support.quantawestllc.com/", "_blank");
  }

  validateAdminRole = (adminOnly) => {
    if (adminOnly) {
      return this.isWebAdmin ? true : false;
    } else {
      return true;
    }
  };

  getCurrentUser = () => {
    //console.log(this.appSrv.GetUserProfileObservable());
    /* const userSubscription = this.appSrv.GetUserProfileObservable().subscribe({
      next(data) { return data.jwt }
    }) */
    this.userService
      .GetCurrentUserInfo(this.userProfile.userName, false)
      .subscribe(
        (success) => {
          success.step1.portalRole === "WebAdmin"
            ? (this.isWebAdmin = true)
            : null;
        },
        (failure) => {
          this.toastr.Clear();
        }
      );
  };

  navigateSideNav(e) {
    var location = e.currentTarget.innerText.trim().toLowerCase();
    switch (location) {
      case "account":
        this.router.navigate(["myaccount"]);
        break;
      case "dashboard":
        this.router.navigate(["linkmenu"]);
        break;
      case "register user":
        this.router.navigate(["usermanagement"]);
        break;
      case "manage users":
        this.router.navigate(["manageusers"]);
        break;
      case "user assignment":
        this.router.navigate(["userassignment"]);
        break;
      case "new user":
        this.router.navigate(["newuser"]);
        break;
      case "service desk":
        this.goToSupportDesk();
        break;
      case "role assignment":
        this.router.navigate(["roleassignment"]);
        break;
    }
  }
}

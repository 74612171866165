import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';    
import { Injectable } from '@angular/core';    
import { Observable } from 'rxjs';    
import { Router } from '@angular/router';    
    
@Injectable()    
export class AuthInterceptor implements HttpInterceptor{    
    
    constructor(private router:Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(req.headers.get('Anonymous') == 'true') {    
            return next.handle(req.clone());    
        }       

        if(sessionStorage.getItem('jwt') != null) {    
            const clonedReq = req.clone({    
                headers:req.headers.set("Authorization", "Bearer " + sessionStorage.getItem('jwt'))    
            });

            return next.handle(clonedReq);    
        } else {    
            this.router.navigateByUrl("/login");    
        }    
    }    
} 
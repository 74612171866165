/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./userlist.component.scss.shim.ngstyle";
import * as i1 from "../../userassignment/userassignment.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/cdk/drag-drop";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./userlist.component";
import * as i7 from "../../app.service";
import * as i8 from "../../common/console.log.service";
import * as i9 from "../../common/toastr.service";
import * as i10 from "../../userassignment/userassignment.service";
var styles_UserListComponent = [i0.styles, i1.styles];
var RenderType_UserListComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_UserListComponent, data: {} });
export { RenderType_UserListComponent as RenderType_UserListComponent };
function View_UserListComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 16777216, null, null, 19, "div", [["cdkDrag", ""], ["class", "userListArray cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], [[null, "cdkDragStarted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDragStarted" === en)) {
        var pd_0 = (_co.dragStarted(_v.context.$implicit.userName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i2.ɵprd(6144, null, i3.ɵb, null, [i3.CdkDrag]), i2.ɵdid(2, 4866048, [[1, 4]], 3, i3.CdkDrag, [i2.ElementRef, [3, i3.CDK_DROP_LIST], i4.DOCUMENT, i2.NgZone, i2.ViewContainerRef, i3.CDK_DRAG_CONFIG, [2, i5.Directionality], i3.DragDrop, i2.ChangeDetectorRef], { data: [0, "data"] }, { started: "cdkDragStarted" }), i2.ɵqud(603979776, 2, { _handles: 1 }), i2.ɵqud(603979776, 3, { _previewTemplate: 0 }), i2.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i2.ɵeld(6, 0, null, null, 13, "table", [["class", "userListItem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_v.context.$implicit.userName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "td", [["class", "imageCell"], ["rowspan", "3"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 0, "img", [["class", "profileimage"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 2, "td", [["cell", "textCell"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 1, "span", [["class", "titleText"]], null, null, null, null, null)), (_l()(), i2.ɵted(12, null, ["", ""])), (_l()(), i2.ɵeld(13, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 0, "div", [["class", "d-flex flex-row columnSeparator"]], null, null, null, null, null)), (_l()(), i2.ɵeld(16, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i2.ɵeld(17, 0, null, null, 2, "td", [["cell", "textCell"]], null, null, null, null, null)), (_l()(), i2.ɵeld(18, 0, null, null, 1, "span", [["class", "titleText"]], null, null, null, null, null)), (_l()(), i2.ɵted(19, null, ["", ""])), (_l()(), i2.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵnov(_v, 2).disabled; var currVal_1 = i2.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i2.ɵinlineInterpolate(1, "", _co.userAssignmentService.ParseImageUrl(_v.context.$implicit), ""); _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.userName; _ck(_v, 12, 0, currVal_4); var currVal_5 = _v.context.$implicit.role; _ck(_v, 19, 0, currVal_5); }); }
function View_UserListComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 6, "div", [["cdkDropList", ""], ["cdkDropListSortingDisabled", ""], ["class", "d-flex flex-column columnListContainer list listContainer cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], null, null, null, null)), i2.ɵprd(6144, null, i3.CDK_DROP_LIST, null, [i3.CdkDropList]), i2.ɵdid(2, 1196032, null, 1, i3.CdkDropList, [i2.ElementRef, i3.DragDrop, i2.ChangeDetectorRef, [2, i5.Directionality], [3, i3.CdkDropListGroup]], { data: [0, "data"], sortingDisabled: [1, "sortingDisabled"] }, null), i2.ɵqud(603979776, 1, { _draggables: 1 }), i2.ɵprd(256, null, i3.CdkDropListGroup, undefined, []), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_UserListComponent_2)), i2.ɵdid(6, 278528, null, 0, i4.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.userArray; var currVal_5 = ""; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = _co.userArray; _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i2.ɵnov(_v, 2).id; var currVal_1 = i2.ɵnov(_v, 2).disabled; var currVal_2 = i2.ɵnov(_v, 2)._dropListRef.isDragging(); var currVal_3 = i2.ɵnov(_v, 2)._dropListRef.isReceiving(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_UserListComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 2, "div", [["class", "d-flex flex-row columnTitle"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "span", [["class", "titleText"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Users"])), (_l()(), i2.ɵeld(4, 0, null, null, 0, "div", [["class", "d-flex flex-row columnSeparator"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 0, "input", [["style", "margin: 5px; width: 96%;"], ["type", "text"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.searchArray($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "div", [["class", "d-flex flex-row columnSeparator"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_UserListComponent_1)), i2.ɵdid(8, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUsersLoaded; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_UserListComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "userlist", [], null, null, null, View_UserListComponent_0, RenderType_UserListComponent)), i2.ɵdid(1, 114688, null, 0, i6.UserListComponent, [i7.AppService, i8.ConsoleLogger, i9.Toastr, i10.UserAssignmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserListComponentNgFactory = i2.ɵccf("userlist", i6.UserListComponent, View_UserListComponent_Host_0, { adminAssign: "adminAssign", adminSelect: "adminSelect", isUsersLoaded: "isUsersLoaded", userArray: "userArray", userDetails: "userDetails", productAssign: "productAssign", isRole: "isRole" }, { adminAssignChange: "adminAssignChange", adminSelectChange: "adminSelectChange", userArrayChange: "userArrayChange", productAssignChange: "productAssignChange" }, []);
export { UserListComponentNgFactory as UserListComponentNgFactory };

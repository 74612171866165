import {takeWhile} from 'rxjs/internal/operators';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppService } from '../app.service';
import { UserProfile } from 'src/models/interfaces/user.profile';
import { Observable } from 'rxjs';
import { IMenuLink } from 'src/models/interfaces/IMenuLink';
import { Environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ɵNgStyleImpl } from '@angular/common';
import { ProjectRequestService } from './projectrequest.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder } from '@angular/forms';
import { Toastr } from '../common/toastr.service';
import { TopnavComponent } from '../dashboard/topnav/topnav.component';

@Component({
  selector: 'projectrequest',
  templateUrl: './projectrequest.component.html',
  styleUrls: ['./projectrequest.component.scss']
})
export class ProjectRequestComponent implements OnInit {

  private userProfile:any;
  public linkMenuObsersable$: Observable<IMenuLink>;
  private alive:boolean = true;
  private formFields:Array<any> = [];
  public sampleObject:any = {
    entries: []
  };
  public editing:boolean = false;
  public editingEnabled:boolean = true;
  public projectRequest;
  public entryEdit;
  public editPanel:boolean = false;
  public currentEntry:any;
  public fileUpload:Array<File> = [];
  public submitDisabled:boolean = true;

  constructor(
    private appService: AppService,
    private router: Router,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: Toastr,
    private projectRequestService: ProjectRequestService) { 
      this.projectRequest = this.formBuilder.group({
        id: '',
        index: '',
        customer: '',
        description: '',
        assetType: '',
        estimatedDate: '',
        jobCode: '',
        contactedIT: '',
        assetNumber: '',
        assetBool: '',
        assetData: null,
        contactName: '',
        contactEmail: '',
        contactNumber: '',
        contractBool: '',
        contract: null,
        rfpBool: '',
        rfp: null,
        notes: '',
        submittedBy: ''
      });
      this.entryEdit = this.formBuilder.group({
        id: '',
        index: '',
        customer: '',
        description: '',
        assetType: '',
        estimatedDate: '',
        jobCode: '',
        contactedIT: '',
        assetNumber: '',
        assetBool: '',
        assetData: '',
        contactName: '',
        contactEmail: '',
        contactNumber: '',
        contractBool: '',
        contract: '',
        rfpBool: '',
        rfp: '',
        notes: ''
      });
    }

  ngOnInit() {
    this.getEntries();
    this.appService.GetUserProfileObservable()
    .subscribe(upo => this.userProfile = upo);
  }

  getFormFields = () => {
    this.sampleObject = this.projectRequestService.GetRows();
  };

  getEntries = () => {
    this.sampleObject.entries = [];
    this.projectRequestService.GetRequests().subscribe(
      success => {
        success.table.forEach( (row, index) => {
          this.sampleObject.entries.push(row);
          row.contact != null ? this.sampleObject.entries[index].contactedIT = row.contact : null;
          row.date != null ? this.sampleObject.entries[index].estimatedDate = row.date : null;
        });
      },
      failure => {
        console.log(failure);
      }
    )
  };

  renderFormFields = (field) => {

  };

  drop(event: CdkDragDrop<string[]>) {
    var previousIndex = this.sampleObject.entries[event.previousIndex].index;
    var previousId = this.sampleObject.entries[event.previousIndex].id;

    var currentIndex = this.sampleObject.entries[event.currentIndex].index;
    var currentId = this.sampleObject.entries[event.currentIndex].id;

    var data = {
      firstRow: {
        Id : previousId,
        Index : previousIndex
      },
      secondRow: {
        Id : currentId,
        Index : currentIndex
      }
    };
    this.projectRequestService.ReorderRequest(data).subscribe(
      success => {
        this.toastr.ShowSuccess(success.message);
        this.getEntries();
      },
      failure => {
        console.log(failure);
        this.toastr.ShowError(failure.message);
      }
    );
  }

  addRow = () => {
    console.log(this.sampleObject.entries.length);
    this.projectRequestService.AddRow({
      id: this.sampleObject.entries.length + 1,
      index: this.sampleObject.entries.length + 1,
      fieldName: '',
      description: '',
      fieldType: 'textSingle',
      options: null,
      required: false,
      warning: false,
      numbersAllowed: true,
      maxLength: 250
    });
    this.getFormFields();
    console.log(this.sampleObject);
  };

  addEntry = (formData) => {
    this.projectRequestService.SubmitRequest(formData).subscribe(
      success => {
        this.toastr.ShowSuccess(success.message);
        this.sampleObject.entries = [];
        this.getEntries();
      },
      failure => {
        console.log(failure);
        this.toastr.ShowError(failure.message);
      }
    );
  };

  deleteEntry = () => {
    this.projectRequestService.DeleteRequest(this.currentEntry.id).subscribe(
      success => {
        this.toastr.ShowSuccess(success.message);
        this.sampleObject.entries = [];
        this.getEntries();
        this.editPanel = false;
      },
      failure => {
        console.log(failure);
        this.toastr.ShowError(failure.message);
      }
    );
  };

  editData(e) {
    if (e.target.tagName != "SELECT") {
      e.currentTarget.style.color = 'black';
      e.currentTarget.style.backgroundColor = 'white';
    }
  }

  setData(e) {
    var id = this.sampleObject.entries.findIndex(x => x.id == e.target.dataset.id);
    if (e.target.tagName == "SELECT") {
      this.projectRequestService.SetRow(id, e.target.dataset.field, e.target.value);
    } else if (e.target.type == "checkbox") {
      this.projectRequestService.SetRow(id, e.target.dataset.field, e.target.checked);
    } else {
      e.currentTarget.style.color = 'white';
      e.currentTarget.style.backgroundColor = 'transparent';
      this.projectRequestService.SetRow(id, e.target.dataset.field, e.target.innerText);
    }
    this.getFormFields();
  }

  handleFileInput = (event) => {
    let reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.projectRequest.patchValue({
          [event.target.name]: {
            name: file.name,
            data: reader.result
          }
        });
        // need to run CD since file load runs outside of zone
        this.changeDetectorRef.markForCheck();
      };
    }
    // patchwork function 
  };

  reorderRows = () => {
    this.projectRequestService.MoveRow(this.sampleObject);
    this.getFormFields();
  };

  setRowClass = (row) => {
    if ( row.customer && row.assetType && row.estimatedDate && row.jobCode && (row.contactedIT != undefined) && row.assetNumber && row.assetData && row.contactName && row.contactEmail && row.contactNumber ) {
      return 'required-row dev-row';
    } else if ( row.customer && row.assetType && row.estimatedDate && row.jobCode && (row.contactedIT != undefined) && row.contract && row.rfp ) {
      return 'required-row';
    } else {
      return '';
    }
  };

  updateRequest = (data) => {
    this.projectRequestService.UpdateRequest(data);
  };

  onSubmit = (formData) => {
    this.submitDisabled = true;
    formData.index = this.sampleObject.entries.length + 1;
    formData.id = this.sampleObject.entries.length;
    formData.submittedBy = this.userProfile.userName;
    this.addEntry(formData);

    this.projectRequest.reset();
  };

  onSubmitEdit = (formData) => {
    formData.id = this.currentEntry.id;
    this.projectRequestService.UpdateRequest(formData).subscribe(
      success => {
        this.toastr.ShowSuccess(success.message);
      },
      failure => {
        console.log(failure);
        this.toastr.ShowError(failure.message);
      }
    );
    this.editPanel = false;
  };

  formatDate = (date) => {
    var d = new Date(date);
    return d.getMonth() + "/" + d.getDate() + "/" + d.getFullYear();
  };

  showEditPanel = (e) => {
    var id = this.sampleObject.entries.findIndex(x => x.id == e.toElement.dataset.id);
    this.currentEntry = this.sampleObject.entries[id];
    this.editPanel = true;
  };

  viewGrid = () => {
    this.editing = true;
    this.getEntries();
  };

  checkValues = () => {
    if ( this.projectRequest.value.customer && this.projectRequest.value.assetType && this.projectRequest.value.estimatedDate && this.projectRequest.value.jobCode && this.projectRequest.value.contactedIT && this.projectRequest.value.contractBool && this.projectRequest.value.rfpBool ) {
      this.submitDisabled = false;
    }
  }

  ngOnDestroy(): void {
    this.alive = false;  
  }
}
import { NgModule } from '@angular/core';
import { FileDownloadComponent } from './filedownload/filedownload.component';
import { LoginComponent } from './login/login.component';
import { LinkMenuComponent } from './linkmenu/linkmenu.component';
import { MyAccountComponent } from './myaccount/myaccount.component';
import { UserAccessRequestComponent } from './useraccessrequest/useraccessrequest.component';
import { Routes, RouterModule } from '@angular/router';
import { UserAssignmentComponent } from './userassignment/userassignment.component';
import { RoleAssignmentComponent } from './roleassignment/roleassignment.component';
import { UserManagementComponent } from './usermanagement/usermanagement.component';
import { ProjectRequestComponent } from './projectrequest/projectrequest.component';
import { NewUserComponent } from './newuser/newuser.component';
import { ManageUsersComponent } from './manageusers/manageusers.component';

const routes: Routes = [
  { path: 'userassignment', component: UserAssignmentComponent },
  { path: 'roleassignment', component: RoleAssignmentComponent },
  { path: 'filedownload', component: FileDownloadComponent },
  { path: 'linkmenu', component: LinkMenuComponent },
  { path: 'login', component: LoginComponent },
  { path: 'myaccount', component: MyAccountComponent },
  { path: 'useraccessrequest', component: UserAccessRequestComponent },
  { path: 'usermanagement', component: UserManagementComponent },
  { path: 'projectrequest', component: ProjectRequestComponent },
  { path: 'newuser', component: NewUserComponent },
  { path: 'manageusers', component: ManageUsersComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
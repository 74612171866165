<div *ngIf="!editingUser">
    <div class="grid-container">
        <div class="filters-container">
            <div class="filters">
                <!-- <select>
                    <option>Application</option>
                    <option>Application 2</option>
                </select>
                <select>
                    <option>Crew</option>
                </select> -->
                <input type="text" data-filter="name" placeholder="Name" [(ngModel)]="filter.name" (keyup)="filterByValue($event)"/>
                <input type="text" data-filter="email" placeholder="Email" [(ngModel)]="filter.email" (keyup)="filterByValue($event)"/>
                <!-- <select>
                    <option>Market</option>
                </select> -->
                <input type="text" data-filter="company" placeholder="Company" [(ngModel)]="filter.company" (keyup)="filterByValue($event)"/>
            </div>
            <div class="filter-buttons">
                <button>Filter</button>
                <button>Clear</button>
            </div>
        </div>
        <div class="grid-users">
            <div *ngFor="let user of filteredUsers" class="grid-user-container">
                <div class="grid-user-functions">
                    <input type="checkbox"/>
                    <span>User Name: {{user.userName}}</span>
                    <a href="javascript:void(0);" (click)="editUser(user.userName)">Edit</a>
                </div>
                <div class="main-card">
                    <div class="avatar">
                        Pictures goes here
                    </div>
                    <div class="user-information">
                        <h5>{{user.firstName}} {{user.lastName}}</h5>
                        <p>{{user.portalRole}}</p>
                        <p class="warning" *ngIf="user.lockedOut">LOCKED</p>
                        <p class="warning" *ngIf="user.isPending">Invitation Pending</p>
                        <p class="warning" *ngIf="user.failedAccessCount > 0">Failed Login Attempts: {{user.failedAccessCount}}</p>
                        <p>Email: {{user.email}}</p>
                        <p>Company: {{user.company}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="newUserClicked" (click)="PrintStep()" class="user-container">
        <div *ngIf="step == 1" class="create-user-step step-1">
            <label>Username:</label>
            <input [(ngModel)]="newUserDetails.username" type="text"/>
            <label>User Email:</label>
            <input [(ngModel)]="newUserDetails.email" type="text"/>
            <label>First Name:</label>
            <input [(ngModel)]="newUserDetails.firstname" type="text"/>
            <label>Last Name:</label>
            <input [(ngModel)]="newUserDetails.lastname" type="text"/>
            <label>Company:</label>
            <input [(ngModel)]="newUserDetails.company" type="text"/>
            <label>User Portal Role:</label>
            <select [(ngModel)]="newUserDetails.role" id="role">
                <option value="fieldAdmin">FieldAdmin</option>
                <option value="user">User</option>
            </select>
            <button (click)="submitStep();">Submit</button>
        </div>
        <div *ngIf="step == 2" class="create-user-step step-2">
            <input type="checkbox" value="qmanage" (change)="addSiteToList($event)"/><span>QManage</span>
            <input type="checkbox" value="qaudit" (change)="addSiteToList($event)"/><span>QAudit</span>
            <input type="checkbox" value="utilimeter" (change)="addSiteToList($event)"/><span>Utilimeter</span>
            <input type="checkbox" value="qtrax" (change)="addSiteToList($event)"/><span>QTrax</span>
            <button (click)="submitStep();">Submit</button>
        </div>
        <div *ngIf="step == 3" class="create-user-step step-3">
            <div *ngFor="let role of roles">
                <input type="checkbox" value="{{role}}" (change)="addRole($event)"/><span>{{role}}</span>
            </div>
            <button (click)="submitStep();">Submit</button>
        </div>
        <div *ngIf="step == 4" class="create-user-step step-4">
            <div *ngIf="markets.qmanage">
                <p>QManage</p>
                <div *ngFor="let market of markets.qmanage">
                    <input type="checkbox" value="{{market}}" site="qmanage" (change)="addMarket($event)"/><span>{{market}}</span>
                </div>
            </div>
            <br>
            <div *ngIf="markets.utilimeter">
                <p>Utilimeter</p>
                <div *ngFor="let market of markets.utilimeter">
                    <input type="checkbox" value="{{market}}" site="utilimeter" (change)="addMarket($event)"/><span>{{market}}</span>
                </div>
            </div>
            <br>
            <button (click)="submitStep();">Submit</button>
        </div>
        <div *ngIf="step === 5" class="create-user-step step-5">
            <div>
                <p>New User Details:</p>
                <div>
                    <span>Full Name: {{newUserDetails.firstname}} {{newUserDetails.lastname}}</span>
                </div>
                <div>
                    <span>Company: {{newUserDetails.company}}</span>
                </div>
                <div>
                    <span>Email Address: {{newUserDetails.email}}</span>
                </div>
                <div>
                    <span>Username: {{newUserDetails.username}}</span>
                </div>
                <div>
                    <span>Portal Role: {{newUserDetails.role}}</span>
                </div>
                <br>
                <p>Sites:</p>
                <div *ngFor="let site of sites">
                    <span>{{site}}</span>
                </div>
                <br>
                <p>Roles:</p>
                <div *ngFor="let role of userRoles">
                    <span>{{role}}</span>
                </div>
                <br>
                <p>Markets:</p>
                <div *ngIf="userMarkets.qmanage">
                    <p>QManage:</p>
                    <div *ngFor="let market of userMarkets.qmanage">
                        <span>{{market}}</span>
                    </div>
                </div>
                <br>
                <div *ngIf="userMarkets.utilimeter">
                    <p>Utilimeter:</p>
                    <div *ngFor="let market of userMarkets.utilimeter">
                        <span>{{market}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="editingUser">
    <button (click)="refreshGrid()">Return to Grid</button>
    <user-account-form [userName]="userToEdit">

    </user-account-form>
</div>
<div
  class="d-flex flex-row w-100 justify-content-center align-items-center flex-wrap h-100"
  style="margin: auto; width: 65% !important"
>
  <div
    class="col-lg-3 d-flex m-4 h-35 link-container pb-4 pr-4 pl-4 pt-2"
    style="border-radius: 10px"
    (click)="NavigationTo(link.url)"
    *ngFor="let link of linkMenuObsersable$ | async"
  >
    <div class="d-flex flex-row w-100 h-100">
      <div
        class="d-flex flex-column w-100 h-100 justify-content-center align-items-center"
      >
        <h5
          class="pt-2 text-white"
          style="
            font-size: 1.2em;
            letter-spacing: 2px;
            text-shadow: 0 0 7px #000;
          "
        >
          {{ link.description }}
        </h5>
        <img
          style="max-width: 130px"
          class="img-fluid link-image w-100 h-100"
          src="{{ GetIconImage(link.icon) }}"
        />
      </div>
    </div>
  </div>
</div>

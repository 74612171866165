<div (click)="PrintStep()" class="new-user-container">
    <div class="steps-container">
        <div *ngIf="step == 1" class="create-user-step step-1">
            <div>
                <label>Username:</label>
                <input [(ngModel)]="newUserDetails.username" (change)="checkUserName(newUserDetails.username)" type="text"/>
            </div>
            <div>
                <label>User Email:</label>
                <input [(ngModel)]="newUserDetails.email" (change)="checkEmail(newUserDetails.email)" type="text"/>
            </div>
            <div>
                <label>First Name:</label>
                <input [(ngModel)]="newUserDetails.firstname" (keyup)="validateNextStep()" type="text"/>
            </div>
            <div>
                <label>Last Name:</label>
                <input [(ngModel)]="newUserDetails.lastname" (keyup)="validateNextStep()" type="text"/>
            </div>
            <div>
                <label>Company:</label>
                <input [(ngModel)]="newUserDetails.company" (keyup)="validateNextStep()" type="text"/>
            </div>
            <div style="flex-flow: column; align-items: start;" *ngIf="this.existingUserInfo !== undefined">
                <!-- <kendo-upload type="file" [autoUpload]="false" (success)="successHandler($event)" (upload)="avatarUpload($event)" (select)="selectHandler($event)">
                
                </kendo-upload> -->
                <div>Profile Photo</div>
                <kendo-upload type="file" [autoUpload]="false" [saveUrl]="uploadUrl" (upload)="uploadHandler($event)" (success)="successHandler($event)" (error)="errorHandler($event)">

                </kendo-upload>
            </div>
            <div *ngIf="!passwordValidated" style="color:red">Passwords must match</div>
            <div *ngIf="this.existingUserInfo !== undefined">
                <label>Password:</label>
                <input [(ngModel)]="newUserDetails.password" autocomplete="new-password" id="password" (keyup)="validatePassword($event)" type="password" placeholder="Leave blank to use current password"/>
            </div>
            <div *ngIf="this.existingUserInfo !== undefined">
                <label>Confirm Password:</label>
                <input [(ngModel)]="newUserDetails.confirmPassword" autocomplete="new-password" id="confirmPassword" (keyup)="validatePassword($event)" type="password" placeholder="Leave blank to use current password"/>
            </div>
            <div *ngIf="isNewUser">
                <label>User Portal Role:</label>
                <select [(ngModel)]="newUserDetails.role" (change)="validateNextStep()" id="role">
                    <option value="FieldAdmin">FieldAdmin</option>
                    <option value="User">User</option>
                </select>
            </div>
        </div>
        <div *ngIf="step == 2" class="create-user-step step-2">
            <!-- <input type="checkbox" value="qmanage" (change)="addSiteToList($event)"/><span>QManage</span>
            <input type="checkbox" value="qaudit" (change)="addSiteToList($event)"/><span>QAudit</span>
            <input type="checkbox" value="utilimeter" (change)="addSiteToList($event)"/><span>Utilimeter</span>
            <input type="checkbox" value="qtrax" (change)="addSiteToList($event)"/><span>QTrax</span> -->

            <!-- <kendo-grid #grid style="overflow-x: auto; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px" [selectable]="true" [resizable]="{enabled: true, checkboxOnly: false }" [data]="allSites">
                <kendo-grid-checkbox-column [width]="50">
                    <ng-template kendoGridCellTemplate let-idx="rowIndex">
                        <input [kendoGridSelectionCheckbox]="idx" />
                    </ng-template>
                </kendo-grid-checkbox-column>
                <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title"></kendo-grid-column>
            </kendo-grid> -->
            <div *ngFor="let site of allSites">
                <input [(ngModel)]="site.selected" type="checkbox" [value]="site.description" (change)="addSiteToList($event)"/><span>{{site.description}}</span>
            </div>
        </div>
        <div *ngIf="step == 3" class="create-user-step step-3">
            <div *ngFor="let site of userRoles">
                <h5>{{site.siteName}}</h5>
                <div *ngFor="let siterole of site.children">
                    <!-- <input type="radio" [(ngModel)]="currentEntry.rfpBool" name="rfpBool" formControlName="rfpBool" [value]="false"/><label>No</label><br> -->
                    <input [attr.name]="site.siteName" [attr.roleid]="siterole.id" [checked]="checkRole(site.siteName, siterole.id, siterole.detail)" [type]="site.isSingleSelect !== true ? 'checkbox' : 'radio'" attr.value="{{siterole.detail}}" (change)="addRole($event)"/><span>{{siterole.detail}}</span>
                </div>
            </div>
            <br>
        </div>
        <div *ngIf="step == 4" class="create-user-step step-4">
            <div *ngFor="let site of siteMarkets">
                <h5>{{site.siteName}}</h5>
                <div class="market-checkbox" *ngFor="let market of site.children">
                    <!-- <input style="margin-right: 10px;" [attr.marketId]="market.id" [checked]="checkMarket(site.siteName, market.id)" type="checkbox" value="{{market.detail}}" [attr.site]="site.siteName" (change)="addMarket($event)"/><span>{{market.detail}}</span> -->
                    <input style="margin-right: 10px;" [attr.marketId]="market.id" [checked]="checkMarket(site.siteName, market.id)" [type]="site.isSingleSelect !== true ? 'checkbox' : 'radio'" value="{{market.detail}}" [attr.site]="site.siteName" (change)="addMarket($event)"/><span>{{market.detail}}</span>
                </div>
            </div>
            <!-- <div *ngIf="markets.qmanage.length > 0">
                <h5>QManage</h5>
                <div class="market-checkbox" *ngFor="let market of markets.qmanage">
                    <input style="margin-right: 10px;" [attr.marketId]="market.marketId" [checked]="checkMarkets(market.description, 'qmanage')" type="checkbox" value="{{market.description}}" site="qmanage" (change)="addMarket($event)"/><span>{{market.description}}</span>
                </div>
            </div>
            <br>
            <div *ngIf="markets.utilimeter.length > 0">
                <h5>Utilimeter</h5>
                <div class="market-checkbox" *ngFor="let market of markets.utilimeter">
                    <input type="checkbox" [checked]="checkMarkets(market, 'utilimeter')" value="{{market}}" site="utilimeter" (change)="addMarket($event)"/><span>{{market}}</span>
                </div>
            </div> -->
            <br>
        </div>
        <div *ngIf="step === 5" class="create-user-step step-5">
            <div>
                <p>Summary Page</p>
                <div>
                    <span>Full Name: {{newUserDetails.firstname}} {{newUserDetails.lastname}}</span>
                </div>
                <div>
                    <span>Company: {{newUserDetails.company}}</span>
                </div>
                <div>
                    <span>Email Address: {{newUserDetails.email}}</span>
                </div>
                <div>
                    <span>Username: {{newUserDetails.username}}</span>
                </div>
                <div>
                    <span>Portal Role: {{newUserDetails.role}}</span>
                </div>
                <br>
                <p>Sites:</p>
                <div *ngFor="let site of allSites">
                    <span *ngIf="site.selected">{{site.description}}</span>
                </div>
                <br>
                <p>Roles:</p>
                <div *ngFor="let key of objectKeys(roles)">
                    <span style="font-weight:bold;">{{key}}</span><br>
                    <span style="margin-left: 15px;">{{roles[key].role}}</span>
                </div>
                <br>
                <p>Markets:</p>
                <!-- <div *ngIf="userMarkets.qmanage">
                    <p>QManage:</p>
                    <div *ngFor="let market of selectedMarkets.qmanage">
                        <span>{{market}}</span>
                    </div>
                </div>
                <br>
                <div *ngIf="userMarkets.utilimeter">
                    <p>Utilimeter:</p>
                    <div *ngFor="let market of selectedMarkets.utilimeter">
                        <span>{{market}}</span>
                    </div>
                </div> -->
                <div *ngFor="let site of siteMarkets">
                    <span style="font-weight: bold;">{{site.siteName}}</span>
                    <div *ngFor="let market of site.children">
                        <span *ngIf="validateMarket(site.siteName, market.id)">{{market.detail}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="progress-bar">
        <div class="bar step-{{step}}">

        </div>
    </div>
    <button *ngIf="isNewUser" (click)="CancelNewUser()">Cancel</button>
    <button *ngIf="step > 1" (click)="previousStep();">Previous Step</button>
    <button *ngIf="step < 5" [disabled]="!stepValidated" (click)="nextStep();">Next Step</button>
    <button *ngIf="step == 5" (click)="nextStep();">Finalize</button>
</div>
<div
  class="d-flex flex-row w-100 h-100 justify-content-center align-items-center p-4 text-white"
>
  <div
    style="min-height: 650px"
    class="w-75 h-75 rounded form-container border"
    *ngIf="userAccountObsersable$ | async"
  >
    <div
      class="d-flex flex-row w-100 text-white border-bottom justify-content-between align-items-center p-2"
    >
      <h4 class="mt-2">
        User Account: {{ (userAccountObsersable$ | async)?.userName }}
      </h4>
      <!-- <div class="d-flex">
                <button (click)="LockAccount()">Lock Account</button>
                <button (click)="UnlockAccount()">Unlock Account</button>
            </div> -->
    </div>
    <div class="w-100 d-flex justify-content-center align-items-start h-75">
      <form
        #accountForm="ngForm"
        [mustMatch]="['password', 'confirmpassword']"
        (ngSubmit)="onSubmit(accountForm, user.userName)"
        class="d-flex flex-row w-100 h-100"
        *ngIf="userAccountObsersable$ | async as user; else loading"
      >
        <div
          class="d-flex flex-column w-50 p-1 justify-content-start align-items-center"
        >
          <h2 class="mt-1">
            {{ user.firstName }} {{ user.lastName }}
            <img
              class="img-fluid editNameImage mb-2"
              src="{{ GetEditNameImage() }}"
              (click)="openEditUserDialog = true"
            />
          </h2>
          <h6>{{ user.jobTitle }}</h6>
          <div
            class="w-75 h-75 d-flex justify-content-center align-items-start p-1"
          >
            <div
              class="w-75 h-100 d-flex justify-content-center"
              (click)="ChangeAvatar()"
              style="position: relative; z-index: 9999"
            >
              <div
                id="testing123"
                (mouseenter)="hoverImage = true"
                (mouseleave)="hoverImage = false"
                style="
                  position: absolute;
                  width: 250px;
                  height: 250px;
                  cursor: pointer;
                  z-index: 1000;
                "
              ></div>
              <img
                *ngIf="hasAvatar"
                class="avatar"
                src="{{ GetUserImage(user.avatarImage) }}"
              />
              <img
                *ngIf="!hasAvatar"
                class="avatar-placeholder"
                style="cursor: pointer"
                [style.opacity]="hoverImage ? 0.4 : 1"
                src="{{ GetAddAvatarImage() }}"
              />
              <div *ngIf="hoverImage" class="avatar-text pr-2">
                {{ !hasAvatar ? "Add" : "Edit" }} Avatar
              </div>
            </div>
          </div>
          <div class="w-100 h-25" [ngClass]="{ 'mt-3': !user.jobTitle }">
            <div class="form-group w-100">
              <h5
                class="mb-4 w-100 d-flex justify-content-center border-bottom"
              >
                Change Password
              </h5>
              <div
                class="w-100 d-flex justify-content-end"
                [ngClass]="{
                  'mb-2 pb-0': password?.errors?.minlength,
                  'mb-4 pb-2': !password?.errors?.minlength
                }"
              >
                <label
                  style="font-size: 12px"
                  for="password"
                  class="control-label mt-2 ml-2 font-weight-bold w-25"
                  >Password:</label
                >
                <input
                  #password="ngModel"
                  class="form-control w-75 border"
                  (change)="CheckPassword(accountForm)"
                  name="password"
                  type="password"
                  autocomplete="new-password"
                  [(ngModel)]="user.password"
                  minlength="12"
                />
              </div>
              <div
                class="w-100 d-flex justify-content-end"
                *ngIf="password?.errors?.minlength"
              >
                <div class="w-25"></div>
                <div
                  style="font-size: 10px"
                  class="w-75 d-flex justify-content-center text-danger"
                >
                  Password must be at least 12 characters
                </div>
              </div>
              <div
                class="w-100 d-flex justify-content-end mt-4"
                [ngClass]="{
                  'mt-3': password?.errors?.minlength,
                  'mt-4 pt-2': !password?.errors?.minlength
                }"
              >
                <label
                  style="font-size: 12px"
                  for="confirmpassword"
                  class="control-label mt-2 ml-2 font-weight-bold w-25"
                  >Confirm Password:</label
                >
                <input
                  #confirmpassword="ngModel"
                  [disabled]="user?.password?.length < 12"
                  class="form-control w-75 border"
                  name="confirmpassword"
                  type="password"
                  [(ngModel)]="user.confirmpassword"
                  minlength="12"
                />
              </div>

              <div
                class="w-100 d-flex justify-content-end"
                style="font-size: 10px"
              >
                <div class="w-25"></div>
                <div
                  *ngIf="confirmpassword?.errors?.minlength"
                  class="w-75 d-flex justify-content-center text-danger pt-1"
                >
                  Confirmation Password must be at least 12 characters
                </div>
                <div
                  *ngIf="confirmpassword?.errors?.mustMatch"
                  class="w-75 d-flex justify-content-center text-danger pt-1"
                >
                  Passwords must match
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column w-50 h-100 p-4 mr-4">
          <div class="form-group w-100 h-100">
            <div class="w-100 d-flex justify-content-end mb-3">
              <label
                for="office"
                class="control-label mt-1 ml-2 font-weight-bold w-25"
                >Office:</label
              >
              <input
                #office="ngModel"
                class="form-control w-75 border bg-dark text-white"
                name="office"
                type="text"
                [(ngModel)]="user.office"
              />
            </div>
            <div class="w-100 d-flex justify-content-end mb-3">
              <label
                for="email"
                class="control-label mt-1 ml-2 font-weight-bold w-25"
                >User Email:</label
              >
              <input
                #email="ngModel"
                class="form-control w-75 border bg-dark text-white"
                name="email"
                type="text"
                [(ngModel)]="user.email"
              />
            </div>
            <div class="w-100 d-flex justify-content-end mb-3">
              <label
                for="company"
                class="control-label mt-1 ml-2 font-weight-bold w-25"
                >Company:</label
              >
              <input
                #company="ngModel"
                class="form-control w-75 border bg-dark text-white"
                name="company"
                type="email"
                [(ngModel)]="user.company"
              />
            </div>
            <div class="w-100 h-50 d-flex justify-content-end mb-3">
              <label
                for="biography"
                class="control-label mt-1 ml-2 font-weight-bold w-25"
                >Biography:</label
              >
              <textarea
                #biography="ngModel"
                class="form-control w-75 h-100 border bg-dark text-white"
                name="biography"
                [(ngModel)]="user.biography"
              ></textarea>
            </div>
            <div class="w-100 d-flex justify-content-end">
              <label
                style="font-size: 12px"
                for="authPassword"
                class="control-label ml-2 font-weight-bold w-50 mr-2 mt-2 d-flex justify-content-end"
                >Enter current password:</label
              >
              <input
                #authPassword="ngModel"
                class="form-control w-50 border bg-danger text-white"
                name="authPassword"
                type="password"
                [(ngModel)]="user.authPassword"
                required
              />
            </div>
            <div
              style="font-size: 10px"
              class="w-100 d-flex justify-content-end mb-4"
            >
              * current password is required
            </div>

            <div class="d-flex flex-row w-100 align-items-end p-0">
              <div class="w-100 d-flex justify-content-start p-0">
                <button
                  type="submit"
                  style="margin-right: 10px"
                  class="w-25 rounded p-2 text-white"
                  [ngClass]="{ 'bg-success': accountForm.form.valid }"
                  [disabled]="!accountForm.form.valid"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="bg-primary w-25 rounded border-0 justify-content-center p-2 text-white"
                  (click)="ResetForm(accountForm)"
                >
                  Reset
                </button>
                <!-- <button class="bg-danger w-25 rounded border-0 p-2 text-white" (click)="Cancel()">Cancel</button> -->
              </div>
            </div>
          </div>
        </div>

        <kendo-dialog
          [hidden]="!openEditUserDialog"
          (close)="openEditUserDialog = false"
          [width]="600"
          [height]="350"
        >
          <kendo-dialog-titlebar>
            <div class="d-flex w-100 justify-content-start mt-2">
              <h6>Edit User Information</h6>
            </div>
          </kendo-dialog-titlebar>
          <div
            class="d-flex flex-column justify-content-center w-100 align-items-center h-100"
            *ngIf="userAccountObsersable$ | async as user"
          >
            <div class="w-75">
              <div class="form-group w-100">
                <input
                  #id="ngModel"
                  [hidden]="true"
                  name="id"
                  type="text"
                  [(ngModel)]="user.id"
                />

                <div class="w-100 d-flex justify-content-start mb-4">
                  <label
                    style="font-size: 12px"
                    for="firstName"
                    class="control-label mt-2 ml-2 font-weight-bold w-25"
                    >First Name:</label
                  >
                  <input
                    #firstName="ngModel"
                    class="form-control w-75 border bg-dark text-white"
                    name="firstName"
                    type="text"
                    [(ngModel)]="user.firstName"
                  />
                </div>
                <div class="w-100 d-flex justify-content-end mt-4">
                  <label
                    style="font-size: 12px"
                    for="lastName"
                    class="control-label mt-2 ml-2 font-weight-bold w-25"
                    >Last Name:</label
                  >
                  <input
                    #lastName="ngModel"
                    class="form-control w-75 border bg-dark text-white"
                    name="lastName"
                    type="text"
                    [(ngModel)]="user.lastName"
                  />
                </div>
                <div class="w-100 d-flex justify-content-end mt-4">
                  <label
                    style="font-size: 12px"
                    for="jobTitle"
                    class="control-label mt-2 ml-2 font-weight-bold w-25 text-white"
                    >Job Title:</label
                  >
                  <input
                    #jobTitle="ngModel"
                    class="form-control w-75 border bg-dark text-white"
                    name="jobTitle"
                    type="text"
                    [(ngModel)]="user.jobTitle"
                  />
                </div>
              </div>
            </div>
          </div>
          <kendo-dialog-actions>
            <button
              class="w-100"
              kendoButton
              (click)="UpdateFormFields($event, user)"
            >
              Update
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>

        <kendo-dialog
          [hidden]="!openChangeAvatarDialog"
          (close)="openChangeAvatarDialog = false"
          [width]="600"
        >
          <kendo-dialog-titlebar>
            <div class="d-flex w-100 justify-content-start mt-2">
              <h6>Edit User Avatar</h6>
            </div>
          </kendo-dialog-titlebar>
          <div class="d-flex flex-row w-100 h-100">
            <div
              class="d-flex flex-column w-25 h-100 justify-content-center align-items-center"
            >
              <img
                class="w-50 avatar-mask"
                src="{{ GetUserImage(user.avatarImage) }}"
              />
            </div>
            <div
              class="d-flex flex-column w-75 h-100 p-2 align-items-center justify-content-center"
            >
              <kendo-upload
                [restrictions]="avatarRestrictions"
                [saveUrl]="uploadAvatartUrl"
                [multiple]="false"
                [autoUpload]="false"
                (error)="ErrorUploadHandler($event)"
                (success)="SuccessUploadHandler($event)"
                class="w-100 border rounded"
              >
              </kendo-upload>
            </div>
          </div>
          <kendo-dialog-actions style="height: auto">
            <button
              class="w-50"
              kendoButton
              type="button"
              (click)="DeleteAvatar(user.userName)"
            >
              Remove Avatar
            </button>
            <button
              class="w-50"
              kendoButton
              (click)="openChangeAvatarDialog = false"
            >
              Close
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>
      </form>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="w-100 h-100 d-flex justify-content-center align-items-center">
    <ngx-spinner
      bdColor="rgba(51, 51, 51, 0.8)"
      size="default"
      type="ball-spin-clockwise"
    >
      <p style="color: white">Loading. Please Wait.</p> </ngx-spinner
    >.
  </div>
</ng-template>

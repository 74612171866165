import { AdminAssign } from 'src/models/interfaces/admin.assign';
import { AdminSelect } from 'src/models/interfaces/admin.select';
import { AppService } from '../app.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart} from '@angular/cdk/drag-drop';
import { Component, OnInit, HostListener } from '@angular/core';
import { ConsoleLogger } from '../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { Toastr } from '../common/toastr.service';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { UserDetails } from 'src/models/interfaces/user.details';
import { UseraccountService } from '../useraccount/useraccount.service';
import { UserManagementService } from './usermanagement.service';
import { UserAssignmentService } from '../userassignment/userassignment.service';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss'],
})
export class UserManagementComponent implements OnInit {
  public adminAssign: AdminAssign[] = [];
  public adminSelect: AdminSelect;
  public environmentUrl: string;
  public helpIconUrl: string;
  public isCollapsed: boolean = true;
  public isUsersLoaded: boolean = false;
  public origArray: UserAssign[] = [];
  public showConfirm: boolean = false;
  public userArray: UserAssign[] = [];
  public userDetails: UserDetails;
  public newUserDetails: any = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    company: '',
    role: ''
  };
  public step: number = 1;
  public sites: Array<string> = [];
  public roles: Array<string> = [];
  public markets: any = {
    qmanage: [],
    utilimeter: []
  };
  public userProfile: UserProfile;
  public userRoles: Array<string> = [];
  public userMarkets: any = {};
  public newUserClicked:boolean = false;
  public gridUsers:Array<any> = [];
  public filteredUsers:Array<any> = [];
  public editingUser:boolean = false;
  public userToEdit:string;
  public filter:any = {}

  constructor(private appSrv: AppService,
              private consoleLogger: ConsoleLogger,
              private toastr: Toastr,
              private userAccountService: UseraccountService,
              private userAssignmentService: UserAssignmentService,
              private userManagementService: UserManagementService,
              private userService: UserManagementService) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.helpIconUrl = "/assets/images/plus.png";
    this.appSrv.GetUserProfileObservable()
    .subscribe(upo => this.userProfile = upo);
    this.getGridUsers();
  }

  submitStep = () => {
    console.log(this.step);
    console.log(this.newUserDetails);
    switch(this.step) {
      case 1:
        this.userService.SetStagingUser(this.newUserDetails).subscribe(
          success => {
            console.log(success);
            this.step++;
          },
          failure => {
            console.log(failure);
          }
        );
      break;
      case 2:
        this.sites.forEach(site => {
          this.getRoles(site);
        });
        console.log(this.roles);
        this.step++;
      break;
      case 3:
        this.sites.forEach(site => {
          this.getMarkets(site);
        });
        console.log(this.markets);
        this.step++;
      break;
      case 4:
        this.step++;
      break;
    }

    
  }

  getAvatar = (user) => {
    this.userAccountService.GetAccount(user).subscribe(result => {
      console.log(result);
    })
  }  

  addSiteToList = (event) => {
    if (event.target.checked) {
      this.sites.push(event.target.value);
    } else {
      var index = this.sites.indexOf(event.target.value);
      if (index !== -1) this.sites.splice(index, 1);
    }
    console.log(this.sites);
  }
  
  addRole = (event) => {
    if (event.target.checked) {
      this.userRoles.push(event.target.value);
    } else {
      var index = this.userRoles.indexOf(event.target.value);
      if (index !== -1) this.userRoles.splice(index, 1);
    }
    console.log(this.userRoles);
  }

  addMarket = (event) => {
    if (event.target.checked) {

      !this.userMarkets.hasOwnProperty(event.target.attributes.site.value) ? Object.defineProperty(this.userMarkets, event.target.attributes.site.value, { value: [] }) : null;
      console.log(this.userMarkets);
      this.userMarkets[event.target.attributes.site.value].push(event.target.value);
    } else {

      if (this.userMarkets[event.target.attributes.site.value]) {
        var index = this.userMarkets[event.target.attributes.site.value].indexOf(event.target.value);
      if (index !== -1) this.userMarkets[event.target.attributes.site.value].splice(index, 1);
      }

      // var index = this.userMarkets.indexOf(event.target.value);
      // if (index !== -1) this.userMarkets.splice(index, 1);
    }
  }

  getAvatars = () => {
    this.gridUsers.forEach(user => {
      this.getAvatar(user.userName);
    });
  }

  getGridUsers() {
    this.userManagementService.GetGridUsers(this.userProfile.userName).subscribe(
      success => {
        this.gridUsers = success;
        this.filteredUsers = success;
        console.log(this.gridUsers);
        this.getAvatars();
      },
      failure => {
        console.log(failure);
      }
    )
  }

  getRoles(site:String) {
    this.userAssignmentService.GetRoles(site.toLowerCase(), this.userProfile.userName).subscribe(
      success => {
        success.roleList.forEach(element => {
          this.roles.push(element.description);
        });
      },
      failure => {
        console.log(failure);
      }
    )
  }

  getMarkets(site) {
    switch (site) {
      case 'qmanage' :
        this.userAssignmentService.GetMarkets("qmanage", null).subscribe(
          success => {
            console.log(success);
            success.markets.forEach(element => {
              this.markets.qmanage.push(element.description);
            });
          },
          failure => {
            console.log(failure);
          }
        );
        break;
      case 'utilimeter' :
        this.userAssignmentService.GetMarkets("utilimeter", this.userProfile.userName).subscribe(
          success => {
            console.log(success);
            success.forEach(element => {
              this.markets.utilimeter.push(element.marketDescription);
            });
          },
          failure => {
            console.log(failure);
          }
        )
        break;
    }
  }

  editUser = (userName) => {
    this.userToEdit = userName;
    this.editingUser = true;
  };

  clearFilters = () => {
    
  }

  refreshGrid = () => {
    this.filteredUsers = [];
    this.gridUsers = [];
    this.getGridUsers();
    this.editingUser = false;
  };

  filterByValue = (event) => {
    /* console.log(event.target.dataset[Object.keys(event.target.dataset)[0]]);
    this.filter[event.target.dataset[Object.keys(event.target.dataset)[0]]] = event.target.value; */

    /* if ( event.target.dataset[Object.keys(event.target.dataset)[0]] == "name" ) {
      this.filter.firstName = event.target.value;
      this.filter.lastName = event.target.value;
      this.filter.userName = event.target.value;
    } else {
      this.filter[event.target.dataset[Object.keys(event.target.dataset)[0]]] = event.target.value;
    } */

    this.filter[event.target.dataset[Object.keys(event.target.dataset)[0]]] = event.target.value;

    this.filteredUsers = this.gridUsers;

    this.filteredUsers = this.filteredUsers.filter(user => {

      /* if ( this.filter[] ||  ) {
        return true;
      } */

      for (var key in this.filter) {
        //if (user[key] === undefined || user[key] != this.filter[key]) {

        if (key == "name") {
          if ( user.firstName != undefined && user.firstName.toLowerCase().indexOf(this.filter[key].toLowerCase()) < 0 && user.lastName.toLowerCase().indexOf(this.filter[key].toLowerCase()) < 0 && user.userName.toLowerCase().indexOf(this.filter[key].toLowerCase()) < 0 ) {
            return false;
          } else if ( user.userName.toLowerCase().indexOf(this.filter[key].toLowerCase()) < 0 ) {
            return false;
          }
        } else if ( user[key] != undefined && user[key].toLowerCase().indexOf(this.filter[key].toLowerCase()) < 0 ) {
          return false;
        } else if ( user[key] == undefined ) {
          return false;
        }
      }
      return true;
    });
  };

  filterByEmail = (event) => {
    //this.filteredUsers = [];

    this.filteredUsers = this.filteredUsers.filter(user => user.email.toLowerCase().indexOf(event.target.value) > -1);

    /* this.gridUsers.forEach(user => {
      var filtered = false;
      if ( user.userName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ) {
        this.filteredUsers.push(user);
        filtered = true; 
      }
    }); */
  }

  filterByCompany = (event) => {
    this.filteredUsers = this.filteredUsers.filter(user => user.company.toLowerCase().indexOf(event.target.value) > -1);
  }

  filterByName = (event) => {
    console.log(event);
    this.filteredUsers = [];

    this.gridUsers.forEach(user => {
      var filtered = false;
      if ( user.userName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ) {
        this.filteredUsers.push(user);
        filtered = true; 
      } else if ( user.firstName !== null && filtered == false ) {
        if ( user.firstName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ) {
          this.filteredUsers.push(user);
          filtered = true; 
        }
      } else if ( user.lastName !== null && filtered == false ) {
        if ( user.lastName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ) {
          this.filteredUsers.push(user);
          filtered = true; 
        }
      } else if ( user.lastName !== null && user.firstName !== null && filtered == false ) {
        var fullName = user.firstName + ' ' + user.lastName;
        if ( fullName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ) {
          this.filteredUsers.push(user);
          filtered = true; 
        }
      }
    });
  };

  PrintStep = () => {
    console.log(this.step);
  }

}
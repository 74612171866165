import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AuthInterceptor } from './common/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileDownloadModule } from './filedownload/filedownload.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LinkMenuModule } from './linkmenu/linkmenu.module';
import { LoginModule } from './login/login.module';
import { MyAccountModule } from './myaccount/myaccount.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner"; 
import { SidenavComponent } from './dashboard/sidenav/sidenav.component';
import { ToastrModule } from 'ngx-toastr';
import { TopnavComponent } from './dashboard/topnav/topnav.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { HttpErrorInterceptor } from './common/httperror.interceptor';
import { UploadModule } from '@progress/kendo-angular-upload';
import { UserAssignmentComponent } from './userassignment/userassignment.component';
import { UserAccessRequestModule } from './useraccessrequest/useraccessrequest.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AdminListModule } from './assignment/adminlist/adminlist.module';
import { UserListModule } from './assignment/userlist/userlist.module';
import { RoleAssignmentComponent } from './roleassignment/roleassignment.component';
import { UserManagementComponent } from './usermanagement/usermanagement.component';
import { ProjectRequestComponent } from './projectrequest/projectrequest.component';
import { UseraccountModule } from './useraccount/useraccount.module';
import { NewUserModule } from './newuser/newuser.module';
import { ManageUsersModule } from './manageusers/manageusers.module';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidenavComponent,
    TopnavComponent,
    UserAssignmentComponent,
    RoleAssignmentComponent,
    UserManagementComponent,
    ProjectRequestComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    LoginModule,
    FileDownloadModule,
    FormsModule, 
    HttpClientModule,
    LinkMenuModule,
    LoginModule,
    MyAccountModule,
    NgbModule,
    UserAccessRequestModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      enableHtml: true,
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true
    }),
    WindowModule,
    TreeViewModule,
    UploadModule,
    AppRoutingModule,
    LayoutModule,
    AdminListModule,
    UserListModule,
    UseraccountModule,
    NewUserModule,
    ManageUsersModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
import { AdminAssign } from 'src/models/interfaces/admin.assign';
import { AdminSelect } from 'src/models/interfaces/admin.select';
import { AppService } from '../app.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart} from '@angular/cdk/drag-drop';
import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConsoleLogger } from '../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { Toastr } from '../common/toastr.service';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { UserDetails } from 'src/models/interfaces/user.details';
import { NewUserService } from './newuser.service';
import { UserManagementService } from '../usermanagement/usermanagement.service';
import { UserAssignmentService } from '../userassignment/userassignment.service';
import { UserProfile } from 'src/models/interfaces/user.profile';
import { resolve } from 'url';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { element } from 'protractor';

@Component({
  selector: 'new-user',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss'],
})
export class NewUserComponent implements OnInit {
  public adminAssign: AdminAssign[] = [];
  public adminSelect: AdminSelect;
  public environmentUrl: string;
  public helpIconUrl: string;
  public isCollapsed: boolean = true;
  public isUsersLoaded: boolean = false;
  public origArray: UserAssign[] = [];
  public showConfirm: boolean = false;
  public userArray: UserAssign[] = [];
  public userDetails: UserDetails;
  public allSites: Array<any> = [];
  @Input() public newUserDetails: any = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    company: '',
    role: '',
    password: null,
    confirmPassword: null
  };
  @Input() public userName: string;
  @Input() public userRole: string;
  @Output() finalize = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Input() public isNewUser: boolean = true;
  public uploadUrl:string = Environment.serverPath + '/api/accounts/uploadAvatar';
  //public uploadUrl:string = 'http://localhost:63000/api/accounts/uploadAvatar/';
  public step: number = 1;
  public sites: Array<any> = [];
  public roles:any = {};
  public roleIds:any = {};
  public markets: any = {};
  public userProfile: UserProfile;
  public userRoles: Array<any> = [];
  public userMarkets: any = {};
  public newUserClicked:boolean = false;
  public gridUsers:Array<any> = [];
  public filteredUsers:Array<any> = [];
  public editingUser:boolean = false;
  public userToEdit:string;
  public filter:any = {}
  public objectKeys = Object.keys;
  public selectedMarkets:any = {};
  public selectedSites:Array<any> = [];
  public marketIds:any = {};
  public existingUserInfo:any;
  public finalized = false;
  public siteMarkets:any;
  public passwordValidated:boolean = true;
  public emailValidated:boolean = false;
  public isPreviousStep:boolean = false;
  public stepValidated:boolean = false;
  public columns:any = [
    {
      field: "site.description",
      title: "Site",
    }
  ];

  constructor(private appSrv: AppService,
              private consoleLogger: ConsoleLogger,
              private toastr: Toastr,
              private userAssignmentService: UserAssignmentService,
              private userManagementService: UserManagementService,
              private userService: NewUserService) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.helpIconUrl = "/assets/images/plus.png";
    if (!this.isNewUser) {
      this.stepValidated = true;
      this.appSrv.GetUserProfileObservable()
      .subscribe(upo => this.userProfile = upo);
    } else {
      this.appSrv.GetUserProfileObservable()
      .subscribe(upo => this.userName = upo.userName);
    }
    this.getLinks();
    this.getGridUsers();
    this.getCurrentUserInfo();
    this.closed.emit(this.newUserDetails.username);
  }

  checkRole = (site, roleId, detail) => {
    if (this.existingUserInfo !== undefined) {
      if (this.existingUserInfo.step3.sitesDetailsSelected[site] !== undefined) {
        this.existingUserInfo.step3.sitesDetailsSelected[site].includes(roleId) && !this.roles[site].role.includes(detail) ? this.roles[site].role.push(detail) : null;
        return this.existingUserInfo.step3.sitesDetailsSelected[site].includes(roleId) ? true : false;
      }
    } else {
      if (this.roleIds[site] !== undefined) {
        return this.roleIds[site].includes(roleId) ? true : false;
      } else {
        return false;
      }
    }
  }

  checkMarket = (site, marketId) => {
    if (this.existingUserInfo !== undefined) {
      if (this.existingUserInfo.step4.sitesDetailsSelected[site] !== undefined) {
        return this.existingUserInfo.step4.sitesDetailsSelected[site].includes(marketId) ? true : false;
      } else {
        return false
      }
    } else {
      if (this.userMarkets[site] !== undefined) {
        return this.userMarkets[site].includes(marketId) ? true : false;
      } else {
        return false;
      }
    }
  }

  getCurrentUserInfo = () => {
    var name;
    this.newUserDetails.password = null;
    this.newUserDetails.confirmPassword = null;
    this.userName !== undefined ? name = this.userName : name = this.userProfile.userName;
    var isWebAdmin = false;
    this.userRole == 'WebAdmin' ? isWebAdmin = true : null;
    this.userService.GetCurrentUserInfo(name, isWebAdmin)
      .subscribe(success => {
        this.existingUserInfo = success;
        this.newUserDetails.username = success.step1.userName;
        this.newUserDetails.firstname = success.step1.firstName;
        this.newUserDetails.lastname = success.step1.lastName;
        this.newUserDetails.email = success.step1.email;
        this.newUserDetails.role = success.step1.portalRole;
        this.newUserDetails.company = success.step1.company;
      },
      failure => {
        this.toastr.ShowSuccess(failure);
      })
  };

  processStep = () => {
    this.stepValidated = false;
    switch(this.step) {
      case 1:
        this.userService.SetStagingUser(this.newUserDetails).subscribe(
          success => {
            this.step++;
            this.validateNextStep();
          },
          failure => {
            console.log(failure);
            //this.toastr.ShowError(failure);
          }
        );
        this.allSites.forEach(site => {
          if (this.existingUserInfo !== undefined) {
            this.existingUserInfo.step2.forEach(e => {
              e.description == site.description ? site.selected = true : null;
            })
          }
        });
      break;
      case 2:
        this.allSites.forEach(site => {
          console.log(this.selectedSites.indexOf(site.description));
          if (site.selected && this.selectedSites.indexOf(site.description) < 0) {
            this.selectedSites.push(site.description);
          } else if (!site.selected && this.selectedSites.indexOf(site.description) > -1) {
            this.selectedSites.splice(this.selectedSites.indexOf(site.description), 1);
          }
        }); 
        this.userService.setStagingLinks(this.selectedSites, (this.userProfile !== undefined ? this.userProfile.userName : this.userName), this.newUserDetails.username).subscribe(
          success => {
            //this.toastr.ShowSuccess(success);
          },
          failure => {
            //this.toastr.ShowError(failure);
            console.log(failure);
            return false;
          }
        )
        this.userAssignmentService.GetRolesForSelectedSites(this.selectedSites, (this.userProfile !== undefined ? this.userProfile.userName : this.userName)).subscribe(
          success => {
            this.selectedSites.forEach(site => {
              if ( this.roles[site] == undefined ) {
                this.roles[site] = {
                  role: []
                }
              }
              if (this.userMarkets[site] == undefined) {
                this.userMarkets[site] = [];
              }
            });
            this.userRoles.length === 0 ? this.userRoles = success : null;
            success.forEach(site => {
              this.userRoles.find(element => element.siteName == site.siteName) == undefined ? this.userRoles.push(site) : null;
            });
            this.userRoles.forEach((site, index) => {
              //success.find(element => element.siteName == site.siteName) == undefined ? this.userRoles.splice(index, 1) : null;
              if (success.find(element => element.siteName == site.siteName) == undefined) {
                this.userRoles.splice(index, 1);
                delete this.roles[site.siteName];
                delete this.roleIds[site.siteName];
              }
            });
          },
          failure => {
            //this.toastr.ShowError(failure);
            console.log(failure);
            return false;
          }
        )
        this.existingUserInfo !== undefined ? this.roleIds = this.existingUserInfo.step3.sitesDetailsSelected : null;
        this.step++;
        this.validateNextStep();
      break;
      case 3:
        console.log(this.selectedSites);
        this.userService.setStagingDetails(this.newUserDetails.username, this.roleIds).subscribe(
          success => {
            //this.toastr.ShowSuccess(success);
          },
          failure => {
            //this.toastr.ShowError(failure);
            console.log(failure);
            return false;
          }
        )
        this.userAssignmentService.GetMarketsForSelectedSites(this.selectedSites, (this.userProfile !== undefined ? this.userProfile.userName : this.userName)).subscribe(
          success => {
            this.siteMarkets == undefined ? this.siteMarkets = success : null;
            success.forEach(site => {
              this.siteMarkets.find(element => element.siteName == site.siteName) == undefined ? this.siteMarkets.push(site) : null;
            });
            this.siteMarkets.forEach((site, index) => {
              //success.find(element => element.siteName == site.siteName) == undefined ? this.siteMarkets.splice(index, 1) : null;
              if (success.find(element => element.siteName == site.siteName) == undefined) {
                this.siteMarkets.splice(index, 1);
                delete this.userMarkets[site.siteName];
              }
            });
          },
          failure => {
            console.log(failure);
          }
        )
        this.existingUserInfo !== undefined ? this.userMarkets = this.existingUserInfo.step4.sitesDetailsSelected : null;
        this.step++;
        this.validateNextStep();
      break;
      case 4:
        for ( const property in this.userMarkets ) {
          if ( this.selectedMarkets[property] == undefined ) {
            this.selectedMarkets[property] = [];
          }
          this.userMarkets[property].forEach(market => {
            market.selected ? this.selectedMarkets[property].push(market.market) : null;
          });
        }
        //Fix userMarkets array
        for (const market in this.userMarkets) {
          if (this.userMarkets[market].length == 1 && this.userMarkets[market][0] == -1) {
            this.userMarkets[market] = [];
          }
          var index = this.userMarkets[market].indexOf(-1);
          if (index !== -1) {
            this.userMarkets[market].splice(index, 1);
          }
        }
        this.userService.setMarketStagingDetails(this.newUserDetails.username, this.userMarkets).subscribe(
          success => {
            //this.toastr.ShowSuccess(success);
          },
          failure => {
            //this.toastr.ShowError(failure);
            console.log(failure);
            return false;
          }
        )
        this.step++;
        this.validateNextStep();
      break;
      case 5: 
        var edit = false;
        this.existingUserInfo !== undefined ? edit = true : null;
        this.userService.finalize(this.newUserDetails.username, edit).subscribe(
          success => {
            this.finalize.emit((this.userProfile !== undefined ? this.userProfile.userName : this.userName));
            this.step = 1;
            this.newUserDetails = {
              username: '',
              email: '',
              firstname: '',
              lastname: '',
              company: '',
              role: '',
              password: null,
              confirmPassword: null
            };
            this.userRoles = [];
            this.userMarkets = {};
            this.roleIds = {};
            this.roles = {};
            this.selectedSites = [];
            this.allSites.forEach((site, index) => {
              this.allSites[index].selected = false;
            });
            this.siteMarkets = undefined;
            this.isNewUser ? this.toastr.ShowSuccess('Account created') : this.toastr.ShowSuccess('User account updated');
            this.validateNextStep();
          },
          failure => {
            //this.toastr.ShowError(failure);
            console.log(failure);
            return false;
          }
        )
      break;
    }
  };

  nextStep = () => {
    this.isPreviousStep = false;
    this.processStep();
    this.closed.emit(this.newUserDetails.username);
  }

  previousStep = () => {
    this.isPreviousStep = true;
    if (this.step == 2 && this.isNewUser) {
      this.userService.cancelUser(this.newUserDetails.username).subscribe(
        success => {
          console.log(success);
          //this.toastr.ShowSuccess('Operation cancelled');
        },
        failure => {
          this.toastr.ShowError(failure);
        }
      )
    }
    this.step--;
    this.validateNextStep();
  };

  avatarUpload = (e) => {
    var formData:any = new FormData();
    formData.append(e.files[0]);
    this.userService.uploadAvatar(formData, this.newUserDetails.username).subscribe(
      success => {
        console.log(success);
      },
      failure => {
        console.log(failure);
      }
    )
  };

  selectHandler = (event) => {
    console.log(event);
  }

  successHandler = (event) => {
    console.log(event);
  }

  errorHandler = (event) => {
    console.log(event);
  }

  uploadHandler = (event) => {
    event.headers = event.headers.append('AvatarUser', this.newUserDetails.username);
    console.log(event);
  }

  cancelUser = () => {
    this.userService.cancelUser(this.newUserDetails.username).subscribe(
      success => {
        this.toastr.ShowSuccess('Operation cancelled');
      },
      failure => {
        this.toastr.ShowError(failure);
      }
    )
  }

  validateMarket = (name, id) => {
    var check = false;
    if (this.userMarkets[name] !== undefined) {
      this.userMarkets[name].forEach(element => {
        element == id ? check = true : null;
      });
    }
    return check;
  }

  checkMarkets = (market, site) => {
    var index = this.userMarkets[site].map(e => e.market).indexOf(market);
    if (this.userMarkets[site][index].selected) {
      return true;
    } else {
      return false;
    }
  };

  addSiteToList = (event) => {
    this.validateNextStep();
  }
  
  addRole = (event) => {
    if (event.target.attributes.type.value == "checkbox") {
      if (event.target.checked) {
        if (this.roles[event.target.name].role !== undefined) {
          if (this.roles[event.target.name].role.length > 0) {
            this.roles[event.target.name].role.push(event.target.value);
          } else {
            this.roles[event.target.name] = {
              role: [event.target.value]
            }
          }
        } else {
          this.roles[event.target.name] = {
            role: [event.target.value]
          }
        }
        if (this.roleIds[event.target.name] !== undefined) {
          this.roleIds[event.target.name].push(parseInt(event.target.attributes.roleId.value))
        } else {
          this.roleIds[event.target.name] = [
            parseInt(event.target.attributes.roleId.value)
          ]
        }
      } else {
        var index = this.roleIds[event.target.name].indexOf(parseInt(event.target.attributes.roleId.value));
        if (index !== -1) this.roleIds[event.target.name].splice(index, 1);
        var index2 = this.roles[event.target.name].role.indexOf(event.target.value);
        if (index2 !== -1) this.roles[event.target.name].role.splice(index, 1);
      }
    } else {
      this.roles[event.target.name] = {
        role: event.target.value
      }
      this.roleIds[event.target.name] = [
        parseInt(event.target.attributes.roleId.value)
      ]
    }
    this.validateNextStep();
  }

  addMarket = (event) => {
    if (this.userMarkets[event.target.attributes.site.value] == undefined || event.target.attributes.type.value == "radio") {
      this.userMarkets[event.target.attributes.site.value] = [];
    }
    var index = this.userMarkets[event.target.attributes.site.value].indexOf(parseInt(event.target.attributes.marketid.value));
    if (event.target.checked) { 
      this.userMarkets[event.target.attributes.site.value].push(parseInt(event.target.attributes.marketId.value));
      this.markets[event.target.attributes.site.value] !== undefined ? this.markets[event.target.attributes.site.value].push(parseInt(event.target.attributes.marketId.value)) : this.markets[event.target.attributes.site.value] = [event.target.attributes.marketId.value];
    } else {
      index !== -1 ? this.userMarkets[event.target.attributes.site.value].splice(index, 1) : null;
    }
    this.validateNextStep();
  }

  getGridUsers() {
    this.userManagementService.GetGridUsers(this.userProfile.userName).subscribe(
      success => {
        this.gridUsers = success;
        this.filteredUsers = success;
      },
      failure => {
        console.log(failure);
      }
    )
  }

  checkUserName = (userName) => {
    this.userService.CheckUserName(userName).subscribe(
      success => {
        this.toastr.ShowSuccess('Username is available');
      },
      failure => {
        this.toastr.ShowError(failure.statusText);
        this.newUserDetails.username = "";
        console.log(failure.statusText)
      }
    );
    this.validateNextStep();
  };

  checkEmail = (email) => {
    if (this.validateEmail(email)) {
      this.userService.CheckEmail(email).subscribe(
        success => {
          this.toastr.ShowSuccess('Email is available');
          this.emailValidated = true;
          this.validateNextStep();
        },
        failure => {
          this.toastr.ShowError(failure.statusText);
          this.newUserDetails.email = "";
        }
      );
    } else {
      this.emailValidated = false;
      this.toastr.ShowWarning('Please enter a valid email');
      this.validateNextStep();
    }
  };

  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateNextStep = () => {
    if (this.isNewUser) {
      switch (this.step) {
        case 1:
          if (this.newUserDetails.username !== ''
            && this.newUserDetails.email !== ''
            && this.newUserDetails.firstname !== ''
            && this.newUserDetails.lastname !== ''
            && this.newUserDetails.role !== ''
            && this.emailValidated == true
          ) {
            this.stepValidated = true
          } else {
            this.stepValidated = false
          }
          break;
        case 2:
          var siteSelected = false;
          this.allSites.forEach((site) => {
            site.selected == true ? siteSelected = true : null;
          });
          this.stepValidated = siteSelected;
          break;
        case 3:
          return Object.keys(this.roleIds).length > 0 ? this.stepValidated = true : this.stepValidated = false;
          break;
        case 4:
          var empty = true;
          for (var site in this.userMarkets) {
            this.userMarkets[site].length > 0 ? empty = false : null;
          }
          this.stepValidated = !empty;
          break;
        case 5:
  
          break;
      }
    } else {
      //return this.passwordValidated;
      this.stepValidated = this.passwordValidated;
    }
  }

  getLinks = () => {
    this.userService.GetAllLinks().subscribe(
      success => {
        this.allSites = success;
        this.allSites.forEach((site, index) => {
          this.allSites[index] = {
            description: site.description,
            selected: false
          }
        });
      },
      failure => {
        console.log(failure);
        this.toastr.ShowError(failure);
      }
    );
  }

  getRoles(site:string) {
    if ( this.roles[site] == undefined ) {
      this.roles[site] = {
        role: ''
      }
    }
    this.userService.GetRoles(site.toLowerCase(), this.userProfile.userName).subscribe(
      success => {
        if (typeof success == 'string') {
          this.toastr.ShowWarning('No roles available for the selected sites');
        } else {
        var index;
        if (this.userRoles.map(e => { return e.site }).indexOf(site) < 0) {
          this.userRoles.push({
            site: site,
            roles: []
          });
          index = this.userRoles.map(e => { return e.site }).indexOf(site);
          success.roleList.forEach(element => {
            this.roles.push(element.description);
            this.userRoles[index].roles.push({
              roleId: element.roleId,
              description: element.description,
              selected: false
            });
          });
        }
        }
      },
      failure => {
        console.log(failure);
      }
    )
  }

  getMarkets(site) {
    switch (site.toLowerCase()) {
      case 'qmanage' :
        this.userService.GetMarkets("qmanage", this.userProfile.userName).subscribe(
          success => {
            if (this.markets.qmanage.length < 1) {
              success.forEach(element => {
                this.markets.qmanage.push({
                  description: element.description,
                  marketId: element.marketId
                });
              });
              this.userMarkets.qmanage == undefined ? this.userMarkets.qmanage = [] : null;
              this.markets.qmanage.forEach(market => {
                this.userMarkets.qmanage.push({
                  market: market.description,
                  selected: false
                });
              });
            }
          },
          failure => {
            console.log(failure);
          }
        );
        break;
      case 'utilimeter' :
        this.userService.GetMarkets("utilimeter", this.userProfile.userName).subscribe(
          success => {
            if (this.markets.utilimeter.length < 1) {
              success.forEach(element => {
                this.markets.utilimeter.push(element.marketDescription);
              });
              this.userMarkets.utilimeter == undefined ? this.userMarkets.utilimeter = [] : null;
              this.markets.utilimeter.forEach(market => {
                this.userMarkets.utilimeter.push({
                  market: market,
                  selected: false
                });
              });
            }
          },
          failure => {
            console.log(failure);
          }
        )
        break;
    }
  }

  validatePassword = (e) => {
    if (e.target.id == "password") {
      if (e.target.value !== "") {
        e.target.value == this.newUserDetails.confirmPassword ? this.passwordValidated = true : this.passwordValidated = false;
      } else {
        (this.newUserDetails.password == "" || this.newUserDetails.password == null) && (this.newUserDetails.confirmPassword == "" || this.newUserDetails.confirmPassword == null) ? this.passwordValidated = true : this.passwordValidated = false;
      }
    } else if (e.target.id == "confirmPassword") {
      if (e.target.value !== "") {
        e.target.value == this.newUserDetails.password ? this.passwordValidated = true : this.passwordValidated = false;
      } else {
        (this.newUserDetails.password == "" || this.newUserDetails.password == null) && (this.newUserDetails.confirmPassword == "" || this.newUserDetails.confirmPassword == null) ? this.passwordValidated = true : this.passwordValidated = false;
      }
    }
    this.stepValidated = this.passwordValidated;
  }

  editUser = (userName) => {
    this.userToEdit = userName;
    this.editingUser = true;
  };

  PrintStep = () => {
    console.log(this.existingUserInfo);
    console.log(this.userMarkets);
  }

  CancelNewUser = () => {
    this.cancelUser();
    this.userMarkets = {};
    this.roleIds = {};
    this.selectedSites = [];
    this.userName = undefined;
    this.newUserDetails = {
      username: '',
      email: '',
      firstname: '',
      lastname: '',
      company: '',
      role: '',
      confirmPassword: null,
      password: null
    };
    this.step = 1;
  }

  ngOnDestroy() {
    this.closed.emit(this.newUserDetails.username);
    if (this.isNewUser) {
      this.userService.cancelUser(this.newUserDetails.username).subscribe(
        success => {
          console.log(success);
          //this.toastr.ShowSuccess('Operation cancelled');
        },
        failure => {
          this.toastr.ShowError(failure);
        }
      )
    }
    this.userName = undefined;
    this.newUserDetails = {
      username: '',
      email: '',
      firstname: '',
      lastname: '',
      company: '',
      role: '',
      confirmPassword: '',
      password: ''
    };
    console.log('navigating away');
  }

}
<div class="d-flex flex-column h-100">
  <div class="d-flex flex-row flexHeightContainer">
    <div class="dashboardRow">
      <dashboard></dashboard>
    </div>
  </div>
  <div class="d-flex flex-row footerRow align-items-center">
    <div class="w-50">
      <span class="footerText">&copy;2017 - {{ GetYear() }} QUES</span>
    </div>
    <div class="w-50" style="text-align: right">
      <span class="footerText"
        ><a target="_blank" class="queslink" href="http://www.ques.com"
          >Return to QUES.com</a
        ></span
      >
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  type="ball-spin-clockwise"
>
  <p style="color: white">Loading. Please Wait.</p>
</ngx-spinner>

import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../app.service";
import * as i2 from "ngx-toastr";
export class Toastr {
    constructor(appSrv, toastrService) {
        this.appSrv = appSrv;
        this.toastrService = toastrService;
        this.errorText = '';
        this.Clear = () => {
            this.toastrService.clear();
        };
    }
    ParseErrorText(response) {
        if (response.error && response.error.error_description) {
            this.errorText = response.error.error_description;
        }
        else {
            if (response.statusText)
                this.errorText = response.statusText;
            if (response.status)
                this.errorText += (this.errorText.length > 0 ? '<br/>' : '') + 'Status: ' + response.status;
            this.errorText += '<br/>Check browser console for details.';
        }
        return this.errorText;
    }
    ParseErrorTitle(response) {
        if (response.error && response.error.error &&
            (response.error.error == this.appSrv.AppSettingsGet().authError || response.error.error == this.appSrv.AppSettingsGet().reCaptchaError))
            return 'User Login Failed';
        return 'Internet Response Failed';
    }
    ShowError(text, title = null) {
        this.toastrService.error(text, title || 'Error!');
    }
    ShowErrorResponse(failure) {
        if (failure instanceof HttpErrorResponse) {
            try {
                this.toastrService.error(this.ParseErrorText(failure), this.ParseErrorTitle(failure));
                console.log('HttpErrorResponse Message: ', failure.message);
            }
            catch (ex) {
                this.toastrService.error('Toastr failed to toast.', 'Toastr Failed');
                console.log('Toastr Error. Ex: ', ex.name);
            }
        }
    }
    ShowSuccess(text, title = null) {
        this.toastrService.success(text, title);
    }
    ShowWarning(text, title = null) {
        this.toastrService.warning(text, title || 'Attention!');
    }
}
Toastr.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Toastr_Factory() { return new Toastr(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService)); }, token: Toastr, providedIn: "root" });

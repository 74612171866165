<div class="text-center sidenav ng-scope">
  <!-- <div class="row menu" style="margin-top:30px; margin-bottom:20px;" (click)="navigateSideNav($event)">
    <div class="sbicon"><i class="fa fa-home fa-3x"></i><br />Dashboard</div>
  </div>
  <div class="row menu" style="margin-top:30px; margin-bottom:20px;">
    <div class="sbicon"><i class="fa fa-registered fa-3x"></i><br />Register User</div>
  </div>
  <div class="row menu" style="margin-top:30px; margin-bottom:20px;" (click)="navigateSideNav($event)">
    <div class="sbicon"><i class="fa fa-user-circle fa-3x"></i><br />Account</div>
  </div>
  <div ng-if="jqx.IsUserRegistrationPage()" class="row menu" style="margin-top:30px; margin-bottom:20px;">
    <div class="sbicon"><i class="fa fa-user-plus fa-3x"></i><br />New User</div>
  </div>
  <div class="row menu" style="margin-top:30px; margin-bottom:20px;">
    <div class="sbicon" (click)="goToSupportDesk()"><i style="color:white" class="fa fa-info-circle fa-3x"></i><br />Service Desk</div>
  </div> -->
  <div
    *ngFor="let item of menuItems"
    [hidden]="!validateAdminRole(item.adminOnly)"
    class="row menu menu-item"
    style="font-size: 13px"
    (click)="navigateSideNav($event)"
  >
    <div class="sbicon">
      <i class="fa {{ item.icon }} fa-3x"></i><br />{{ item.text }}
    </div>
  </div>
</div>

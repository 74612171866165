<!-- <input type="text" data-filter="name" placeholder="Name" (keyup)="filterByValue($event)"/> -->
<!-- <div id="regGrid" class="k-grid row">
    <div id="userPushGrid" kendo-grid="cpu.pGrid"
         k-data-source="cpu.upgData"
         k-on-change="cpu.onSelection()"
         k-options="cpu.upgOptions"></div>
</div> -->
<kendo-grid #grid style="overflow-x: auto; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px" [selectable]="true" [resizable]="{enabled: true, checkboxOnly: false }" (pageChange)="pageChange($event)" [filter]="state.filter"
    (selectionChange)="select($event)" (cellClick)="cellClickHandler($event)" [data]="gridView" [pageable]="true" [pageSize]="pageSize" [skip]="skip" [style.width]="'100%'" [style.height]="'100%'" [scrollable]="'scrollable'"
    filterable="menu" (dataStateChange)="dataStateChange($event)">
        <kendo-grid-messages noRecords="No records">
        </kendo-grid-messages>
        <ng-template *ngIf="isWebAdmin" kendoGridToolbarTemplate>
            <button (click)="deleteUser()" class="k-button red"><i class="fa fa-trash"></i> Delete</button>
            <button (click)="resendInvite()" class="k-button blue"><i class="fa fa-paper-plane"></i> Resend Invite</button>
            <button (click)="unlockUsers()" class="k-button green"><i class="fa fa-unlock"></i> Unlock Selected Users</button>
            <button (click)="lockUsers()" class="k-button red"><i class="fa fa-lock"></i> Lock Selected Users</button>
        </ng-template>
        <kendo-grid-checkbox-column [width]="50">
            <ng-template kendoGridCellTemplate let-idx="rowIndex">
                <input [kendoGridSelectionCheckbox]="idx" />
            </ng-template>
        </kendo-grid-checkbox-column>
        <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title" [width]="col.width" [filter]="getFilter(col.field)">
            <ng-template style="text-align:center;" *ngIf="col.field == 'lockedOut'" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <i style="font-weight:bold;font-size:20px;color:lime;" *ngIf="dataItem.lockedOut == false" class="fa fa-unlock"></i><i style="font-weight:bold;font-size:20px;color:red;" *ngIf="dataItem.lockedOut == true" class="fa fa-lock"></i>
            </ng-template>
            <ng-template *ngIf="col.field == 'lockedOut'" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <!-- <kendo-grid-string-filter-cell
                    [column]="col"
                    [filter]="filter">
                </kendo-grid-string-filter-cell> -->
            </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column [width]="100" *ngFor="let column of columns; let i=index " [hidden]="hiddenFields.indexOf(column.field)> -1" field="{{column.field}}" title="{{column.title}}" filter="{{column.filter}}"></kendo-grid-column>
        <div *ngIf="gridData">
            <kendo-grid-command-column title="Created On" field="fileCreationDate" [width]="80">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{FormatDate(dataItem.fileCreationDate)}}
                </ng-template>
            </kendo-grid-command-column> 
        </div> -->
</kendo-grid>
<kendo-dialog *ngIf="dialogStatus && isWebAdmin" title="Edit User" (close)="onClose()">
    <!-- <new-user [newUserDetails]="userDetails" [isNewUser]="false" [userName]="userName" (finalize)="finalizeHandler($event)" (closed)="closeHandler($event)"></new-user> -->
    <new-user [newUserDetails]="userDetails" [isNewUser]="false" [userName]="userName" (finalize)="finalizeHandler($event)" (closed)="closeHandler($event)"></new-user>
    <!-- <kendo-dialog-actions>
        <button kendoButton (click)="onAccept()">Yes</button>
        <button kendoButton (click)="onDecline()">No</button>
    </kendo-dialog-actions> -->
</kendo-dialog>
<kendo-dialog *ngIf="deleteDialogStatus" title="Deletion Confirmation" (close)="onClose()">
    <div style="text-align: center;">
        <div style="padding: 20px;">Are you sure you wish to delete this user: {{selectedUsers[0].dataItem.userName}}?</div>
        <kendo-dialog-actions>
            <button kendoButton (click)="confirmDelete()">Yes</button>
            <button kendoButton (click)="deleteDialogStatus = false;">No</button>
        </kendo-dialog-actions>
    </div>
</kendo-dialog>
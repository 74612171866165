import { Injectable } from '@angular/core';
import { IUserAccount } from 'src/models/interfaces/IUSerAccount';
import { HttpClient } from '@angular/common/http';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UseraccountService {

constructor(private http:HttpClient) { }


  GetAccount = (userName:string) =>  this.http.post<IUserAccount>(Environment.serverPath +"/api/accounts/myaccount",{userName:userName});

  DeleteAvatar = (userName:string) =>  this.http.post<any>(Environment.serverPath +"/api/accounts/deleteavatar",{userName:userName});

  UpdateAccount = (form:any) =>  this.http.post<any>(Environment.serverPath +"/api/accounts/updateMyAccount", form);

  LockAccounts = (lockList:Array<string>) => this.http.post<any>(Environment.serverPath +"/api/accounts/batchlock",{LockList:lockList});

  UnlockAccounts = (lockList:Array<string>) => this.http.post<any>(Environment.serverPath +"/api/accounts/batchunlock",{LockList:lockList});
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { UseraccountModule } from '../useraccount/useraccount.module'
import { NewUserComponent } from './newuser.component';
import { FormsModule } from '@angular/forms';
import { GridModule } from "@progress/kendo-angular-grid";
import { UploadModule } from '@progress/kendo-angular-upload';
@NgModule({
  imports: [
    UseraccountModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    GridModule,
    UploadModule
  ],
  declarations: [NewUserComponent],
  exports: [NewUserComponent]
})
export class NewUserModule { }

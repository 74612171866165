import { AdminSelect } from 'src/models/interfaces/admin.select';
import { Environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAssign } from 'src/models/interfaces/user.assign';

@Injectable({
  providedIn: 'root'
})

export class UserManagementService {

  static readonly authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
  });

  private baseUrl: string = Environment.serverPath;
  private quaditUrl: string = Environment.quaditPath;
  private utmeterUrl: string = Environment.utmeterPath;
  private localUrl : string = 'http://localhost:63000';

  //private baseUrl: string = "http://localhost:63000";
  //private localQauditUrl: string = "http://localhost:62029";
  //private localUtilimeterUrl: string = "http://localhost:64655";

  constructor(private httpClient: HttpClient) { }

  SetStagingUser(stagingUser) {
    console.log({ 
        //Company: stagingUser.company,
        ConfirmPassword: '',
        Email: stagingUser.email,
        FirstName: stagingUser.firstname,
        LastName: stagingUser.lastname,
        Password: '',
        Portalrole: stagingUser.role,
        UserName: stagingUser.username,
        Id: stagingUser.username
    });
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/stagingfirststep',
    { 
        //Company: stagingUser.company,
        ConfirmPassword: 'testpassword',
        Email: stagingUser.email,
        FirstName: stagingUser.firstname,
        LastName: stagingUser.lastname,
        Password: 'testpassword',
        Portalrole: stagingUser.role,
        UserName: stagingUser.username,
        Id: stagingUser.username
    },
    { headers: UserManagementService.authHeaders });
    //return { SetRoleId: roleId, adminName: adminName, userName: userName };
  }

  // Test Function
  GetMarkets(site:string, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.get<any>(this.quaditUrl + '/api/qm/subordinatemarkets');
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/mktcontrol/usermarkets',
        {
          UserName: userName
        });
        break;
    }
    
  }

  GetRoles(site:String, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/utiliRole/gridroles',
        {
        	UserName : userName
        });
        break;
    }
  }

  GetUsers(site:string, userName:String) {
    switch(site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
    }
  }

  
  GetGridUsers(userName:string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/gridusers',
    { 
      UserName: userName, 
    },
    { headers: UserManagementService.authHeaders });
  }

  LockUsers(userList:Array<string>) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/batchlock',
    { 
      LockList: userList
    },
    { headers: UserManagementService.authHeaders });
  }

  UnlockUsers(userList:Array<string>) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/batchunlock',
    { 
      LockList: userList
    },
    { headers: UserManagementService.authHeaders });
  }

  ResendInvite(userList:Array<string>) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/batchreinvite',
    { 
      LockList: userList
    },
    { headers: UserManagementService.authHeaders });
  }

  DeleteUser(userId:string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/delete',
    { 
      deleteId: userId
    },
    { headers: UserManagementService.authHeaders });
  }

  /***** Parsing Methods *****/

  ParseImageUrl(url: UserAssign): string {
  
    if (url == undefined) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
    if (url.image == null) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';

    return 'data:image/JPEG;base64,' + url.image;
  }

  ParseUserDetailImageUrl(userImage: string): string {
  
    if (userImage == undefined || userImage == null) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';

    return 'data:image/JPEG;base64,' + userImage;
  }  
}
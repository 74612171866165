import { AdminAssign } from 'src/models/interfaces/admin.assign';
import { AdminSelect } from 'src/models/interfaces/admin.select';
import { AppService } from '../../app.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart} from '@angular/cdk/drag-drop';
import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ConsoleLogger } from '../../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { Toastr } from '../../common/toastr.service';
import { UserAssignmentService } from '../../userassignment/userassignment.service';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { UserDetails } from 'src/models/interfaces/user.details';
import { MarketAssign } from 'src/models/interfaces/market.assign';
import { ProductAssign } from 'src/models/interfaces/product.assign';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'adminlist',
  templateUrl: './adminlist.component.html',
  styleUrls: ['./adminlist.component.scss', '../../userassignment/userassignment.component.scss'],
})
export class AdminListComponent implements OnInit {

    @Input() cdkDropListData: any;
    @Input() title:string;
    @Input() adminAssign: AdminAssign[] = []; 
    @Output() adminAssignChange = new EventEmitter<any>();
    @Input() adminSelect: AdminSelect;
    @Output() adminSelectChange = new EventEmitter<any>();
    @Input() isUsersLoaded: boolean = false;
    @Input() userArray: UserAssign[] = [];
    @Output() userArrayChange = new EventEmitter<UserAssign[]>();
    @Input() userDetails: UserDetails;
    @Output() userDetailsChange = new EventEmitter();
    @Input() marketAssign: MarketAssign[] = []; 
    @Input() isNested: boolean;
    @Input() productAssign: ProductAssign[] = [];
    @Output() productAssignChange = new EventEmitter<any>();
    @Input() userProfile:UserProfile;

  public environmentUrl: string;
  public helpIconUrl: string;
  public isCollapsed: boolean = true;
  public origArray: UserAssign[] = [];
  public showConfirm: boolean = false;

  constructor(private appSrv: AppService,
              private consoleLogger: ConsoleLogger,
              private toastr: Toastr,
              public userAssignmentService: UserAssignmentService) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.helpIconUrl = "/assets/images/plus.png";
    this.adminSelect = {
      adminName: '',
      assigneeName: ''      
    };
    this.userDetails = {
      company: '',
      email: '',
      fullName: '',
      image: '',
      job: '',
      phone: '',
      role: '',
      userName: '',
      valid: false
     };
    //this.GetUserListOnLoad();
    //this.debugComponent();
  }

  /***** Event listeners *****/

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    //console.log(event);
    
    if (event.keyCode === 46) {

      if (this.adminSelect.adminName && this.adminSelect.assigneeName) {
        this.showConfirm = true;
      } else {
        this.toastr.ShowWarning('Select assigned user to delete.', 'Nothing Selected')     
      }
    }
  }

  /***** Events *****/

  GetUserListOnLoad() {
    this.appSrv.ShowSpinnerSet(true);

    this.userAssignmentService.GetAdminAssigned().subscribe(
      success => {
        this.adminAssign = success.map((x: any) => Object.assign({}, x));
        this.isUsersLoaded = true;
        this.appSrv.ShowSpinnerSet(false);
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Fetch admin assignment list failed.' });
      });
  }

  dragStarted(un: string) {
    this.UpdateUserDetails(un);
  }

  onAssignVisibleClick(aa:any) {
    aa.assignVisible = !aa.assignVisible;
    this.productAssignChange.emit(this.productAssign);
  }

  onClick(assu: string) {
    this.UpdateUserDetails(assu);
  }

  onClickAssigned(au: string, assu: string) {
    this.adminSelect = {
      adminName: au,
      assigneeName: assu
    };
    this.UpdateUserDetails(assu);
    this.adminSelectChange.emit(this.adminSelect);
  }

  onCloseClick() {
    this.showConfirm = false;
  }

  onDeleteConfirm(yesNo: boolean) {
    this.onCloseClick();

    if (!yesNo) return;

    this.appSrv.ShowSpinnerSet(true);
    this.userAssignmentService.DeleteAdminAssignment(this.adminSelect).subscribe(
      () => {
        var selAdmin = this.adminAssign.find(aa => aa.adminName == this.adminSelect.adminName);

        if (selAdmin == null) {
          this.toastr.ShowWarning('Display failed to update. Please reload this page.', 'Display sync error')
        } else {
          var saIndex = selAdmin.assigneeUsers.findIndex(as => as.userName == this.adminSelect.assigneeName);
    
          if (saIndex == -1) {
            this.toastr.ShowWarning('Display data out of date. Please reload this page.', 'Display sync error')
          } else {
            selAdmin.assigneeUsers.splice(saIndex, 1);
          }  
        }    

        this.toastr.ShowSuccess('Assignment delete successful.')
        this.appSrv.ShowSpinnerSet(false);
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
      });
  }

  onDrop(event: CdkDragDrop<string[]>, adminName: string, isRole: boolean) {

    var aaData;
    var products;
    var markets;
    var previousData

    // Looks for role in nested productAssign object
    if (isRole) {
      products = this.productAssign.find(aa => aa.productName == event.container.element.nativeElement.attributes["product"].value);
      markets = products.markets.find(product => product.marketName == event.container.element.nativeElement.attributes["market"].value);
      aaData = markets.roles.find(rr => rr.roleName == event.container.element.nativeElement.attributes["role"].value);
    } else {
      aaData = this.adminAssign.find(aa => aa.adminName == adminName);
    }

    if (aaData.assigneeUsers == null) {
      aaData.assigneeUsers = [];
    } else {
      if (aaData.assigneeUsers.find(au => au.userName == event.item.data.userName)) {
        this.toastr.ShowWarning(event.item.data.userName + ' already assigned to ' + adminName + '.', 'Already Assigned')
        return;
      } 
    }

    this.appSrv.ShowSpinnerSet(true);

    // Removes user from previous role/list
    if (isRole) {
      if (event.previousContainer.element.nativeElement.attributes["product"]) {
        products = this.productAssign.find(aa => aa.productName == event.previousContainer.element.nativeElement.attributes["product"].value);
        markets = products.markets.find(product => product.marketName == event.previousContainer.element.nativeElement.attributes["market"].value);
        previousData = markets.roles.find(rr => rr.roleName == event.previousContainer.element.nativeElement.attributes["role"].value);
        var index = previousData.assigneeUsers.indexOf(event.item.data);
        if (index !== -1) previousData.assigneeUsers.splice(index, 1);
      }
    } else {
      if (event.previousContainer.element.nativeElement.attributes["id"]) {
        var prevData = this.adminAssign.find(aa => aa.adminName == event.previousContainer.element.nativeElement.attributes["id"].value);
        if (prevData) {
          var prevAssign = {
            adminName: event.previousContainer.element.nativeElement.attributes["id"].value,
            assigneeName: event.item.data.userName
          }
          this.userAssignmentService.DeleteAdminAssignment(prevAssign).subscribe(
            () => {
              var selAdmin = this.adminAssign.find(aa => aa.adminName == prevAssign.adminName);
      
              if (selAdmin == null) {
                this.toastr.ShowWarning('Display failed to update. Please reload this page.', 'Display sync error')
              } else {
                var saIndex = selAdmin.assigneeUsers.findIndex(as => as.userName == prevAssign.assigneeName);
          
                if (saIndex == -1) {
                  this.toastr.ShowWarning('Display data out of date. Please reload this page.', 'Display sync error')
                } else {
                  selAdmin.assigneeUsers.splice(saIndex, 1);
                }  
              }    
      
              this.toastr.ShowSuccess('Reassignment successful.')
              this.appSrv.ShowSpinnerSet(false);
            },
            failure => {
              this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
            });
          var i = prevData.assigneeUsers.indexOf(event.item.data);
          if (i !== -1) prevData.assigneeUsers.splice(i, 1);
        }
      }
    }

    isRole ? this.productAssignChange.emit(event.item.data) : this.adminAssignChange.emit(event.item.data);

    this.appSrv.ShowSpinnerSet(false);

    if (isRole) {
      this.userAssignmentService.SetSiteRole(event.container.element.nativeElement.attributes['roleid'].value, this.userProfile.userName, event.item.data.userName).subscribe(
        () => {
          aaData.assigneeUsers.push( event.item.data );    
          aaData.assigneeUsers.sort((a, b) => {
            if (a.userName < b.userName) return -1;
            else if (a.userName > b.userName) return 1;
            else return 0;
          });
          this.appSrv.ShowSpinnerSet(false);
          this.toastr.ShowSuccess('Assignment Successful');
        },
        failure => {
          this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
        });
    } else {
      this.userAssignmentService.SetAdminAssignment(adminName, event.item.data.userName).subscribe(
        () => {
          aaData.assigneeUsers.push( event.item.data );    
          aaData.assigneeUsers.sort((a, b) => {
            if (a.userName < b.userName) return -1;
            else if (a.userName > b.userName) return 1;
            else return 0;
          });
          this.appSrv.ShowSpinnerSet(false);
        },
        failure => {
          this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
        });
    }
  }

  /***** Rendering Methods *****/

  toggleAssignPanel(aa: AdminAssign) : string {
    return aa.assignVisible ? "/assets/images/minus.png" : "/assets/images/plus.png";
  }

  /***** Private Common Methods *****/

  private ErrorReporter({ failure, consoleText }: { failure: any; consoleText: string; }) {
    this.consoleLogger.LogStatusToConsole(failure, consoleText);
    this.toastr.ShowErrorResponse(failure);
    this.appSrv.ShowSpinnerSet(false);
  }

  private UpdateUserDetails(un: string) {
    //console.log('clicked');
    this.userAssignmentService.GetShowUserDetails({ un }).subscribe(
      success => {
        this.userDetails = {
          company: success.company,
          email: success.email,
          fullName: success.firstName + ' ' + success.lastName,
          image: this.userAssignmentService.ParseUserDetailImageUrl(success.image),
          job: success.jobTitle,
          phone: success.phoneNumber,
          role: success.role,
          userName: success.userName,
          valid: true
          }
        this.userDetailsChange.emit(this.userDetails);
      },
      () => {
        this.userDetails = {
          company: '',
          email: '',
          fullName: '',
          image: Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg',
          job: '',
          phone: '',
          role: '',
          userName: '',
          valid: false
          }
      });   
  }

  private UpdateUserDetailsDebug(un: string) {
    //console.log(un);
    this.userAssignmentService.GetShowUserDetails({ un }).subscribe(
      success => {
        this.userDetails = {
          company: success.company,
          email: success.email,
          fullName: success.firstName + ' ' + success.lastName,
          image: this.userAssignmentService.ParseUserDetailImageUrl(success.image),
          job: success.jobTitle,
          phone: success.phoneNumber,
          role: success.role,
          userName: success.userName,
          valid: true
          }
      },
      () => {
        this.userDetails = {
          company: '',
          email: '',
          fullName: '',
          image: Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg',
          job: '',
          phone: '',
          role: '',
          userName: '',
          valid: false
          }
      }); 
  }

  // Debug Functions

  debugComponent() {

  }

}
import { AdminAssign } from 'src/models/interfaces/admin.assign';
import { AdminSelect } from 'src/models/interfaces/admin.select';
import { AppService } from '../app.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart} from '@angular/cdk/drag-drop';
import { Component, OnInit, HostListener } from '@angular/core';
import { ConsoleLogger } from '../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { Toastr } from '../common/toastr.service';
import { UserAssignmentService } from './userassignment.service';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { UserDetails } from 'src/models/interfaces/user.details';

@Component({
  selector: 'userassignment',
  templateUrl: './userassignment.component.html',
  styleUrls: ['./userassignment.component.scss'],
})
export class UserAssignmentComponent implements OnInit {
  public adminAssign: AdminAssign[] = [];
  public adminSelect: AdminSelect;
  public environmentUrl: string;
  public helpIconUrl: string;
  public isCollapsed: boolean = true;
  public isUsersLoaded: boolean = false;
  public origArray: UserAssign[] = [];
  public showConfirm: boolean = false;
  public userArray: UserAssign[] = [];
  public userDetails: UserDetails;

  constructor(private appSrv: AppService,
              private consoleLogger: ConsoleLogger,
              private toastr: Toastr,
              public userAssignmentService: UserAssignmentService) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.helpIconUrl = "/assets/images/plus.png";
    this.adminSelect = {
      adminName: '',
      assigneeName: ''      
    };
    this.userDetails = {
      company: '',
      email: '',
      fullName: '',
      image: '',
      job: '',
      phone: '',
      role: '',
      userName: '',
      valid: false
     };
    this.GetUserListOnLoad();

    this.debugComponent();
  }

  /***** Event listeners *****/

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);
    
    if (event.keyCode === 46) {

      if (this.adminSelect.adminName && this.adminSelect.assigneeName) {
        this.showConfirm = true;
      } else {
        this.toastr.ShowWarning('Select assigned user to delete.', 'Nothing Selected')     
      }
    }
  }

  /***** Events *****/

  GetUserListOnLoad() {
    this.appSrv.ShowSpinnerSet(true);

    this.userAssignmentService.GetRoleNameAvatar().subscribe(
      success => {
        console.log(success);
        var search = success.filter(user => user.userName === 'cacruz');
        console.log(search);
        this.origArray = success.map((x: any) => Object.assign({}, x));
        this.resetList();
        this.isUsersLoaded = true;
        this.userAssignmentService.GetAdminAssigned().subscribe(
          success => {
            console.log(success);
            this.adminAssign = success.map((x: any) => Object.assign({}, x));
            this.isUsersLoaded = true;
            this.appSrv.ShowSpinnerSet(false);
          },
          failure => {
            console.log(failure);
            this.ErrorReporter({ failure, consoleText: 'Fetch admin assignment list failed.' });
          });
      },
      failure => {
        console.log(failure);
        this.ErrorReporter({ failure, consoleText: 'Fetch User List Failed.' });
      });
  }

  dragStarted(un: string) {
    this.UpdateUserDetails(un);
  }

  onAssignVisibleClick(aa: AdminAssign) {
    aa.assignVisible = !aa.assignVisible;
  }

  onClick(assu: string) {
    this.UpdateUserDetails(assu);
  }

  onClickAssigned(au: string, assu: string) {
    this.adminSelect = {
      adminName: au,
      assigneeName: assu
    };
    this.UpdateUserDetails(assu);
  }

  onCloseClick() {
    this.showConfirm = false;
  }

  onDeleteConfirm(yesNo: boolean) {
    this.onCloseClick();

    if (!yesNo) return;

    this.appSrv.ShowSpinnerSet(true);
    this.userAssignmentService.DeleteAdminAssignment(this.adminSelect).subscribe(
      () => {
        var selAdmin = this.adminAssign.find(aa => aa.adminName == this.adminSelect.adminName);

        if (selAdmin == null) {
          this.toastr.ShowWarning('Display failed to update. Please reload this page.', 'Display sync error')
        } else {
          var saIndex = selAdmin.assigneeUsers.findIndex(as => as.userName == this.adminSelect.assigneeName);
    
          if (saIndex == -1) {
            this.toastr.ShowWarning('Display data out of date. Please reload this page.', 'Display sync error')
          } else {
            selAdmin.assigneeUsers.splice(saIndex, 1);
          }  
        }    

        this.toastr.ShowSuccess('Assignment delete successful.')
        this.appSrv.ShowSpinnerSet(false);
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
      });
  }

  onDrop(event: CdkDragDrop<string[]>, adminName: string) {
    var aaData = this.adminAssign.find(aa => aa.adminName == adminName);

    console.log('dropped userassignment');

    if (aaData.assigneeUsers == null){
      aaData.assigneeUsers = [];
    } else {

      if (aaData.assigneeUsers.find(au => au.userName == event.item.data.userName)){
        this.toastr.ShowWarning(event.item.data.userName + ' already assigned to ' + adminName + '.', 'Already Assigned')
        return;
      } 
    }

    this.appSrv.ShowSpinnerSet(true);

    // DEBUG

    aaData.assigneeUsers.push( event.item.data );    
    aaData.assigneeUsers.sort((a, b) => {
      if (a.userName < b.userName) return -1;
      else if (a.userName > b.userName) return 1;
      else return 0;
    });
    this.appSrv.ShowSpinnerSet(false);

    /* this.userAssignmentService.SetAdminAssignment(adminName, event.item.data.userName).subscribe(
      () => {
        aaData.assigneeUsers.push( event.item.data );    
        aaData.assigneeUsers.sort((a, b) => {
          if (a.userName < b.userName) return -1;
          else if (a.userName > b.userName) return 1;
          else return 0;
        });
        this.appSrv.ShowSpinnerSet(false);
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
      }); */
  }

  checkAdminAssign(event) {
    var index = this.userArray.indexOf(event);
    if (index !== -1) this.userArray.splice(index, 1);
  }

  /***** Rendering Methods *****/

  toggleAssignPanel(aa: AdminAssign) : string {

    return aa.assignVisible ? "/assets/images/minus.png" : "/assets/images/plus.png";
  }

  toggleHelpText() {
    this.isCollapsed = !this.isCollapsed
    this.helpIconUrl = this.isCollapsed ? "/assets/images/plus.png" : "/assets/images/minus.png";
  }

  /***** Private Common Methods *****/

  private ErrorReporter({ failure, consoleText }: { failure: any; consoleText: string; }) {
    this.consoleLogger.LogStatusToConsole(failure, consoleText);
    this.toastr.ShowErrorResponse(failure);
    this.appSrv.ShowSpinnerSet(false);
  }

  private resetList() {
    this.userArray = [];

    setTimeout(() => { 
      this.userArray = this.origArray.slice();
      console.log(this.userArray);
    }, 0);    
  }

  private UpdateUserDetails(un: string) {
    this.userAssignmentService.GetShowUserDetails({ un }).subscribe(
      success => {
        this.userDetails = {
          company: success.company,
          email: success.email,
          fullName: success.firstName + ' ' + success.lastName,
          image: this.userAssignmentService.ParseUserDetailImageUrl(success.image),
          job: success.jobTitle,
          phone: success.phoneNumber,
          role: success.role,
          userName: success.userName,
          valid: true
          }
      },
      () => {
        this.userDetails = {
          company: '',
          email: '',
          fullName: '',
          image: Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg',
          job: '',
          phone: '',
          role: '',
          userName: '',
          valid: false
          }
      });   
  }

  // Debug Functions

  debugComponent() {
    /* this.userDetails = {
      company: 'QUES',
      email: 'c@ques.com',
      fullName: 'David Hill',
      image: '',
      job: 'Job',
      phone: '111.222.3333',
      role: 'User',
      userName: 'DHill',
      valid: true
    };
    this.userArray.push({
      image: 'http://tpc.googlesyndication.com/simgad/2735792949360454598',
      role: 'user',
      userName: 'Bob Bobinson'
    })
    this.userArray.push({
      image: 'http://tpc.googlesyndication.com/simgad/2735792949360454598',
      role: 'user',
      userName: 'Rob Robinson'
    })
    this.userArray.push({
      image: 'http://tpc.googlesyndication.com/simgad/2735792949360454598',
      role: 'user',
      userName: 'Jimothy Jimbo'
    })
    this.adminAssign.push({
      adminName: 'JLindquist',
      adminRole: 'admin',
      assignVisible: true,
      assigneeUsers: []
    })
    this.adminAssign.push({
      adminName: 'MBison',
      adminRole: 'admin',
      assignVisible: true,
      assigneeUsers: []
    })
    this.isUsersLoaded = true; */
  }

}
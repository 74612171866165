/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filedownload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./filedownload.component";
import * as i3 from "../app.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../common/toastr.service";
var styles_FileDownloadComponent = [i0.styles];
var RenderType_FileDownloadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileDownloadComponent, data: {} });
export { RenderType_FileDownloadComponent as RenderType_FileDownloadComponent };
export function View_FileDownloadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "centreMiddle divCenterBoxItems w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "panelBase"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "panelHeader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download File"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "panelDownload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-download btn-download-display"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Click Here to download your file"]))], null, null); }
export function View_FileDownloadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-download", [], null, null, null, View_FileDownloadComponent_0, RenderType_FileDownloadComponent)), i1.ɵdid(1, 114688, null, 0, i2.FileDownloadComponent, [i3.AppService, i4.HttpClient, i5.Toastr], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileDownloadComponentNgFactory = i1.ɵccf("file-download", i2.FileDownloadComponent, View_FileDownloadComponent_Host_0, {}, {}, []);
export { FileDownloadComponentNgFactory as FileDownloadComponentNgFactory };

import { NgModule } from "@angular/core";
import { ManageUsersComponent } from './manageusers.component';
import { NewUserComponent } from '../newuser/newuser.component';
import { CommonModule } from '@angular/common';
import { GridModule } from "@progress/kendo-angular-grid";
import { DialogModule }  from '@progress/kendo-angular-dialog';
import { NewUserModule } from '../newuser/newuser.module';

@NgModule({
    imports: [
        CommonModule,
        GridModule,
        DialogModule,
        NewUserModule
    ],
    declarations: [ ManageUsersComponent ],
    bootstrap: [ ManageUsersComponent ]      
})

export class ManageUsersModule { } 
<div>
    <div class="d-flex flex-row columnTitle">
      <span class="titleText">{{ title ? title : 'List' }}</span>
    </div>
    <div class="d-flex flex-row columnSeparator"></div>
    <div class="d-flex flex-column columnListContainer columnListContainerTop listContainer" *ngIf="isUsersLoaded">
      <div class="d-flex flex-row userAdminContainer" *ngFor="let aa of ( productAssign.length > 0 ? productAssign : adminAssign )">
        <table class="userAdminItem">
          <tr>
            <td cdkDropList class="userAdminCell" (cdkDropListDropped)="onDrop($event, (aa.productName ? aa.productName : aa.adminName), false)">
              <img class="assigneeToggle" src="{{ environmentUrl }}{{ toggleAssignPanel(aa) }}" (click)="onAssignVisibleClick(aa)"/>
              <span class="titleText">{{ aa.productName ? aa.productName : aa.adminName }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <div *ngFor="let market of aa.markets" class="d-flex flex-row userAdminContainer">
                <table class="userAdminItem" [ngbCollapse]="!aa.assignVisible">
                  <tr>
                    <td class="userAdminCell">
                      <img class="assigneeToggle" src="{{ environmentUrl }}{{ toggleAssignPanel(market) }}" (click)="onAssignVisibleClick(market)"/>
                      <span class="titleText">{{ market.marketName }}</span>
                    </td>
                  </tr>       
                  <tr>
                    <td>
                      <div *ngFor="let role of market.roles" class="d-flex flex-row userAdminContainer">
                        <table class="userAdminItem" [ngbCollapse]="!market.assignVisible">
                          <tr>
                            <td cdkDropList [attr.role]="role.roleName" [attr.roleId]="role.roleId" [attr.market]="market.marketName" [attr.product]="aa.productName" class="userAdminCell" (cdkDropListDropped)="onDrop($event, role.roleName, true)">
                              <img class="assigneeToggle" src="{{ environmentUrl }}{{ toggleAssignPanel(role) }}" (click)="onAssignVisibleClick(role)"/>
                              <span class="titleText">{{ role.roleName }}</span>
                            </td>
                          </tr>       
                          <tr>
                            <td cdkDropList class="list" (cdkDropListDropped)="onDrop($event, role.roleName, true)" [attr.role]="role.roleName" [attr.market]="market.marketName" [attr.product]="aa.productName">
                              <table cdkDrag id="cell{{ assu.userName }}" class="userListItem" *ngFor="let assu of role.assigneeUsers" (click)="onClickAssigned(role.adminName, role.userName)" [ngbCollapse]="!role.assignVisible"
                            [class.userListItemSelected]="role.roleName == adminSelect.adminName && assu.userName == adminSelect.assigneeName" [cdkDragData]="assu">
                                <tr>
                                  <td rowspan="3" class="imageCell">
                                    <img class="profileimage" src="{{ userAssignmentService.ParseImageUrl(assu) }}" />
                                  </td>
                                  <td cell="textCell">
                                    <span class="titleText">{{ assu.userName }}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="d-flex flex-row columnSeparator"></div>
                                  </td>
                                </tr>
                                <tr>
                                  <td cell="textCell">
                                    <span class="titleText">{{ assu.role }}</span>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>   
                        </table>
                      </div>

                    </td>
                  </tr>   
                </table>
              </div>
              <div *ngIf="!aa.markets && aa.assigneeUsers != null" class="d-flex flex-row userAssigneeCell">
                <div cdkDropList id="{{ aa.adminName }}" class="d-flex flex-column columnListContainer w-100" (cdkDropListDropped)="onDrop($event, aa.adminName, false)" [cdkDropListData]="aa.assigneeUsers">
                  <table cdkDrag id="cell{{ assu.userName }}" class="userListItem" *ngFor="let assu of aa.assigneeUsers" (click)="onClickAssigned(aa.adminName, assu.userName)" [ngbCollapse]="!aa.assignVisible"
                    [class.userListItemSelected]="aa.adminName == adminSelect.adminName && assu.userName == adminSelect.assigneeName" [cdkDragData]="assu">
                    <tr>
                      <td rowspan="3" class="imageCell">
                        <img class="profileimage" src="{{ userAssignmentService.ParseImageUrl(assu) }}" />
                      </td>
                      <td cell="textCell">
                        <span class="titleText">{{ assu.userName }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row columnSeparator"></div>
                      </td>
                    </tr>
                    <tr>
                      <td cell="textCell">
                        <span class="titleText">{{ assu.role }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </td>
          </tr>          
        </table>
      </div>
    </div>
  </div>
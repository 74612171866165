<div>
    <div class="topNav">
        <div class="w-100 p-0">
            <top-nav></top-nav>
        </div>
    </div>
    <div class="request-form-container">
        <div class="d-flex flex-column col-1 p-2 h-100 align-items-center sideNav text-center">
            <div _ngcontent-ups-c4="" (click)="editing = false" class="row menu ng-star-inserted" style="margin-top:30px; margin-bottom:20px;"><div _ngcontent-ups-c4="" class="sbicon"><i _ngcontent-ups-c4="" class="fa fa-wpforms fa-3x"></i><br _ngcontent-ups-c4="">Request Form</div></div>
            <div _ngcontent-ups-c4="" (click)="editing = true" class="row menu ng-star-inserted" style="margin-top:30px; margin-bottom:20px;"><div _ngcontent-ups-c4="" class="sbicon"><i _ngcontent-ups-c4="" class="fa fa-th-list fa-3x"></i><br _ngcontent-ups-c4="">View Requests</div></div>
        </div>
        <div *ngIf="!editing" class="request-form">
            <div>
                <h3>Project Request Form</h3>
                <br>
                <!-- Editable Form -->
                <!-- <form [formGroup]="projectRequest" (ngsubmit)="onSubmit(projectRequest.value)">
                    <div *ngFor="let field of sampleObject.rows">
                        <label>{{field.fieldName}}<span *ngIf="field.required" style="color: red; font-weight: bold;"> *</span></label>
                        <p>{{field.description}}</p>
                        <div [ngSwitch]="field.fieldType">
                            <div *ngSwitchCase="'textSingle'">  
                                <input formControlName="customer" [required]="field.required" type="text" maxLength="{{field.maxLength}}" ng-pattern="!field.numbersAllowed ? '[a-zA-Z]*'' : '*' '"/>
                            </div>
                            <div *ngSwitchCase="'textMulti'">
                                <textarea formControlName="customer" [required]="field.required" maxLength="{{field.maxLength}}" ng-pattern="!field.numbersAllowed ? '[a-zA-Z]*'' : '*' '"></textarea>
                            </div>
                            <div *ngSwitchCase="'radioButtons'">
                                <div *ngFor="let option of field.options" >
                                    <input type="radio" name="{{field.fieldName}}" value="{{option}}"/><label>{{option}}</label><br>
                                </div>
                            </div>
                            <div *ngSwitchCase="'FileUpload'">
                                
                            </div>
                            <div *ngSwitchDefault>Field type not specified</div>
                        </div>
                        <br>
                    </div>
                    <input type="submit" value="Submit Form"/>
                </form> -->
        
                <!-- Static Form -->
                <form [formGroup]="projectRequest" (ngSubmit)="onSubmit(projectRequest.value)" (change)="checkValues()">
                    <!-- <input type="hidden" id="id" formControlName="id" value="1"/> -->
                    <div>
                        <label>Customer<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Name of the Utility or Company</p>
                        <div>
                            <input id="customer" formControlName="customer" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Product Description</label>
                        <p>Description of the services (i.e. GLT, URD, OH, Engineering, etc.) offered and what IT will need to facilitate.</p>
                        <div>
                            <textarea id="description" formControlName="description" pattern="[a-zA-Z]*'"></textarea>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Asset Type<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Poles, Meters, Safety, etc</p>
                        <div>
                            <input id="assetType" formControlName="assetType" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Estimated Execution Date<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>The date you expect to begin in the field</p>
                        <div>
                            <input id="estimatedDate" formControlName="estimatedDate" type="date" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Job Code<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Job code IT can bill for any work done on this project</p>
                        <div>
                            <input id="jobCode" formControlName="jobCode" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Have you contacted IT directly?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>You must contact IT by phone or email regardless of filling out this form</p>
                        <div>
                            <input type="radio" name="contactedIT" formControlName="contactedIT" value="true"/><label>Yes</label><br>
                            <input type="radio" name="contactedIT" formControlName="contactedIT" value="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Estimated Number of Assets</label>
                        <p>A count of the anticpated number of assets that the project will have or will need to be loaded into the system for collection.</p>
                        <div>
                            <input id="assetNumber" formControlName="assetNumber" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Do you have any asset data?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Any shape, csv, or xls files  (i.e. data to be loaded)</p>
                        <div>
                            <input type="radio" name="assetBool" formControlName="assetBool" value="true"/><label>Yes</label><br>
                            <input type="radio" name="assetBool" formControlName="assetBool" value="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="projectRequest.value.assetBool == 'true'">
                        <label>Asset Data Upload<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="file" id="assetData" name="assetData" (change)="handleFileInput($event)" multiple>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Utility IT/PM Contact Name</label>
                        <p>Contact that IT can reach out to for more details</p>
                        <div>
                            <input id="contactName" formControlName="contactName" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Utility IT/PM Contact Email</label>
                        <p>Contacts Email</p>
                        <div>
                            <input id="contactEmail" formControlName="contactEmail" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Utility IT/PM Contact Phone Number</label>
                        <p>Contacts Phone Number</p>
                        <div>
                            <input id="contactNumber" formControlName="contactNumber" type="text" pattern="[a-zA-Z]*'"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Is there a contract?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Contract for work to be performed</p>
                        <div>
                            <input type="radio" name="contractBool" formControlName="contractBool" value="true"/><label>Yes</label><br>
                            <input type="radio" name="contractBool" formControlName="contractBool" value="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="projectRequest.value.contractBool == 'true'">
                        <label>Contract Upload<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="file" id="contract" name="contract" (change)="handleFileInput($event)">
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Is there an RFP?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="radio" name="rfpBool" formControlName="rfpBool" value="true"/><label>Yes</label><br>
                            <input type="radio" name="rfpBool" formControlName="rfpBool" value="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="projectRequest.value.rfpBool == 'true'">
                        <label>RFP Upload<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="file" id="rfp" name="rfp" (change)="handleFileInput($event)">
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Notes</label>
                        <p></p>
                        <div>
                            <textarea id="notes" formControlName="notes" pattern="[a-zA-Z]*'"></textarea>
                        </div>
                        <br>
                    </div>
                    <button [disabled]="submitDisabled" class="button" type="submit">Submit Form</button>
                </form>
                <br>
                <!-- <button *ngIf="editingEnabled" (click)="editing = !editing">
                    View Entries
                </button> -->
            </div>
        </div>
        <div *ngIf="editing" class="form-grid" cdkDropList (cdkDropListDropped)="drop($event)">
            <!-- Form Columns -->
            <!-- <div class="container-fluid form-row">
                <div class="col-0"></div>
                <div class="col-2">Field Name</div>
                <div class="col-3">Field Type</div>
                <div class="col-3">Description</div>
                <div class="col-1">Options</div>
                <div class="col-1">Required</div>
                <div class="col-1">Warning</div>
            </div> -->
    
            <!-- Entry Columns -->
            <div class="container-fluid form-row">
                <!-- 12 -->
                <div class="col-0"></div>
                <div class="col-1">Customer</div>
                <div class="col-1">Description</div>
                <div class="col-0">Asset Type</div>
                <div class="col-0">Date</div>
                <div class="col-1">Job Code</div>
                <div class="col-0">Contact</div>
                <div class="col-0"># Assets</div>
                <div class="col-0">Asset Data</div>
                <div class="col-0">Contact Name</div>
                <div class="col-0">Contact Email</div>
                <div class="col-0">Contact Phone Number</div>
                <div class="col-0">Contract</div>
                <div class="col-0">RFP</div>
                <div class="col-0">Notes</div>
            </div>
    
            <!-- Form Rows -->
            <!-- <div class="container-fluid form-row" [ngClass]="{'required-row': row.required, 'dev-row': row.warning}" *ngFor="let row of sampleObject.rows" cdkDrag>
                <div class="col-0" cdkDragHandle>
                    <i class="fa fa-bars"></i>
                </div>
                <div class="col-2" [attr.data-field]="'fieldName'" [attr.data-index]="row.index" contenteditable="false" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.fieldName}}                  
                </div>
                <div class="col-2">
                    <select (change)="setData($event)" [attr.data-field]="'fieldType'" [attr.data-index]="row.index">
                        <option value="textSingle" [selected]="row.fieldType == 'textSingle'">Text Single</option>
                        <option value="textMulti" [selected]="row.fieldType == 'textMulti'">Text Multi</option>
                        <option value="radioButtons" [selected]="row.fieldType == 'radioButtons'">Radio Buttons</option>
                        <option value="datePicker" [selected]="row.fieldType == 'datePicker'">Date Picker</option>
                        <option value="fileUpload" [selected]="row.fieldType == 'fileUpload'">File Upload</option>
                    </select>
                </div>
                <div class="col-3" [attr.data-field]="'description'" [attr.data-index]="row.index" contenteditable="false" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.description}}
                </div>
                <div class="col-1" [attr.data-index]="row.index" contenteditable="false" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.options}}              
                </div>
                <div class="col-1" [attr.data-index]="row.index">
                    <input type="checkbox" [attr.data-index]="row.index" [attr.data-field]="'required'" [checked]="row.required" (click)="setData($event)" />
                </div>
                <div class="col-1" [attr.data-index]="row.index">
                    <input type="checkbox" [attr.data-index]="row.index" [attr.data-field]="'warning'" [checked]="row.warning" (click)="setData($event)" />
                </div>
            </div> -->
    
            <!-- Entry Rows -->
            <div class="container-fluid form-row" [ngClass]="setRowClass(row)" *ngFor="let row of sampleObject.entries" cdkDrag>
                <div class="col-0" cdkDragHandle>
                    <i class="fa fa-bars"></i>
                </div>
                <!-- <div class="col-1">
                    {{row.index}}                
                </div> -->
                <div class="col-1" [attr.data-field]="'customer'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.customer}}                  
                </div>
                <div class="col-1" [attr.data-field]="'description'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.description}}
                </div>
                <div class="col-0" [attr.data-field]="'assetType'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.assetType}}      
                    <span *ngIf='row.assetType == ""'>STILL NEEDED</span>        
                </div>
                <div class="col-0" [attr.data-field]="'estimatedDate'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{formatDate(row.estimatedDate)}}  
                    <span *ngIf='row.estimatedDate == ""'>STILL NEEDED</span>              
                </div>
                <div class="col-1" [attr.data-field]="'jobCode'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.jobCode}} 
                    <span *ngIf='row.jobCode == ""'>STILL NEEDED</span>               
                </div>
                <div class="col-0" [attr.data-field]="'contactedIT'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.contactedIT}}  
                    <span *ngIf='row.contactedIT == null'>STILL NEEDED</span>              
                </div>
                <div class="col-0" [attr.data-field]="'assetNumber'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.assetNumber}}              
                </div>
                <div class="col-0" [attr.data-field]="'contactedIT'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    <a *ngIf="row.assetBool == true" target="_blank" href="{{row.assetData}}">File</a>
                    <span *ngIf='row.assetBool == false'>STILL NEEDED</span>           
                </div>
                <div class="col-0" [attr.data-field]="'contactName'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.contactName}}              
                </div>
                <div class="col-0" [attr.data-field]="'contactEmail'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.contactEmail}}              
                </div>
                <div class="col-0" [attr.data-field]="'contactNumber'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.contactNumber}}              
                </div>
                <div class="col-0" [attr.data-field]="'contract'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.contract}}  
                    <span *ngIf='row.contractBool == false'>STILL NEEDED</span>             
                </div>
                <div class="col-0" [attr.data-field]="'rfp'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.rfp}}      
                    <span *ngIf='row.rfpBool == false'>STILL NEEDED</span>          
                </div>
                <div class="col-0" [attr.data-field]="'notes'" [attr.data-id]="row.id" contenteditable="false" (click)="showEditPanel($event)" (focus)="editData($event)" (blur)="setData($event)">
                    {{row.notes}}              
                </div>
                <!-- <div class="col-1" [attr.data-index]="row.index">
                    <input type="checkbox" [attr.data-index]="row.index" [attr.data-field]="'required'" [checked]="row.required" (click)="setData($event)" />
                </div>
                <div class="col-1" [attr.data-index]="row.index">
                    <input type="checkbox" [attr.data-index]="row.index" [attr.data-field]="'warning'" [checked]="row.warning" (click)="setData($event)" />
                </div> -->
            </div>
        </div>
        <div *ngIf="editPanel" class="edit-panel-container">
            <div class="edit-header">
                Project Request {{currentEntry.id}}
                <div class="button-container">
                    <button class="button" form="entryEdit" type="submit">Save</button>
                    <button class="button" (click)="deleteEntry()">Delete</button>
                    <button class="button" (click)="editPanel = false;">X</button>
                </div>
            </div>
            <div class="edit-panel">
                <form id="entryEdit" [formGroup]="entryEdit" (ngSubmit)="onSubmitEdit(entryEdit.value)">
                    <input type="hidden" id="index" formControlName="index" [(ngModel)]="currentEntry.index"/>
                    <div>
                        <label>Customer<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Name of the Utility or Company</p>
                        <div>
                            <input id="customer" formControlName="customer" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.customer"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Product Description</label>
                        <p>Description of the services (i.e. GLT, URD, OH, Engineering, etc.) offered and what IT will need to facilitate.</p>
                        <div>
                            <textarea id="description" formControlName="description" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.description"></textarea>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Asset Type<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Poles, Meters, Safety, etc</p>
                        <div>
                            <input id="assetType" formControlName="assetType" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.assetType"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Estimated Execution Date<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>The date you expect to begin in the field</p>
                        <div>
                            <input id="estimatedDate" formControlName="estimatedDate" type="date" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.estimatedDate"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Job Code<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Job code IT can bill for any work done on this project</p>
                        <div>
                            <input id="jobCode" formControlName="jobCode" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.jobCode"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Have you contacted IT directly?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>You must contact IT by phone or email regardless of filling out this form</p>
                        <div>
                            <input type="radio" [(ngModel)]="currentEntry.contactedIT" name="contactedIT" formControlName="contactedIT" [value]="true"/><label>Yes</label><br>
                            <input type="radio" [(ngModel)]="currentEntry.contactedIT" name="contactedIT" formControlName="contactedIT" [value]="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Estimated Number of Assets<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>A count of the anticpated number of assets that the project will have or will need to be loaded into the system for collection.</p>
                        <div>
                            <input id="assetNumber" formControlName="assetNumber" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.assetNumber"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Do you have any asset data?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Any shape, csv, or xls files  (i.e. data to be loaded)</p>
                        <div>
                            <input type="radio" [(ngModel)]="currentEntry.assetBool" name="assetBool" formControlName="assetBool" [value]="true"/><label>Yes</label><br>
                            <input type="radio" [(ngModel)]="currentEntry.assetBool" name="assetBool" formControlName="assetBool" [value]="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="entryEdit.value.assetBool == true">
                        <label>Asset Data Upload<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="file" [(ngModel)]="currentEntry.assetData" id="assetData" name="assetData" formControlName="assetData">
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Utility IT/PM Contact Name</label>
                        <p>Contact that IT can reach out to for more details</p>
                        <div>
                            <input id="contactName" formControlName="contactName" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.contactName"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Utility IT/PM Contact Email</label>
                        <p>Contacts Email</p>
                        <div>
                            <input id="contactEmail" formControlName="contactEmail" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.contactEmail"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Utility IT/PM Contact Phone Number</label>
                        <p>Contacts Phone Number</p>
                        <div>
                            <input id="contactNumber" formControlName="contactNumber" type="text" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.contactNumber"/>
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Is there a contract?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p>Contract for work to be performed</p>
                        <div>
                            <input type="radio" [(ngModel)]="currentEntry.contractBool" name="contractBool" formControlName="contractBool" [value]="true"/><label>Yes</label><br>
                            <input type="radio" [(ngModel)]="currentEntry.contractBool" name="contractBool" formControlName="contractBool" [value]="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="entryEdit.value.contractBool">
                        <label>Contract Upload<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="file" [(ngModel)]="currentEntry.contract" id="contract" name="contract" formControlName="contract">
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Is there an RFP?<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="radio" [(ngModel)]="currentEntry.rfpBool" name="rfpBool" formControlName="rfpBool" [value]="true"/><label>Yes</label><br>
                            <input type="radio" [(ngModel)]="currentEntry.rfpBool" name="rfpBool" formControlName="rfpBool" [value]="false"/><label>No</label><br>
                        </div>
                        <br>
                    </div>
                    <div *ngIf="entryEdit.value.rfpBool">
                        <label>RFP Upload<span style="color: red; font-weight: bold;"> *</span></label>
                        <p></p>
                        <div>
                            <input type="file" [(ngModel)]="currentEntry.rfp" id="rfp" name="rfp" formControlName="rfp">
                        </div>
                        <br>
                    </div>
                    <div>
                        <label>Notes</label>
                        <p></p>
                        <div>
                            <textarea id="notes" formControlName="notes" pattern="[a-zA-Z]*'" [(ngModel)]="currentEntry.notes"></textarea>
                        </div>
                        <br>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
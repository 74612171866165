import { Environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NewUserService {
    //private localUtilimeterUrl: string = "http://localhost:64655";
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = Environment.serverPath;
        this.quaditUrl = Environment.quaditPath;
        this.utmeterUrl = Environment.utmeterPath;
        this.localUrl = "http://localhost:63000";
        this.localQauditUrl = "http://localhost:62029";
        this.setStagingLinks = (sites, admin, user) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/stagingsecondstep', {
                SitesSelected: sites,
                AdminUserName: admin,
                UserName: user
            }, { headers: NewUserService.authHeaders });
        };
        this.setStagingDetails = (user, siteDetailsSelected) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/stagingthirdstep', {
                UserName: user,
                SitesDetailsSelected: siteDetailsSelected
            }, { headers: NewUserService.authHeaders });
        };
        this.setMarketStagingDetails = (user, siteDetailsSelected) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/stagingfourthstep', {
                UserName: user,
                SitesDetailsSelected: siteDetailsSelected
            }, { headers: NewUserService.authHeaders });
        };
        this.finalize = (user, edit) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/finalize', {
                UserName: user,
                SubUserName: user,
                IsEdit: edit
            }, { headers: NewUserService.authHeaders });
        };
        this.cancelUser = (user) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/deletestaging', {
                UserName: user
            }, { headers: NewUserService.authHeaders });
        };
        this.uploadAvatar = (avatar, user) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/uploadAvatar', {
                UserName: user
            }, { headers: NewUserService.fileHeader });
        };
        this.GetCurrentUserInfo = (userName, isWebAdmin) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/currentuserinfo', {
                UserName: userName,
                subUserName: userName,
            }, { headers: NewUserService.authHeaders });
        };
    }
    SetStagingUser(stagingUser) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/stagingfirststep', {
            Company: stagingUser.company,
            ConfirmPassword: stagingUser.confirmPassword !== null ? stagingUser.confirmPassword : null,
            Email: stagingUser.email,
            FirstName: stagingUser.firstname,
            LastName: stagingUser.lastname,
            Password: stagingUser.password !== null ? stagingUser.password : null,
            Portalrole: stagingUser.role,
            UserName: stagingUser.username,
            Id: stagingUser.username
        }, { headers: NewUserService.authHeaders });
    }
    // Test Function
    GetMarkets(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/subordinatemarkets', {
                    userName: userName
                }, { headers: NewUserService.authHeaders });
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/mktcontrol/usermarkets', {
                    UserName: userName
                }, { headers: NewUserService.authHeaders });
                break;
            default:
                let observable = Observable.create(observer => {
                    setTimeout(() => {
                        let users = "No roles for the selected sites";
                        observer.next(users); // This method same as resolve() method from Angular 1
                        observer.complete(); //to show we are done with our processing
                        // observer.error(new Error("error message"));
                    });
                });
                return observable;
        }
    }
    GetRoles(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/utiliRole/gridroles', {
                    UserName: userName
                });
                break;
            default:
                //let observable = of('test');
                let observable = Observable.create(observer => {
                    setTimeout(() => {
                        let users = "No roles for the selected sites";
                        observer.next(users); // This method same as resolve() method from Angular 1
                        observer.complete(); //to show we are done with our processing
                        // observer.error(new Error("error message"));
                    });
                });
                return observable;
        }
    }
    GetUsers(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
        }
    }
    CheckUserName(userName) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/checkusername', {
            UserName: userName
        });
    }
    CheckEmail(email) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/checkemail', {
            email: email
        });
    }
    GetAllLinks() {
        return this.httpClient.get(this.baseUrl + '/api/utililink/getalllinks');
    }
    GetGridUsers(userName) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/gridusers', {
            UserName: userName,
        }, { headers: NewUserService.authHeaders });
    }
    /***** Parsing Methods *****/
    ParseImageUrl(url) {
        if (url == undefined)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        if (url.image == null)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        return 'data:image/JPEG;base64,' + url.image;
    }
    ParseUserDetailImageUrl(userImage) {
        if (userImage == undefined || userImage == null)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        return 'data:image/JPEG;base64,' + userImage;
    }
}
NewUserService.authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
});
NewUserService.fileHeader = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'multipart/form-data'
});
NewUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewUserService_Factory() { return new NewUserService(i0.ɵɵinject(i1.HttpClient)); }, token: NewUserService, providedIn: "root" });

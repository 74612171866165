import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDownloadComponent } from './filedownload.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FileDownloadComponent]
})
export class FileDownloadModule { }

import { AdminSelect } from 'src/models/interfaces/admin.select';
import { UIFilter } from 'src/models/classes/UIFilter';
import { Environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAssign } from 'src/models/interfaces/user.assign';

@Injectable({
  providedIn: 'root'
})

export class ProjectRequestService {

  static readonly authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
  });

  private baseUrl: string = Environment.serverPath;
  private quaditUrl: string = Environment.quaditPath;
  //private quaditUrl: string = "http://localhost:62029/";
  private utmeterUrl: string = Environment.utmeterPath;
  public sampleObject: any = {
    rows: [
        {
            index: 1,
            fieldName: 'Customer',
            description: 'Name of the Utility or Company',
            fieldType: 'textSingle',
            options: null,
            required: true,
            warning: true,
            numbersAllowed: true,
            maxLength: 50
        },
        {
            index: 2,
            fieldName: 'Project Description',
            description: 'Description of the services (i.e. GLT, URD, OH, Engineering, etc.) offered and what IT will need to facilitate',
            fieldType: 'textMulti',
            options: null,
            required: false,
            warning: false,
            numbersAllowed: false,
            maxLength: 250
        },
        {
            index: 3,
            fieldName: 'Have you contacted IT directly?',
            description: 'You must contact IT by phone or email regardless of filling out this form.',
            fieldType: 'radioButtons',
            options: [
                'Yes',
                'No'
            ],
            required: true,
            warning: true,
            numbersAllowed: true,
            maxLength: 50
        },
    ]
  };
  public sampleRecord:any = {
    entries: []
  };

  //private baseUrl: string = "http://localhost:63000";
  //private localQauditUrl: string = "http://localhost:62029";
  //private localUtilimeterUrl: string = "http://localhost:64655";

  constructor(private httpClient: HttpClient) { }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  GetRows = () => {
    return this.sampleRecord;
  };

  SetRow = (index, property, data) => {
    this.sampleRecord.entries[index][property] = data;
  };

  AddRow = (data) => {
    this.sampleRecord.entries.push(data);
  };

  MoveRow = (newObject) => {
    this.sampleRecord = newObject;
  };

  GetRequests = () => {
    return this.httpClient.post<any>(this.quaditUrl + '/api/req/getRequests', 
    {
      UserName: ''
    });
  };

  UpdateRequest = (data) => {
    return this.httpClient.post<any>(this.quaditUrl + '/api/req/updateRequest',
    {
      PoleId: null,
      PhaseId: null,
      DetailType: null,
      ReportId: null,
      ChartId: null,
      Delete: null,
      OptionalLabel: null,
      AuxiliaryData: data
    });
  };

  ReorderRequest = (data) => {
    return this.httpClient.post<any>(this.quaditUrl + '/api/req/reorderRequest',
    {
      PoleId: null,
      PhaseId: null,
      DetailType: null,
      ReportId: null,
      ChartId: null,
      Delete: null,
      OptionalLabel: null,
      AuxiliaryData: data
    });
  };

  SubmitRequest = (data) => {
    return this.httpClient.post<any>(this.quaditUrl + '/api/req/submitRequest',
    {
      PoleId: null,
      PhaseId: null,
      DetailType: null,
      ReportId: null,
      ChartId: null,
      Delete: null,
      OptionalLabel: null,
      AuxiliaryData: data
    });
  };

  DeleteRequest = (data) => {
    return this.httpClient.post<any>(this.quaditUrl + '/api/req/deleteRequest',
    {
      PoleId: null,
      PhaseId: null,
      DetailType: null,
      ReportId: null,
      ChartId: null,
      Delete: null,
      OptionalLabel: null,
      AuxiliaryData: data
    });
  };

  FileUpload = (data) => {
    return this.httpClient.post<any>(this.quaditUrl + "/api/req/uploadRequestFile",
    {
      PoleId: null,
      PhaseId: null,
      DetailType: null,
      ReportId: null,
      ChartId: null,
      Delete: null,
      OptionalLabel: null,
      AuxiliaryData: data
    })
  } 

  // Test Function
  GetMarkets(site:string, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.get<any>(this.quaditUrl + '/api/qm/markets');
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/mktcontrol/usermarkets',
        {
          UserName: userName
        });
        break;
    }
    
  }

  GetRoles(site:String, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/utiliRole/gridroles',
        {
        	UserName : userName
        });
        break;
    }
  }

  GetUsers(site:string, userName:String) {
    switch(site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
    }
  }
}
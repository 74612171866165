<div class="d-flex flex-column h-100">
    <div class="topNav" *ngIf="showDashboard">
        <div class="w-100 p-0">
            <top-nav></top-nav>
        </div>
    </div>
    <div class="d-flex flex-row routerOutlet">
        <div class="d-flex flex-column col-2 p-2 h-100 align-items-center sideNav" *ngIf="showDashboard">
            <side-nav></side-nav>
        </div>
        <div class="d-flex flex-column p-2 h-100 w-100">
            <div class="overflowAuto h-100">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
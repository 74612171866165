/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./topnav/topnav.component.ngfactory";
import * as i3 from "./topnav/topnav.component";
import * as i4 from "../app.service";
import * as i5 from "@angular/common/http";
import * as i6 from "@angular/router";
import * as i7 from "../common/toastr.service";
import * as i8 from "./sidenav/sidenav.component.ngfactory";
import * as i9 from "./sidenav/sidenav.component";
import * as i10 from "../newuser/newuser.service";
import * as i11 from "@angular/common";
import * as i12 from "./dashboard.component";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "topNav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "w-100 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "top-nav", [], null, null, null, i2.View_TopnavComponent_0, i2.RenderType_TopnavComponent)), i1.ɵdid(3, 114688, null, 0, i3.TopnavComponent, [i4.AppService, i5.HttpClient, i6.Router, i7.Toastr], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex flex-column col-2 p-2 h-100 align-items-center sideNav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "side-nav", [], null, null, null, i8.View_SidenavComponent_0, i8.RenderType_SidenavComponent)), i1.ɵdid(2, 114688, null, 0, i9.SidenavComponent, [i6.Router, i4.AppService, i7.Toastr, i10.NewUserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "d-flex flex-column h-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "d-flex flex-row routerOutlet"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "d-flex flex-column p-2 h-100 w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "overflowAuto h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDashboard; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showDashboard; _ck(_v, 5, 0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i12.DashboardComponent, [i4.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("dashboard", i12.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };

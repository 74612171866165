import { AppService } from './app.service';
import { Component, OnInit } from '@angular/core';
import { ConsoleLogger } from './common/console.log.service';
import { LoginService } from './login/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Toastr } from './common/toastr.service';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public gtma: string;
  public kmli: string;

  constructor(
    private appSrv: AppService,
    private consoleLogger: ConsoleLogger,
    private loadSpinner: NgxSpinnerService,
    private loginService: LoginService,
    private router: Router,
    private toastr: Toastr) { }

  ngOnInit() {
    this.appSrv.ShowSpinnerObservable()
    .subscribe(show => {

        if (show) {
          this.loadSpinner.show();
        } else {
          this.loadSpinner.hide();
        }
    });
    this.AutoLogin();
  }

  /***** Root Application Methods *****/

  AutoLogin() {
    this.gtma = this.ValueForQueryStringKey(this.appSrv.AppSettingsGet().queryStringReturnAccount);
    this.kmli = this.ValueForQueryStringKey(this.appSrv.AppSettingsGet().queryStringReturn);

    if (!this.gtma && !this.kmli) {
      this.appSrv.FileDownloadSet(this.ValueForQueryStringKey(this.appSrv.AppSettingsGet().queryStringDownload));
      this.appSrv.ClearUserProfile();
    } else {
      let upls: UserProfile = this.appSrv.GetUserProfileLocalStorage(this.gtma || this.kmli);
      if (upls) {
        this.appSrv.SetUserProfileFromObject(upls);
        this.appSrv.ShowSpinnerSet(true);
        this.loginService.GenerateSeedApi(upls.userName).subscribe( 
          success => {
            this.appSrv.UrlVariableSet(success.urlVariable);
            this.appSrv.SetUserProfile(upls.jwt, upls.qkey, upls.userName);
            console.log(success.urlVariable);
            this.loginService.SaveJsonWebToken(upls.jwt, decodeURIComponent(success.urlVariable.split("&")[2].split("=")[1])).subscribe(
              () => {
                this.loginService.FetchUserAvatar(upls.userName).subscribe( 
                  success => {
                    this.appSrv.AvatarImageSet(success.image);
                    this.loginService.FetchUserName(upls.userName).subscribe( 
                      success => {
                        var autoGoTo = this.kmli ? 'linkmenu' : 'myaccount';

                        this.appSrv.FullNameSet(success.userName);
                        this.appSrv.SetCurrentState(autoGoTo);
                        this.appSrv.ShowDashboardSet(true);
                        this.appSrv.ShowSpinnerSet(false);
                        this.router.navigate([autoGoTo]);
                        this.appSrv.SetUserProfileFromObject(upls);
                      },
                      failure => {
                        this.appSrv.FullNameSet(upls.userName);
                        this.appSrv.ShowSpinnerSet(false);
                        this.consoleLogger.LogStatusToConsole(failure, 'Fetch User Name Failed.');
                      });
                  },
                  failure => {
                    this.appSrv.AvatarImageSet(null);
                    this.appSrv.ShowSpinnerSet(false);
                    this.consoleLogger.LogStatusToConsole(failure, 'Fetch User Avatar Failed.');
                  });
              },
              failure => {
                this.appSrv.AvatarImageSet(null);
                this.appSrv.ClearUserProfile();
                this.appSrv.FullNameSet(null);
                this.appSrv.ShowSpinnerSet(false);
                this.toastr.ShowErrorResponse(failure);
              });            
          },
          failure => {
            this.appSrv.ShowSpinnerSet(false);
            this.toastr.ShowWarning('Unable to login, please try again. If this error continues, please contact the support desk.');  
          });
      } else {
        this.toastr.ShowWarning('Please log back into your account to continue.');
      }
    }
  }

  ValueForQueryStringKey(pKey: string): string {
    let value: string = null;

    window.location.search.replace('?', '').split('&').forEach( kvp => {
      let val = kvp.split('=');

      if (val.includes(pKey) && val.length > 1) {
        value = val[1];
      }     
    });

    return value;
  }

  GetYear = () => new Date().getFullYear();

}
import { Environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UseraccountService {
    constructor(http) {
        this.http = http;
        this.GetAccount = (userName) => this.http.post(Environment.serverPath + "/api/accounts/myaccount", { userName: userName });
        this.DeleteAvatar = (userName) => this.http.post(Environment.serverPath + "/api/accounts/deleteavatar", { userName: userName });
        this.UpdateAccount = (form) => this.http.post(Environment.serverPath + "/api/accounts/updateMyAccount", form);
        this.LockAccounts = (lockList) => this.http.post(Environment.serverPath + "/api/accounts/batchlock", { LockList: lockList });
        this.UnlockAccounts = (lockList) => this.http.post(Environment.serverPath + "/api/accounts/batchunlock", { LockList: lockList });
    }
}
UseraccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UseraccountService_Factory() { return new UseraccountService(i0.ɵɵinject(i1.HttpClient)); }, token: UseraccountService, providedIn: "root" });

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./myaccount.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../useraccount/useraccount.component.ngfactory";
import * as i3 from "../useraccount/useraccount.component";
import * as i4 from "../useraccount/useraccount.service";
import * as i5 from "../app.service";
import * as i6 from "@angular/router";
import * as i7 from "../common/toastr.service";
import * as i8 from "ngx-spinner";
import * as i9 from "./myaccount.component";
var styles_MyAccountComponent = [i0.styles];
var RenderType_MyAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyAccountComponent, data: {} });
export { RenderType_MyAccountComponent as RenderType_MyAccountComponent };
export function View_MyAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-account-form", [], null, null, null, i2.View_UseraccountComponent_0, i2.RenderType_UseraccountComponent)), i1.ɵdid(1, 638976, null, 0, i3.UseraccountComponent, [i4.UseraccountService, i5.AppService, i6.Router, i7.Toastr, i8.NgxSpinnerService], { userName: [0, "userName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userName; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MyAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "my-account", [], null, null, null, View_MyAccountComponent_0, RenderType_MyAccountComponent)), i1.ɵdid(1, 245760, null, 0, i9.MyAccountComponent, [i5.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyAccountComponentNgFactory = i1.ɵccf("my-account", i9.MyAccountComponent, View_MyAccountComponent_Host_0, {}, {}, []);
export { MyAccountComponentNgFactory as MyAccountComponentNgFactory };

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsoleLogger {

  private consoleLogText: string = '';

  constructor() { }

  LogStatusToConsole(response: any, statusTitle: string) {
    this.consoleLogText = statusTitle;

    if (response.status) this.consoleLogText += ' Status: ' + response.status;    
    if (response.statusText) this.consoleLogText += '- Status Text: ' + response.statusText;

    console.log(this.consoleLogText);
  }
}
import { AppService } from 'src/app/app.service';
import { HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class Toastr {

    private errorText: string = '';

    constructor(private appSrv: AppService, private toastrService: ToastrService) { }

    ParseErrorText(response: any): string {

        if (response.error && response.error.error_description) {
            this.errorText = response.error.error_description;
        } else {

            if (response.statusText) this.errorText = response.statusText;
            if (response.status) this.errorText += (this.errorText.length > 0 ? '<br/>' : '') + 'Status: ' + response.status;    
              
            this.errorText += '<br/>Check browser console for details.';
        }

        return this.errorText;
    }

    ParseErrorTitle(response: any): string {
        
        if (response.error && response.error.error &&
            (response.error.error == this.appSrv.AppSettingsGet().authError || response.error.error == this.appSrv.AppSettingsGet().reCaptchaError)) return 'User Login Failed';

        return 'Internet Response Failed';
    }

    ShowError(text: string, title: string = null) {
        this.toastrService.error(text, title || 'Error!');
    }

    ShowErrorResponse(failure: any) {

        if (failure instanceof HttpErrorResponse) {

            try {                            
                this.toastrService.error(this.ParseErrorText(failure), this.ParseErrorTitle(failure));
                console.log('HttpErrorResponse Message: ', failure.message);
            } catch (ex) {
                this.toastrService.error('Toastr failed to toast.', 'Toastr Failed');
                console.log('Toastr Error. Ex: ', ex.name)
            }
        }
    }

    ShowSuccess(text: string, title: string = null) {
        this.toastrService.success(text, title);
    }

    ShowWarning(text: string, title: string = null) {
        this.toastrService.warning(text, title || 'Attention!');
    }

    Clear = () => {
        this.toastrService.clear();
    }
}
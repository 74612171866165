import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AdminListComponent } from './adminlist.component';
import { DragDropModule  } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    DragDropModule,
    NgbModule
  ],
  declarations: [
    AdminListComponent
  ],
  exports: [
    AdminListComponent
  ]
})

export class AdminListModule { }
import { AppService } from '../../app.service';
import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Toastr } from '../../common/toastr.service';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'top-nav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  public avatarImage: any;
  public environmentUrl: string;
  public isLoggedIn: boolean;
  public showSupport: boolean;
  public userProfile: UserProfile;
  public fullName: string;

  constructor(
    private appSrv: AppService,
    private httpClient: HttpClient,
    private router: Router,
    private toastr: Toastr) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.showSupport = false;
    this.fullName = this.appSrv.FullNameGet();
    this.appSrv.AvatarImageObservable()
    .subscribe((aio: any) => this.avatarImage = aio);
    this.appSrv.GetUserProfileObservable()
    .subscribe(upo => {
      this.userProfile = upo
    });
  }

  /***** Events *****/

  onAccActClick(goRoute: string) {
    this.router.navigate([goRoute]);
  }

  onCloseClick() {
    this.showSupport = false;
  }

  onLogoutClick() {
    this.httpClient.post<any>(Environment.serverPath + '/api/jwt/deletejwt',
    {
      jwtString: this.userProfile.jwt,
      queryKey: this.userProfile.qkey
    },
    { headers: new HttpHeaders({
      'Anonymous': 'false',
      'Content-Type': 'application/json'
      })
    }).subscribe( 
      success => {
        console.log(success);
        this.appSrv.ShowDashboardSet(false);
        this.toastr.ShowSuccess('Logged out successfully.');
      },
      failure => {
        console.log(failure);
        this.appSrv.ShowDashboardSet(false);
        this.toastr.ShowWarning('Logged out of Utiliportal.');
      }
    );
    console.log('logged out');
    this.appSrv.ClearUserProfile();
    this.appSrv.FullNameSet(null);
    this.avatarImage = null;
    this.router.navigate(['login']);
  }

  onSupportClick() {
    this.showSupport = !this.showSupport;
  }
}
import * as i0 from "@angular/core";
export class ConsoleLogger {
    constructor() {
        this.consoleLogText = '';
    }
    LogStatusToConsole(response, statusTitle) {
        this.consoleLogText = statusTitle;
        if (response.status)
            this.consoleLogText += ' Status: ' + response.status;
        if (response.statusText)
            this.consoleLogText += '- Status Text: ' + response.statusText;
        console.log(this.consoleLogText);
    }
}
ConsoleLogger.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsoleLogger_Factory() { return new ConsoleLogger(); }, token: ConsoleLogger, providedIn: "root" });

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkMenuComponent } from './linkmenu.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    LinkMenuComponent
  ],
  exports:[
    LinkMenuComponent
  ]
})

export class LinkMenuModule { }

import { AppService } from '../app.service';
import { Component, OnInit } from '@angular/core';
import { Environment } from '../../environments/environment';
import { filterBy, process, State } from '@progress/kendo-data-query';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NewUserService } from '../newuser/newuser.service';
import { Router } from '@angular/router';
import { Toastr } from '../common/toastr.service';
import { UserManagementService } from '../usermanagement/usermanagement.service';
import { UserProfile } from 'src/models/interfaces/user.profile';
import {
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";

@Component({
  selector: "manage-users",
  templateUrl: "./manageusers.component.html",
  styleUrls: ["./manageusers.component.scss"],
})
export class ManageUsersComponent implements OnInit {
  static readonly anonHeaders = new HttpHeaders({
    Anonymous: "true",
    "Content-Type": "application/json",
  });

  private baseUrl: string = Environment.serverPath;
  locations: Array<String> = [];
  public isWebAdmin: boolean = false;
  public userProfile: UserProfile;
  public pageSize = 10;
  public skip = 0;
  public gridView: GridDataResult;
  public dialogStatus: boolean = false;
  public dialogUserName: string;
  public deleteDialogStatus: boolean = false;
  public filter: any = {};
  public state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  selectedUsers: any = [];
  users: any;
  userDetails: any = {
    username: "",
    email: "",
    firstname: "",
    lastname: "",
    company: "",
    role: "",
    password: null,
    confirmPassword: null,
  };
  public userName: string;
  public userRole: string;
  columns: any[] = [
    {
      field: "email",
      title: "User Email",
      filterable: {
        multi: true,
        search: true,
      },
    },
    {
      field: "userName",
      title: "User Name",
    },
    {
      field: "firstName",
      title: "First Name",
    },
    {
      field: "lastName",
      title: "Last Name",
    },
    {
      field: "portalRole",
      title: "Role Name",
    },
    {
      field: "company",
      title: "Company",
    },
    {
      field: "lockedOut",
      title: "Locked Status",
    },
    {
      field: "failedAccessCount",
      title: "# of Failed Logins",
    },
    {
      field: "isPending",
      title: "Pending",
    },
  ];

  constructor(
    private router: Router,
    private appSrv: AppService,
    private httpClient: HttpClient,
    private userService: NewUserService,
    private toastr: Toastr,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit() {
    this.appSrv
      .GetUserProfileObservable()
      .subscribe((upo) => (this.userProfile = upo));
    this.getGridUsers();
    this.getCurrentUserInfo();
  }

  getCurrentUserInfo = () => {
    var name;
    this.userName !== undefined
      ? (name = this.userName)
      : (name = this.userProfile.userName);
    this.userService.GetCurrentUserInfo(name, false).subscribe(
      (success) => {
        success.step1.portalRole === "WebAdmin"
          ? (this.isWebAdmin = true)
          : null;
      },
      (failure) => {
        this.toastr.ShowError(failure);
        console.log(failure);
      }
    );
  };

  getGridUsers() {
    this.userManagementService
      .GetGridUsers(this.userProfile.userName)
      .subscribe(
        (success) => {
          this.users = success;
          this.calculateRows();
        },
        (failure) => {
          console.log(failure);
        }
      );
  }

  filterByValue = (event) => {
    var filteredArray = this.users.filter((user) =>
      user.userName.includes(event.target.value)
    );
  };

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
  }

  loadItems = () => {
    this.gridView = {
      data: filterBy(this.users, this.state.filter).slice(
        this.skip,
        this.skip + this.pageSize
      ),
      total: filterBy(this.users, this.state.filter).length,
    };
  };

  getFilter = (columnName) => {
    if (columnName == "lockedOut" || columnName == "isPending") {
      return "boolean";
    } else {
      //return "string";
    }
  };

  getFilterLabels = (columnName, bool) => {
    if (columnName === "lockedOut") {
      return bool ? "Locked" : "Unlocked";
    } else {
      return bool ? "True" : "False";
    }
  };

  cellClickHandler = (event) => {
    this.userName = event.dataItem.userName;
    this.userRole = event.dataItem.portalRole;
    this.dialogStatus = true;
  };

  select = (event) => {
    if (event.deselectedRows.length > 0) {
      event.deselectedRows.forEach((row, index) => {
        var rowIndex = this.selectedUsers.findIndex(
          (user) => user.dataItem.id === row.dataItem.id
        );
        if (
          event.deselectedRows.length >= 1 &&
          this.selectedUsers.length > 1 &&
          rowIndex !== undefined &&
          rowIndex > -1
        ) {
          if (this.selectedUsers.length === 1) {
            this.selectedUsers = [];
          } else {
            rowIndex !== undefined
              ? this.selectedUsers.splice(rowIndex, 1)
              : null;
          }
        } else {
          this.selectedUsers = [];
        }
      });
    }
    if (!event.ctrlKey) {
      this.selectedUsers = event.selectedRows;
      var user = event.selectedRows[0].dataItem;
    } else {
      event.selectedRows[0] !== undefined
        ? this.selectedUsers.push(event.selectedRows[0])
        : null;
    }
  };

  lockUsers = () => {
    var userList = [];
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((user) => {
        userList.push(user.dataItem.userName);
      });
      this.userManagementService.LockUsers(userList).subscribe(
        (success) => {
          this.toastr.ShowSuccess("Account(s) Locked");
          this.selectedUsers = [];
          this.getGridUsers();
        },
        (failure) => {
          console.log(failure);
        }
      );
    } else {
      this.toastr.ShowWarning("No account(s) selected");
    }
  };

  unlockUsers = () => {
    var userList = [];
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((user) => {
        userList.push(user.dataItem.userName);
      });
      this.userManagementService.UnlockUsers(userList).subscribe(
        (success) => {
          this.toastr.ShowSuccess("Account(s) Unlocked");
          this.selectedUsers = [];
          this.getGridUsers();
        },
        (failure) => {
          console.log(failure);
        }
      );
    } else {
      this.toastr.ShowWarning("No account(s) selected");
    }
  };

  resendInvite = () => {
    var userList = [];
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((user) => {
        userList.push(user.dataItem.userName);
      });
      this.userManagementService.ResendInvite(userList).subscribe(
        (success) => {
          this.toastr.ShowSuccess("Invite Resent");
        },
        (failure) => {
          console.log(failure);
        }
      );
    } else {
      this.toastr.ShowWarning("No user(s) selected");
    }
  };

  deleteUser = () => {
    var userList = [];
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((user) => {
        userList.push(user.dataItem.userName);
      });
      if (this.selectedUsers.length > 1) {
        this.toastr.ShowWarning("Only one user at a time may be deleted");
      } else {
        this.deleteDialogStatus = true;
      }
    } else {
      this.toastr.ShowWarning("No user(s) selected");
    }
  };

  confirmDelete = () => {
    this.userManagementService
      .DeleteUser(this.selectedUsers[0].dataItem.id)
      .subscribe(
        (success) => {
          this.toastr.ShowSuccess("Account(s) deleted");
          this.deleteDialogStatus = false;
          this.getGridUsers();
        },
        (failure) => {
          console.log(failure);
        }
      );
  };

  calculateRows = () => {
    var element = <HTMLElement>(
      document.getElementsByClassName("k-grid-content")[0]
    );
    var height = element.offsetHeight;
    this.pageSize = Math.floor(height / 36);
    this.loadItems();
  };

  dataStateChange(state: DataStateChangeEvent): void {
    if (state.filter == undefined || state.filter.filters.length > 0) {
      this.skip = 0;
    }
    this.state = state;
    this.gridView = process(this.users, state);
  }

  closeHandler = (event) => {
    this.dialogUserName = event;
  };

  finalizeHandler = (event) => {
    this.dialogStatus = false;
    this.getGridUsers();
  };

  checkSelectedValues = () => {
    console.log(this.selectedUsers);
  };

  onClose() {
    this.dialogStatus = false;
    //this.userName = undefined;
    this.userService.cancelUser(this.dialogUserName).subscribe((success) => {
      this.toastr.ShowSuccess(
        "Operation cancelled for user " + this.dialogUserName
      );
    });
  }
  onAccept() {
    this.dialogStatus = false;
  }
  onDecline() {
    this.dialogStatus = false;
  }

  displayAlert = (event) => {
    alert("Value Changed");
    console.log(event);
  };

  navigateSideNav(e) {
    var location = e.currentTarget.innerText.trim().toLowerCase();

    switch (location) {
      case "account":
        this.router.navigate(["myaccount"]);
        break;
      case "dashboard":
        this.router.navigate(["linkmenu"]);
        break;
    }
  }
}

import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Observable, pipe } from "rxjs";
import { IUserAccount } from "src/models/interfaces/IUSerAccount";
import { NgForm, FormControl } from "@angular/forms";
import { AppService } from "../app.service";
import { UseraccountService } from "./useraccount.service";
import { takeWhile } from "rxjs/internal/operators";
import { UserProfile } from "src/models/interfaces/user.profile";
import { Environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Toastr } from "../common/toastr.service";
import { SuccessEvent, FileRestrictions } from "@progress/kendo-angular-upload";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "user-account-form",
  templateUrl: "./useraccount.component.html",
  styleUrls: ["./useraccount.component.scss"],
})
export class UseraccountComponent implements OnInit {
  public userAccountObsersable$: Observable<IUserAccount>;
  @Input() userName: string;
  public hoverImage: boolean = false;
  private alive: boolean = true;
  public openEditUserDialog: boolean = false;
  public openChangeAvatarDialog: boolean = false;
  private localUrl: string = "http://localhost:63000";
  public uploadAvatartUrl: string = `${Environment.serverPath}//api/accounts/uploadAvatar`;
  //public uploadAvatartUrl: string = `${this.localUrl}/api/accounts/uploadAvatar`;
  public avatarRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png"],
  };
  public hasAvatar: boolean = false;

  constructor(
    private userAccountService: UseraccountService,
    private appService: AppService,
    private router: Router,
    private toastrService: Toastr,
    private loadSpinner: NgxSpinnerService
  ) {}

  ngOnChanges(): void {
    /* setTimeout(() => {
      this.GetAccount();
    }, 0) */
  }

  ngOnInit() {
    this.GetAccount();
    this.userAccountObsersable$.subscribe((response) => {
      console.log(response);
    });
  }

  GetAccount = () => {
    this.appService
      .GetUserProfileObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((profile: UserProfile) => {
        this.loadSpinner.show();
        this.userAccountObsersable$ = this.userAccountService.GetAccount(
          this.userName
        );
        setTimeout(() => {
          this.loadSpinner.hide();
        }, 500);
      });
  };

  LockAccount = () => {
    this.userAccountService
      .LockAccounts([this.userName])
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.toastrService.ShowSuccess("Account successfully locked.");
        this.openChangeAvatarDialog = false;
      }),
      (error) => {
        this.toastrService.ShowError(error.message);
        this.openChangeAvatarDialog = false;
      };
  };

  UnlockAccount = () => {
    this.userAccountService
      .UnlockAccounts([this.userName])
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.toastrService.ShowSuccess("Account successfully unlocked.");
        this.openChangeAvatarDialog = false;
      }),
      (error) => {
        this.toastrService.ShowError(error.message);
        this.openChangeAvatarDialog = false;
      };
  };

  GetEditNameImage = (): string =>
    `${Environment.assetsUrl}/assets/images/editnametitle.png`;

  //GetUserImage = (avatar: string): string => avatar === null ? `${Environment.assetsUrl}/assets/images/avatars/defaultavatar.png` : `data:image/JPEG;base64,${avatar}`;

  GetUserImage = (avatar: string) => {
    if (avatar === null) {
      //return `${Environment.assetsUrl}/assets/images/avatars/defaultavatar.png`;
      this.hasAvatar = false;
      return "";
    } else {
      this.hasAvatar = true;
      return `data:image/JPEG;base64,${avatar}`;
    }
  };

  GetAddAvatarImage = () =>
    `${Environment.assetsUrl}/assets/images/avatars/defaultavatar.png`;

  Cancel = () => this.router.navigate(["linkmenu"]);

  //ResetForm = (accountForm: NgForm): void => accountForm.form.reset();

  ResetForm = (accountForm: NgForm) => {
    this.userAccountObsersable$ = this.userAccountService.GetAccount(
      this.userName
    );
  };

  ChangeAvatar = () => (this.openChangeAvatarDialog = true);

  DeleteAvatar = (userName: string) => {
    this.userAccountService.DeleteAvatar(userName).subscribe(
      (success) => {
        this.toastrService.ShowSuccess("Avatar removed");
        this.appService.AvatarImageSet(null);
        console.log(success);
        this.hasAvatar = false;
        this.GetAccount();
      },
      (failure) => {
        console.log(failure);
      }
    );
  };

  SuccessUploadHandler = (e: SuccessEvent) => {
    this.toastrService.ShowSuccess(e["response"]["statusText"]);
    console.log(e);
    this.openChangeAvatarDialog = false;
    this.GetAccount();
  };

  ErrorUploadHandler = (e: ErrorEvent) =>
    this.toastrService.ShowError("Image file is to large (Max Size: 65MB)");

  UpdateFormFields = (event, user) => {
    event.preventDefault();
    this.toastrService.ShowWarning("Changes will not take effect until update");
    this.openEditUserDialog = false;
  };

  CheckPassword = (accountForm) => {
    if (accountForm.value["password"] === "") {
      accountForm.controls["confirmpassword"].setValue("");
      accountForm.controls["authPassword"].setValue("");
    }
  };

  onSubmit(accountForm: NgForm, userName: string): void {
    if (accountForm.form.valid) {
      accountForm.form.addControl("userName", new FormControl("", []));
      accountForm.form.addControl("portalRole", new FormControl("", []));
      accountForm.controls["userName"].setValue(userName);

      this.userAccountService
        .UpdateAccount(accountForm.form.value)
        .pipe(takeWhile(() => this.alive))
        .subscribe(
          (result) => {
            if (result === null) {
              this.toastrService.ShowSuccess("Update Successfull");
              this.GetAccount();
            } else {
              this.toastrService.ShowWarning(result);
            }
          },
          (error) => {
            this.toastrService.ShowError(error.statusText);
          }
        );
    } else {
      this.toastrService.ShowError("Please enter your password and try again");
    }
  }
}

import { AdminSelect } from 'src/models/interfaces/admin.select';
import { Environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAssign } from 'src/models/interfaces/user.assign';

@Injectable({
  providedIn: 'root'
})

export class UserAssignmentService {

  static readonly authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
  });

  private baseUrl: string = Environment.serverPath;
  private quaditUrl: string = Environment.quaditPath;
  private utmeterUrl: string = Environment.utmeterPath;

  private localUrl: string = "http://localhost:63000";

  //private baseUrl: string = "http://localhost:63000";
  //private localQauditUrl: string = "http://localhost:62029";
  //private localUtilimeterUrl: string = "http://localhost:64655";

  constructor(private httpClient: HttpClient) { }

  DeleteAdminAssignment(asKvp: AdminSelect) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/deleteAssign',
    asKvp,
    { headers: UserAssignmentService.authHeaders });
  }

  GetAdminAssigned() {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/getAdminAssigned',
      null,
      { headers: UserAssignmentService.authHeaders });
  }

  GetRoleNameAvatar() {
    //console.log(this.localUrl + '/api/accounts/getAdminAssignmentUsers');
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/getAdminAssignmentUsers',
      null,
      { headers: UserAssignmentService.authHeaders });
  }

  GetShowUserDetails({ un }: { un: string; }) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/getUserDetails',
    { userName: un },
    { headers: UserAssignmentService.authHeaders });
  }

  SetAdminAssignment(aName: string, assName: string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/addAssign',
    { adminName: aName, assigneeName: assName },
    { headers: UserAssignmentService.authHeaders });
  }

  SetSiteRole(roleId:string, adminName:string, userName:string) {
    //console.log(roleId + " " + userName + " " + adminName);
    return this.httpClient.post<any>(this.quaditUrl + '/api/qm/setrole',
    { 
      SetRoleId: roleId,
      SubUserName: userName, 
      UserName: adminName 
    },
    { headers: UserAssignmentService.authHeaders });
    //return { SetRoleId: roleId, adminName: adminName, userName: userName };
  }

  // Test Function
  GetMarkets(site:string, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/subordinatemarkets',
        {
          IsWebAdmin: true,
          SubUserName: userName,
          UserName: userName
        });
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/mktcontrol/usermarkets',
        {
          UserName: userName
        });
        break;
    }
    
  }

  GetRoles(site:String, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/utiliRole/gridroles',
        {
        	UserName : userName
        });
        break;
    }
  }

  GetRolesForSelectedSites = (sites:Array<string>, userName:string) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/rolesforselectedsites',
    {
      SelectedSites: sites,
      UserName: userName,
      SubUserName: userName,
      IsWebAdmin: true
    },
    { headers: UserAssignmentService.authHeaders })
  }

  GetMarketsForSelectedSites = (sites:Array<string>, userName:string) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/marketsforselectedsites',
    {
      SelectedSites: sites,
      UserName: userName,
      SubUserName: userName,
      IsWebAdmin: true
    },
    { headers: UserAssignmentService.authHeaders })
  }

  GetUsers(site:string, userName:String) {
    switch(site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
    }
  }

  /***** Parsing Methods *****/

  ParseImageUrl(url: UserAssign): string {
  
    if (url == undefined) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
    if (url.image == null) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';

    return 'data:image/JPEG;base64,' + url.image;
  }

  ParseUserDetailImageUrl(userImage: string): string {
  
    if (userImage == undefined || userImage == null) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';

    return 'data:image/JPEG;base64,' + userImage;
  }  
}
import { HttpParams } from '@angular/common/http';
import { Environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LinkmenuService {
    constructor(http) {
        this.http = http;
        this.GetMenuLinks = (userName) => {
            const params = new HttpParams().set("userName", userName);
            return this.http.get(Environment.serverPath + "/api/utililink/getauthlinks", { params });
        };
    }
}
LinkmenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LinkmenuService_Factory() { return new LinkmenuService(i0.ɵɵinject(i1.HttpClient)); }, token: LinkmenuService, providedIn: "root" });

import { Environment } from '../../environments/environment'; 
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserCredentials } from 'src/models/interfaces/user.credentials';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  static readonly anonHeaders = new HttpHeaders({
    'Anonymous': 'true',
    'Content-Type': 'application/json'
  });
  static readonly authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
  });

  private baseUrl: string = Environment.serverPath;
  private localUrl : string = 'http://localhost:63000';

  constructor(private httpClient: HttpClient) { }

  FetchUserAvatar(loginUn: string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/getAvatar',
      { userName: loginUn },
      { headers: LoginService.authHeaders });
  }

  FetchUserName(loginUn: string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/whatsMyName',
      { userName: loginUn },
      { headers: LoginService.authHeaders });
  }

  GenerateSeedApi(loginUn: string) {
    let params = new HttpParams().set('userName', loginUn);

    return this.httpClient.get<any>(this.baseUrl + '/api/jwt/token',
      {
        headers: LoginService.anonHeaders,
        params: params
      });
  }

  GotoMyAccount(loginUn: string) {
    let params = new HttpParams().set('userName', loginUn);

    return this.httpClient.get<any>(this.baseUrl + '/api/accounts/gotomember',
      {
        headers: LoginService.authHeaders,
        params: params
      });
  }

  IsPwReset(loginUn: string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/ispasswordreset',
      { userName: loginUn },
      { headers: LoginService.anonHeaders });
  }

  SaveJsonWebToken(accessToken: string, webjwt: string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/jwt/savejwt',
      {
        jwtString: accessToken,
        queryKey: webjwt
      },
      { headers: LoginService.authHeaders });
  }

  UserAuthentication(uCreds: UserCredentials) {
    let reqHeader = new HttpHeaders({
      'Anonymous': 'true',
      'Content-Type': 'application/x-www-urlencoded'
    });
    
    let urlSearchParams = new URLSearchParams();

    for (var cr in uCreds)
        if (uCreds.hasOwnProperty(cr)) urlSearchParams.set(cr, uCreds[cr]);
    return this.httpClient.post<any>(this.baseUrl + '/oauth/token',
      urlSearchParams.toString(),
      { headers: reqHeader });  
  }
}
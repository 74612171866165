import { AppService } from '../app.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  public showDashboard: boolean;

  constructor(private appSrv: AppService) { }

  ngOnInit() {
    this.appSrv.ShowDashboardObservable()
    .subscribe(db => this.showDashboard = db);
  }
}
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./linkmenu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./linkmenu.component";
import * as i4 from "../app.service";
import * as i5 from "./linkmenu.service";
import * as i6 from "@angular/router";
var styles_LinkMenuComponent = [i0.styles];
var RenderType_LinkMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkMenuComponent, data: {} });
export { RenderType_LinkMenuComponent as RenderType_LinkMenuComponent };
function View_LinkMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-lg-3 d-flex m-4 h-35 link-container pb-4 pr-4 pl-4 pt-2"], ["style", "border-radius: 10px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.NavigationTo(_v.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "d-flex flex-row w-100 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "d-flex flex-column w-100 h-100 justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "pt-2 text-white"], ["style", "\n            font-size: 1.2em;\n            letter-spacing: 2px;\n            text-shadow: 0 0 7px #000;\n          "]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "img-fluid link-image w-100 h-100"], ["style", "max-width: 130px"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.description; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.GetIconImage(_v.context.$implicit.icon), ""); _ck(_v, 5, 0, currVal_1); }); }
export function View_LinkMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "d-flex flex-row w-100 justify-content-center align-items-center flex-wrap h-100"], ["style", "margin: auto; width: 65% !important"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LinkMenuComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.linkMenuObsersable$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LinkMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "linkmenu", [], null, null, null, View_LinkMenuComponent_0, RenderType_LinkMenuComponent)), i1.ɵdid(1, 245760, null, 0, i3.LinkMenuComponent, [i4.AppService, i5.LinkmenuService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LinkMenuComponentNgFactory = i1.ɵccf("linkmenu", i3.LinkMenuComponent, View_LinkMenuComponent_Host_0, {}, {}, []);
export { LinkMenuComponentNgFactory as LinkMenuComponentNgFactory };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UseraccountComponent } from './useraccount.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DialogModule }  from '@progress/kendo-angular-dialog';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UploadModule } from '@progress/kendo-angular-upload';
import { NgxSpinnerModule } from "ngx-spinner"; 
import { MustMatchDirective } from '../directives/MustMatch.directive'

@NgModule({
  imports: [
    NgxSpinnerModule,
    UploadModule,
    ButtonsModule,
    RouterModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule
  ],
  declarations: [UseraccountComponent, MustMatchDirective],
  exports:[UseraccountComponent]
})
export class UseraccountModule { }

import { Environment } from '../../environments/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LoginService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = Environment.serverPath;
        this.localUrl = 'http://localhost:63000';
    }
    FetchUserAvatar(loginUn) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/getAvatar', { userName: loginUn }, { headers: LoginService.authHeaders });
    }
    FetchUserName(loginUn) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/whatsMyName', { userName: loginUn }, { headers: LoginService.authHeaders });
    }
    GenerateSeedApi(loginUn) {
        let params = new HttpParams().set('userName', loginUn);
        return this.httpClient.get(this.baseUrl + '/api/jwt/token', {
            headers: LoginService.anonHeaders,
            params: params
        });
    }
    GotoMyAccount(loginUn) {
        let params = new HttpParams().set('userName', loginUn);
        return this.httpClient.get(this.baseUrl + '/api/accounts/gotomember', {
            headers: LoginService.authHeaders,
            params: params
        });
    }
    IsPwReset(loginUn) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/ispasswordreset', { userName: loginUn }, { headers: LoginService.anonHeaders });
    }
    SaveJsonWebToken(accessToken, webjwt) {
        return this.httpClient.post(this.baseUrl + '/api/jwt/savejwt', {
            jwtString: accessToken,
            queryKey: webjwt
        }, { headers: LoginService.authHeaders });
    }
    UserAuthentication(uCreds) {
        let reqHeader = new HttpHeaders({
            'Anonymous': 'true',
            'Content-Type': 'application/x-www-urlencoded'
        });
        let urlSearchParams = new URLSearchParams();
        for (var cr in uCreds)
            if (uCreds.hasOwnProperty(cr))
                urlSearchParams.set(cr, uCreds[cr]);
        return this.httpClient.post(this.baseUrl + '/oauth/token', urlSearchParams.toString(), { headers: reqHeader });
    }
}
LoginService.anonHeaders = new HttpHeaders({
    'Anonymous': 'true',
    'Content-Type': 'application/json'
});
LoginService.authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
});
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });

import { AppService } from '../app.service';
import { Component, OnInit } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Toastr } from '../common/toastr.service';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'file-download',
  templateUrl: './filedownload.component.html',
  styleUrls: ['./filedownload.component.scss']
})
export class FileDownloadComponent implements OnInit {
  public userProfile: UserProfile;

  constructor(
    private appSrv: AppService,
    private httpClient: HttpClient,
    private toastr: Toastr) { }

  ngOnInit() {
    this.appSrv.GetUserProfileObservable()
    .subscribe(upo => this.userProfile = upo);
  }

  /***** Events *****/

  onDownloadClick() {
    this.appSrv.ShowSpinnerSet(true);
    this.httpClient.post<any>(Environment.serverPath + '/api/fdl/getdlfldr',
    {
      fileId: this.appSrv.FileDownloadGet()
    },
    { headers: new HttpHeaders({
      'Anonymous': 'false',
      'Content-Type': 'application/json'
      })
    }).subscribe( 
      success => {
        this.appSrv.ShowSpinnerSet(false);
        window.location.href = Environment.assetsUrl + this.appSrv.AppSettingsGet().downloadFolder + success.SubFolderFileUrl;
      },
      failure => {
        this.appSrv.ShowSpinnerSet(false);
        this.toastr.ShowErrorResponse(failure);
      }
    );
  }
}
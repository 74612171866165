import { AppSettings } from 'src/models/interfaces/app.settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserProfile } from '../models/interfaces/user.profile';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private appSettings: BehaviorSubject<AppSettings>;
    private avatarImage: BehaviorSubject<any>;
    private fileDownload: BehaviorSubject<string>;
    private fullName: BehaviorSubject<string>;
    private gotoMyAccount: BehaviorSubject<boolean>;
    private recaptchaResponse: BehaviorSubject<string>;
    private showDashboard: BehaviorSubject<boolean>;
    private showSpinner: BehaviorSubject<boolean>;
    private urlVariable: BehaviorSubject<string>;
    private userProfile: BehaviorSubject<UserProfile>;

    constructor() {
        this.appSettings = new BehaviorSubject<AppSettings>({
            authError: 'auth_error',
            defaultAvatar: '/images/avatars/noavatar.jpg',
            downloadFolder: "/dropbox/",
            fa: 'FieldAdmin',
            queryStringDownload: "dwnid",
            queryStringReturn: "kmli",
            queryStringReturnAccount: "gtma",            
            reCaptchaError: 'invalid_password_recaptcha',
            reCaptchaPublicKey: '6LfkzIMUAAAAAKOT1Az9sNGyvtlnGO2icyYjovWI',
            sa: 'WebAdmin'
        });
        this.avatarImage = new BehaviorSubject<any>(null);
        this.fileDownload = new BehaviorSubject<string>(null);
        this.fullName = new BehaviorSubject<string>(null);
        this.gotoMyAccount = new BehaviorSubject<boolean>(false);
        this.recaptchaResponse = new BehaviorSubject<string>(null);
        this.showDashboard = new BehaviorSubject<boolean>(false);
        this.showSpinner = new BehaviorSubject<boolean>(false);
        this.urlVariable = new BehaviorSubject<string>(null);
        this.userProfile = new BehaviorSubject<UserProfile>(null);
    }

    /***** Global user profile methods  *****/

    ClearUserProfile(){
        
        if (this.userProfile.value) {
            localStorage.removeItem(this.userProfile.value.userName);
        }

        this.userProfile.next({
            currentState: '',
            isLoggedIn: false,
            jwt: null,
            qkey: null,
            userName: null
        });
    }

    GetUserProfileLocalStorage(getUserName: string): UserProfile {

        if (getUserName) {
            return JSON.parse(localStorage.getItem(getUserName));
        } else {
            return null;
        }
    }

    GetUserProfileObservable(): Observable<UserProfile>{
        return this.userProfile.asObservable();
    }
    
    SetCurrentState(currState: string) {
        let currUp: UserProfile = this.userProfile.value;
        currUp.currentState = currState;

        localStorage.setItem(currUp.userName, JSON.stringify(currUp))      
    }

    SetUserProfile(setJwt: string, setQkey: string, setUserName: string){
        let currUp: UserProfile = this.userProfile.value;
        currUp.isLoggedIn = true;
        currUp.jwt = setJwt;
        currUp.qkey = setQkey;
        currUp.userName = setUserName;

        localStorage.setItem(currUp.userName, JSON.stringify(currUp))
    }

    SetUserProfileFromObject(value: UserProfile) {
        this.userProfile.next(value);
    }

    /***** Global observable variables  *****/

    AppSettingsGet(): AppSettings {
        return this.appSettings.value;      
    }

    AvatarImageObservable(): any {
        return this.avatarImage.asObservable();
    }

    AvatarImageGet(): any {
        return this.avatarImage.value;
    }

    AvatarImageSet(value: any) {
        this.avatarImage.next(value);
    }

    FileDownloadGet(): string {
        return this.fileDownload.value;
    }

    FileDownloadSet(value: string) {
        this.fileDownload.next(value);
    }  

    FullNameGet(): string {
        return this.fullName.value;
    }

    FullNameObservable(): Observable<string> {
        return this.fullName.asObservable();
    }

    FullNameSet(value: string) {
        this.fullName.next(value);
    }

    GotoMyAccountGet(): boolean {
        return this.gotoMyAccount.value;
    }

    GotoMyAccountSet(value: boolean) {
        this.gotoMyAccount.next(value);
    }

    RecaptchaResponseGet(): string {
        return this.recaptchaResponse.value;
    }

    RecaptchaResponseSet(value: string) {
        this.recaptchaResponse.next(value);
    }

    ShowDashboardObservable(): Observable<boolean> {
        return this.showDashboard.asObservable();
    }

    ShowDashboardSet(value: boolean) {
        this.showDashboard.next(value);
    }

    ShowSpinnerObservable(): Observable<boolean> {
        return this.showSpinner.asObservable();
    }

    ShowSpinnerSet(value: boolean) {
        this.showSpinner.next(value);
    }

    UrlVariableGet(): string {
        return this.urlVariable.value;
    }

    UrlVariableSet(value: string) {
        this.urlVariable.next(value);
    }


}
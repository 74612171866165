import { AdminAssign } from 'src/models/interfaces/admin.assign';
import { AdminSelect } from 'src/models/interfaces/admin.select';
import { AppService } from '../../app.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart} from '@angular/cdk/drag-drop';
import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ConsoleLogger } from '../../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { Toastr } from '../../common/toastr.service';
import { UserAssignmentService } from '../../userassignment/userassignment.service';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { UserDetails } from 'src/models/interfaces/user.details';
import { ProductAssign } from 'src/models/interfaces/product.assign';

@Component({
  selector: 'userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss', '../../userassignment/userassignment.component.scss'],
})
export class UserListComponent implements OnInit {

    @Input() adminAssign: AdminAssign[] = []; 
    @Output() adminAssignChange = new EventEmitter<AdminAssign[]>();
    @Input() adminSelect: AdminSelect;
    @Output() adminSelectChange = new EventEmitter<any>();
    @Input() isUsersLoaded: boolean = false;
    @Input() userArray: UserAssign[] = [];
    @Output() userArrayChange = new EventEmitter<UserAssign[]>();
    @Input() userDetails: UserDetails;
    @Input() productAssign: ProductAssign[] = [];
    @Output() productAssignChange = new EventEmitter<ProductAssign[]>();
    @Input() isRole: boolean = false;
    originalArray: UserAssign[] = [];

  //public adminAssign: AdminAssign[] = [];
  public environmentUrl: string;
  public helpIconUrl: string;
  public isCollapsed: boolean = true;
  public origArray: UserAssign[] = [];
  public showConfirm: boolean = false;

  constructor(private appSrv: AppService,
              private consoleLogger: ConsoleLogger,
              private toastr: Toastr,
              public userAssignmentService: UserAssignmentService) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.helpIconUrl = "/assets/images/plus.png";
    this.adminSelect = {
      adminName: '',
      assigneeName: ''      
    };
    this.userDetails = {
      company: '',
      email: '',
      fullName: '',
      image: '',
      job: '',
      phone: '',
      role: '',
      userName: '',
      valid: false
     };
     this.originalArray = this.userArray;
    //this.GetUserListOnLoad();
    //this.debugComponent();
  }

  /***** Events *****/

  GetUserListOnLoad() {
    this.appSrv.ShowSpinnerSet(true);

    this.userAssignmentService.GetRoleNameAvatar().subscribe(
      success => {
        this.origArray = success.map((x: any) => Object.assign({}, x));
        this.resetList();

        this.userAssignmentService.GetAdminAssigned().subscribe(
          success => {
            this.adminAssign = success.map((x: any) => Object.assign({}, x));
            this.isUsersLoaded = true;
            this.appSrv.ShowSpinnerSet(false);
          },
          failure => {
            this.ErrorReporter({ failure, consoleText: 'Fetch admin assignment list failed.' });
          });
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Fetch User List Failed.' });
      });
  }

  dragStarted(un: string) {
    this.UpdateUserDetails(un);
  }

  onClick(assu: string) {
    this.UpdateUserDetails(assu);
  }

  searchArray(event) {
    var tempUsers:UserAssign[] = [];
    this.originalArray.length < this.userArray.length ? this.originalArray = this.userArray : null;
    this.originalArray.forEach(user => {
      user.userName.indexOf(event.target.value) > -1 ? tempUsers.push(user) : null;
    });
    this.userArrayChange.emit(tempUsers);
  }

  /***** Rendering Methods *****/

  /***** Private Common Methods *****/

  private ErrorReporter({ failure, consoleText }: { failure: any; consoleText: string; }) {
    this.consoleLogger.LogStatusToConsole(failure, consoleText);
    this.toastr.ShowErrorResponse(failure);
    this.appSrv.ShowSpinnerSet(false);
  }

  private resetList() {
    this.userArray = [];

    setTimeout(() => { 
      this.userArray = this.origArray.slice();
    }, 0);    
  }

  private UpdateUserDetails(un: string) {
    this.userAssignmentService.GetShowUserDetails({ un }).subscribe(
      success => {
        this.userDetails = {
          company: success.company,
          email: success.email,
          fullName: success.firstName + ' ' + success.lastName,
          image: this.userAssignmentService.ParseUserDetailImageUrl(success.image),
          job: success.jobTitle,
          phone: success.phoneNumber,
          role: success.role,
          userName: success.userName,
          valid: true
          }
      },
      () => {
        this.userDetails = {
          company: '',
          email: '',
          fullName: '',
          image: Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg',
          job: '',
          phone: '',
          role: '',
          userName: '',
          valid: false
          }
      });   
  }

  // Debug Functions

  debugComponent() {

  }

}
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class AppService {
    constructor() {
        this.appSettings = new BehaviorSubject({
            authError: 'auth_error',
            defaultAvatar: '/images/avatars/noavatar.jpg',
            downloadFolder: "/dropbox/",
            fa: 'FieldAdmin',
            queryStringDownload: "dwnid",
            queryStringReturn: "kmli",
            queryStringReturnAccount: "gtma",
            reCaptchaError: 'invalid_password_recaptcha',
            reCaptchaPublicKey: '6LfkzIMUAAAAAKOT1Az9sNGyvtlnGO2icyYjovWI',
            sa: 'WebAdmin'
        });
        this.avatarImage = new BehaviorSubject(null);
        this.fileDownload = new BehaviorSubject(null);
        this.fullName = new BehaviorSubject(null);
        this.gotoMyAccount = new BehaviorSubject(false);
        this.recaptchaResponse = new BehaviorSubject(null);
        this.showDashboard = new BehaviorSubject(false);
        this.showSpinner = new BehaviorSubject(false);
        this.urlVariable = new BehaviorSubject(null);
        this.userProfile = new BehaviorSubject(null);
    }
    /***** Global user profile methods  *****/
    ClearUserProfile() {
        if (this.userProfile.value) {
            localStorage.removeItem(this.userProfile.value.userName);
        }
        this.userProfile.next({
            currentState: '',
            isLoggedIn: false,
            jwt: null,
            qkey: null,
            userName: null
        });
    }
    GetUserProfileLocalStorage(getUserName) {
        if (getUserName) {
            return JSON.parse(localStorage.getItem(getUserName));
        }
        else {
            return null;
        }
    }
    GetUserProfileObservable() {
        return this.userProfile.asObservable();
    }
    SetCurrentState(currState) {
        let currUp = this.userProfile.value;
        currUp.currentState = currState;
        localStorage.setItem(currUp.userName, JSON.stringify(currUp));
    }
    SetUserProfile(setJwt, setQkey, setUserName) {
        let currUp = this.userProfile.value;
        currUp.isLoggedIn = true;
        currUp.jwt = setJwt;
        currUp.qkey = setQkey;
        currUp.userName = setUserName;
        localStorage.setItem(currUp.userName, JSON.stringify(currUp));
    }
    SetUserProfileFromObject(value) {
        this.userProfile.next(value);
    }
    /***** Global observable variables  *****/
    AppSettingsGet() {
        return this.appSettings.value;
    }
    AvatarImageObservable() {
        return this.avatarImage.asObservable();
    }
    AvatarImageGet() {
        return this.avatarImage.value;
    }
    AvatarImageSet(value) {
        this.avatarImage.next(value);
    }
    FileDownloadGet() {
        return this.fileDownload.value;
    }
    FileDownloadSet(value) {
        this.fileDownload.next(value);
    }
    FullNameGet() {
        return this.fullName.value;
    }
    FullNameObservable() {
        return this.fullName.asObservable();
    }
    FullNameSet(value) {
        this.fullName.next(value);
    }
    GotoMyAccountGet() {
        return this.gotoMyAccount.value;
    }
    GotoMyAccountSet(value) {
        this.gotoMyAccount.next(value);
    }
    RecaptchaResponseGet() {
        return this.recaptchaResponse.value;
    }
    RecaptchaResponseSet(value) {
        this.recaptchaResponse.next(value);
    }
    ShowDashboardObservable() {
        return this.showDashboard.asObservable();
    }
    ShowDashboardSet(value) {
        this.showDashboard.next(value);
    }
    ShowSpinnerObservable() {
        return this.showSpinner.asObservable();
    }
    ShowSpinnerSet(value) {
        this.showSpinner.next(value);
    }
    UrlVariableGet() {
        return this.urlVariable.value;
    }
    UrlVariableSet(value) {
        this.urlVariable.next(value);
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(); }, token: AppService, providedIn: "root" });

import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';    
import { Injectable } from '@angular/core';    
import { Observable, throwError } from 'rxjs';    
import { tap, catchError } from "rxjs/operators";
import { Router } from '@angular/router';    
import { Toastr } from './toastr.service';
    
@Injectable()    
export class HttpErrorInterceptor implements HttpInterceptor{    
    
    constructor(private router:Router,
        private toastrService:Toastr) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(evt => {
                //console.log(evt);
            }),
            catchError((err:any) => {
                !err.message.includes('currentuserinfo') ? this.toastrService.ShowError(err.message) : null;
                return throwError(err);
                return next.handle(req.clone());
            })
        ); 
    }    
} 
import { AdminAssign } from 'src/models/interfaces/admin.assign';
import { AdminSelect } from 'src/models/interfaces/admin.select';
import { AppService } from '../app.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart} from '@angular/cdk/drag-drop';
import { Component, OnInit, HostListener } from '@angular/core';
import { ConsoleLogger } from '../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { Toastr } from '../common/toastr.service';
import { UserAssignmentService } from '../userassignment/userassignment.service';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { UserDetails } from 'src/models/interfaces/user.details';
import { MarketAssign } from 'src/models/interfaces/market.assign';
import { ProductAssign } from 'src/models/interfaces/product.assign';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: 'roleassignment',
  templateUrl: './roleassignment.component.html',
  styleUrls: ['./roleassignment.component.scss',  '../userassignment/userassignment.component.scss'],
})
export class RoleAssignmentComponent implements OnInit {
  public adminAssign: AdminAssign[] = [];
  public adminSelect: AdminSelect;
  public environmentUrl: string;
  public helpIconUrl: string;
  public isCollapsed: boolean = true;
  public isUsersLoaded: boolean = false;
  public origArray: UserAssign[] = [];
  public showConfirm: boolean = false;
  public userArray: UserAssign[] = [];
  public userDetails: UserDetails;
  public marketAssign: MarketAssign[] = [];
  public productAssign: ProductAssign[] = [];
  public userProfile: UserProfile;

  constructor(private appSrv: AppService,
              private consoleLogger: ConsoleLogger,
              private toastr: Toastr,
              public userAssignmentService: UserAssignmentService) { }

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.helpIconUrl = "/assets/images/plus.png";
    this.appSrv.GetUserProfileObservable()
    .subscribe(upo => this.userProfile = upo);
    this.adminSelect = {
      adminName: '',
      assigneeName: ''      
    };
    this.userDetails = {
      company: '',
      email: '',
      fullName: '',
      image: '',
      job: '',
      phone: '',
      role: '',
      userName: '',
      valid: false
     };
    this.GetUserListOnLoad();
    this.GetMarketsOnLoad();
    this.debugComponent();
    this.GetUsers();
  }

  /***** Event listeners *****/

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    
    if (event.keyCode === 46) {

      if (this.adminSelect.adminName && this.adminSelect.assigneeName) {
        this.showConfirm = true;
      } else {
        this.toastr.ShowWarning('Select assigned user to delete.', 'Nothing Selected')     
      }
    }
  }

  /***** Events *****/

  GetUserListOnLoad() {
    this.appSrv.ShowSpinnerSet(true);

    this.userAssignmentService.GetRoleNameAvatar().subscribe(
      success => {
        this.origArray = success.map((x: any) => Object.assign({}, x));
        this.resetList();
        this.isUsersLoaded = true;
        this.userAssignmentService.GetAdminAssigned().subscribe(
          success => {
            this.adminAssign = success.map((x: any) => Object.assign({}, x));
            this.isUsersLoaded = true;
            this.appSrv.ShowSpinnerSet(false);
          },
          failure => {
            this.ErrorReporter({ failure, consoleText: 'Fetch admin assignment list failed.' });
          });
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Fetch User List Failed.' });
      });
  }

  GetMarketsOnLoad() {
    this.userAssignmentService.GetMarkets("qmanage", null).subscribe(
      success => {
        var site = this.productAssign.find(aa => aa.productName == "QManage");
        success.forEach(element => {
          site.markets.push({
            marketName : element.description,
            assignVisible : false,
            roles : [
              
            ]
          })
        });
        this.GetRoles("QManage");
      },
      failure => {
        console.log(failure);
      }
    )
    this.userAssignmentService.GetMarkets("utilimeter", this.userProfile.userName).subscribe(
      success => {
        var site = this.productAssign.find(aa => aa.productName == "Utilimeter");
        success.forEach(element => {
          site.markets.push({
            marketName : element.marketDescription,
            assignVisible : false,
            roles : [
              
            ]
          })
        });
        this.GetRoles("Utilimeter");
      },
      failure => {
        console.log(failure);
      }
    )
  }

  GetRoles(site:String) {
    this.userAssignmentService.GetRoles(site.toLowerCase(), this.userProfile.userName).subscribe(
      success => {
        var Site = this.productAssign.find(aa => aa.productName == site);
        Site.markets.forEach(market => {
          success.roleList.forEach(element => {
            market.roles.push({
              roleName: element.description,
              roleId: element.roleId,
              assignVisible: true,
              assigneeUsers: null
            });
          });
        });
      },
      failure => {
        console.log(failure);
      }
    )
  }

  GetUsers() {
    this.appSrv.ShowSpinnerSet(true);
    /* this.productAssign.forEach( site => {
      this.userAssignmentService.GetUsers(site.productName).subscribe(
        success => {

        }
      )
    }); */

    /* this.userAssignmentService.GetRoleNameAvatar().subscribe(
      success => {
        this.origArray = success.map((x: any) => Object.assign({}, x));
        this.resetList();
        this.isUsersLoaded = true;
        this.userAssignmentService.GetAdminAssigned().subscribe(
          success => {
            this.adminAssign = success.map((x: any) => Object.assign({}, x));
            this.isUsersLoaded = true;
            this.appSrv.ShowSpinnerSet(false);
          },
          failure => {
            this.ErrorReporter({ failure, consoleText: 'Fetch admin assignment list failed.' });
          });
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Fetch User List Failed.' });
      }); */
  }

  dragStarted(un: string) {
    this.UpdateUserDetails(un);
  }

  /* onClick(assu: string) {
    this.UpdateUserDetails(assu);
  } */

  /* onClickAssigned(au: string, assu: string) {
    this.adminSelect = {
      adminName: au,
      assigneeName: assu
    };
    this.UpdateUserDetails(assu);
  } */

  onCloseClick() {
    this.showConfirm = false;
  }

  onDeleteConfirm(yesNo: boolean) {
    this.onCloseClick();

    if (!yesNo) return;

    this.appSrv.ShowSpinnerSet(true);
    this.userAssignmentService.DeleteAdminAssignment(this.adminSelect).subscribe(
      () => {
        var selAdmin = this.adminAssign.find(aa => aa.adminName == this.adminSelect.adminName);

        if (selAdmin == null) {
          this.toastr.ShowWarning('Display failed to update. Please reload this page.', 'Display sync error')
        } else {
          var saIndex = selAdmin.assigneeUsers.findIndex(as => as.userName == this.adminSelect.assigneeName);
    
          if (saIndex == -1) {
            this.toastr.ShowWarning('Display data out of date. Please reload this page.', 'Display sync error')
          } else {
            selAdmin.assigneeUsers.splice(saIndex, 1);
          }  
        }    

        this.toastr.ShowSuccess('Assignment delete successful.')
        this.appSrv.ShowSpinnerSet(false);
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
      });
  }

  /* onDrop(event: CdkDragDrop<string[]>, adminName: string) {
    var aaData = this.adminAssign.find(aa => aa.adminName == adminName);

    if (aaData.assigneeUsers == null){
      aaData.assigneeUsers = [];
    } else {

      if (aaData.assigneeUsers.find(au => au.userName == event.item.data.userName)){
        this.toastr.ShowWarning(event.item.data.userName + ' already assigned to ' + adminName + '.', 'Already Assigned')
        return;
      } 
    }

    this.appSrv.ShowSpinnerSet(true);

    // DEBUG

    aaData.assigneeUsers.push( event.item.data );    
    aaData.assigneeUsers.sort((a, b) => {
      if (a.userName < b.userName) return -1;
      else if (a.userName > b.userName) return 1;
      else return 0;
    });
    this.appSrv.ShowSpinnerSet(false);

    this.userAssignmentService.SetAdminAssignment(adminName, event.item.data.userName).subscribe(
      () => {
        aaData.assigneeUsers.push( event.item.data );    
        aaData.assigneeUsers.sort((a, b) => {
          if (a.userName < b.userName) return -1;
          else if (a.userName > b.userName) return 1;
          else return 0;
        });
        this.appSrv.ShowSpinnerSet(false);
      },
      failure => {
        this.ErrorReporter({ failure, consoleText: 'Admin assignment failed to save.' });
      });
  } */

  checkAdminAssign(event) {
    var index = this.userArray.indexOf(event);
    if (index !== -1) this.userArray.splice(index, 1);
  }

  /***** Rendering Methods *****/

  toggleHelpText() {
    this.isCollapsed = !this.isCollapsed
    this.helpIconUrl = this.isCollapsed ? "/assets/images/plus.png" : "/assets/images/minus.png";
  }

  /***** Private Common Methods *****/

  private ErrorReporter({ failure, consoleText }: { failure: any; consoleText: string; }) {
    this.consoleLogger.LogStatusToConsole(failure, consoleText);
    this.toastr.ShowErrorResponse(failure);
    this.appSrv.ShowSpinnerSet(false);
  }

  private resetList() {
    this.userArray = [];

    setTimeout(() => { 
      this.userArray = this.origArray.slice();
    }, 0);    
  }

  private UpdateUserDetails(un: string) {
    this.userAssignmentService.GetShowUserDetails({ un }).subscribe(
      success => {
        this.userDetails = {
          company: success.company,
          email: success.email,
          fullName: success.firstName + ' ' + success.lastName,
          image: this.userAssignmentService.ParseUserDetailImageUrl(success.image),
          job: success.jobTitle,
          phone: success.phoneNumber,
          role: success.role,
          userName: success.userName,
          valid: true
          }
      },
      () => {
        this.userDetails = {
          company: '',
          email: '',
          fullName: '',
          image: Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg',
          job: '',
          phone: '',
          role: '',
          userName: '',
          valid: false
          }
      });   
  }

  // Debug Functions

  debugComponent() {
    /* this.userDetails = {
      company: 'QUES',
      email: 'c@ques.com',
      fullName: 'David Hill',
      image: '',
      job: 'Job',
      phone: '111.222.3333',
      role: 'User',
      userName: 'DHill',
      valid: true
    };
    this.userArray.push({
      image: 'http://tpc.googlesyndication.com/simgad/2735792949360454598',
      role: 'user',
      userName: 'Bob Bobinson'
    })
    this.userArray.push({
      image: 'http://tpc.googlesyndication.com/simgad/2735792949360454598',
      role: 'user',
      userName: 'Rob Robinson'
    })
    this.userArray.push({
      image: 'http://tpc.googlesyndication.com/simgad/2735792949360454598',
      role: 'user',
      userName: 'Jimothy Jimbo'
    })
    this.productAssign.push({
      productName: 'QPass',
      assignVisible: true,
      markets: [
        {
          marketName: 'STEM-C',
          assignVisible: true,
          roles: [
            
          ]
        },
      ],
    }); */
    this.productAssign.push({
      productName: 'QManage',
      assignVisible: false,
      markets: [
        /* {
          marketName: 'Nevada Energy',
          assignVisible: true,
          roles: [
            {
              roleName: 'User',
              assignVisible: true,
              assigneeUsers: null
            }
          ]
        },
        {
          marketName: 'LazyQ',
          assignVisible: true,
          roles: [
            {
              roleName: 'Customer',
              assignVisible: true,
              assigneeUsers: null
            },
            {
              roleName: 'User',
              assignVisible: true,
              assigneeUsers: null
            }
          ]
        }, */
      ]
    });
    this.productAssign.push({
      productName: 'Utilimeter',
      assignVisible: false,
      markets: []
    });
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment } from '../../environments/environment'
import {Observable,} from 'rxjs';
import { IMenuLink } from 'src/models/interfaces/IMenuLink'
@Injectable({
  providedIn: 'root'
})
export class LinkmenuService { 

constructor(private http:HttpClient) {
 }

GetMenuLinks = (userName:string):Observable<IMenuLink> => {
  const params = new HttpParams().set("userName", userName)
  return this.http.get<IMenuLink>(Environment.serverPath +"/api/utililink/getauthlinks",{params});
}

}

<div style="margin: auto; max-width: 50%" class="row col-lg-12 useraccessrequest">
    <div class="panel panel-primary">
        <div style="background: black" class="panel-heading">UARF</div>
        <div style="max-height: 580px; overflow-y: auto; background: #283F5D; color: white; min-height: 580px" class="panel-body">

            <form style="text-align: left" name="userForm" enctype="multipart/form-data" (submit)="submitForm($event)" novalidate="" class="ng-pristine ng-invalid ng-invalid-required ng-valid-pattern ng-valid-email">

                <h5>User Access Request Type<span style="color: red"> *</span></h5>
                <div class="indent">
                    <div *ngFor="let option of userAccessReqTypes; let i = index">
                        <input [attr.value]="option" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="uart" [attr.value]="option" [attr.id]="i">
                        <label>
                            {{option}}
                        </label>
                    </div>
                    <!-- <p *ngIf="uarf.requestFieldValues.customfield_10048.value === null && submitted" class="help-block ng-hide" style="color:#a94442">Request Type is required.</p> -->

                </div>
                <br>
                <div class="form-group" ng-class="{ 'has-error' :userForm.name.$invalid && userForm.name.$pristine && submitted  }">
                    <label>Name Field<span style="color: red"> *</span></label>
                    <input type="text" name="name" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" [(ngModel)]="uarf.requestFieldValues.customfield_10037" required="">
                    <!-- <p *ngIf="userForm.name.$invalid && userForm.name.$pristine && submitted" class="help-block ng-hide">Name is required.</p> -->

                </div>

                <div class="form-group" ng-class="{ 'has-error' : userForm.title.$invalid && userForm.title.$pristine  && submitted }">
                    <label>Title<span style="color: red"> *</span></label>
                    <input type="text" name="title" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" [(ngModel)]="uarf.requestFieldValues.customfield_10044" required="">
                    <!-- <p *ngIf="userForm.title.$invalid && userForm.title.$pristine && submitted" class="help-block ng-hide">Title is required.</p> -->

                </div>

                <div class="form-group" ng-class="{ 'has-error' : userForm.group.$invalid && userForm.group.$pristine  && submitted }">
                    <label>Group<span style="color: red"> *</span></label>
                    <input type="text" name="group" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" [(ngModel)]="uarf.requestFieldValues.customfield_10047" required="">
                    <!-- <p *ngIf="userForm.group.$invalid && userForm.group.$pristine  && submitted" class="help-block ng-hide">Group is required.</p> -->

                </div>

                <h5>Location<span style="color: red"> *</span></h5>
                <div class="indent ng-scope" ng-class="{'has-error': uarf.requestFieldValues.customfield_10046.value === null && submitted}" required="">
                    <!-- <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="location" id="location+0" [(ngModel)]="uarf.requestFieldValues.customfield_10046.value" value="Atlanta">
                    <label class="form-check-label ng-binding" for="location+0">
                        Atlanta
                    </label> -->
                    <div *ngFor="let option of locations; let i = index">
                        <input (click)="setValue($event)" [attr.value]="option" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="location" [attr.id]="i">
                        <label>
                            {{option}}
                        </label>
                    </div>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10046.value === null && submitted" class="help-block ng-hide" style="color:#a94442">Location is required.</p> -->

                <br>

                <div class="form-group">
                    <label>Windows Account (optional)</label>
                    <input type="text" name="windowaccount" class="form-control ng-pristine ng-untouched ng-valid ng-empty" [(ngModel)]="uarf.requestFieldValues.customfield_10049[0].value">
                </div>
                <br>

                <h5>Does this user need an email created for them?<span style="color: red"> *</span></h5>
                <div class="form-check indent">
                    <input class="form-check-input ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" type="radio" name="sendemail" id="sendemail" [(ngModel)]="uarf.requestFieldValues.customfield_10057.id" value="10078" ng-class="{'has-error': uarf.sendemail === null && submitted}" required="">
                    <label class="form-check-label" for="sendemail">
                        Yes
                    </label>
                </div>
                <div class="form-check indent">
                    <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="sendemail" id="sendemail2" [(ngModel)]="uarf.requestFieldValues.customfield_10057.id" value="10079">
                    <label class="form-check-label" for="sendemail2">
                        No, I have provided their current email below
                    </label>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10057.id === null && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->
                <br>


                <div class="form-group" ng-class="{ 'has-error' :userForm.emailinput.$invalid && userForm.emailinput.$pristine && submitted  }">
                    <label>Email Address (optional)</label>
                    <input type="email" name="emailinput" class="form-control ng-pristine ng-untouched ng-empty ng-valid-pattern ng-valid-email ng-valid ng-valid-required" [(ngModel)]="uarf.requestFieldValues.customfield_10040" ng-pattern="/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/" ng-required="uarf.requestFieldValues.customfield_10057.id ==='10078'">
                    <p style="font-size: 10px">
                        This must be provided if you have selected yes above

                    </p>
                    <!-- <p style="color:#a94442" *ngIf="userForm.emailinput.$dirty&&userForm.emailinput.$error.pattern" class="ng-hide">Please Enter Valid Email</p> -->
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10040 === undefined && uarf.requestFieldValues.customfield_10057.id ==='10078' && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->

                <br>

                <div class="form-group">
                    <label>Domain File Share Access (optional)</label>
                    <br>
                    <textarea name="dfsaccess" class="form-control ng-pristine ng-untouched ng-valid ng-empty" [(ngModel)]="uarf.requestFieldValues.customfield_10050[0].value"></textarea>
                </div>
                <br>
                <h5>File Share Access Rights (optional)</h5>
                <div class="indent">
                    <div class="form-check">
                        <input class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="radio" name="fsar" id="fsar1" [(ngModel)]="uarf.requestFieldValues.customfield_10051.id" value="10066">
                        <label class="form-check-label" for="fsar1">
                            Read Only Access
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="radio" name="fsar" id="fsar1" [(ngModel)]="uarf.requestFieldValues.customfield_10051.id" value="10067">
                        <label class="form-check-label" for="fsar2">
                            Read/Write Access
                        </label>
                    </div>
                </div>
                <br>
                <h5>Does the requested access adhere to the defined access privilege levels?<span style="color: red"> *</span></h5>
                <div class="indent ng-scope" ng-repeat="option in commonRadioOptions" ng-class="{'has-error': uarf.requestFieldValues.customfield_10053.value === null && submitted}" required="">
                    <div *ngFor="let option of commonRadioOptions; let i = index">
                        <input [attr.value]="option" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="adaccesspriv" [attr.id]="i">
                        <label>
                            {{option}}
                        </label>
                    </div>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10053.value === null && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->

                <br>

                <h5>Will this User Access Request affect the current Segregation of Duties Matrix?<span style="color: red"> *</span></h5>
                <div class="indent ng-scope" ng-repeat="option in commonRadioOptions" ng-class="{'has-error': uarf.requestFieldValues.customfield_10054.value === null && submitted}" required="">
                    <div *ngFor="let option of commonRadioOptions; let i = index">
                        <input [attr.value]="option" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="affcurrsod" [attr.id]="i">
                        <label>
                            {{option}}
                        </label>
                    </div>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10054.value === null && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->

                <br>

                <h5>Has the Segregation of Duties Matrix been updated?<span style="color: red"> *</span></h5>
                <div class="indent ng-scope" ng-repeat="option in commonRadioOptions" ng-class="{'has-error': uarf.requestFieldValues.customfield_10055.value === null && submitted}" required="">
                    <div *ngFor="let option of commonRadioOptions; let i = index">
                        <input [attr.value]="option" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="radio" name="sodupdated" [attr.id]="i">
                        <label>
                            {{option}}
                        </label>
                    </div>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10055.value === null && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->
                <br>
                <label for="attachment">Attachment (optional)</label>
                <br>
                <input type="file" id="file" name="attachment" multiple="">
                <p style="font-size: 10px">
                    Hold Ctrl to select multiple
                </p>
                <br>

                <h5>User single access account<span style="color: red"> *</span></h5>

                <div class="indent" ng-class="{'has-error': uarf.requestFieldValues.customfield_10041[0].id === false || uarf.requestFieldValues.customfield_10041[0].id === null && submitted}" required="">
                    <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" name="usaa" [(ngModel)]="uarf.requestFieldValues.customfield_10041[0].id" ng-true-value="'10053'" (change)="trueFalseValue(10041, '10053')" value="" id="usaa1">
                    <label class="form-check-label" for="usaa1">
                        I acknowledge this account will only be used by one user.
                    </label>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10041[0].id === false || uarf.requestFieldValues.customfield_10041[0].id === null && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->



                <h5>Access Acknowledgement<span style="color: red"> *</span></h5>
                <div class="indent" ng-class="{'has-error': uarf.requestFieldValues.customfield_10056[0].id === null && submitted}" required="">
                    <input class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" name="usaa2" [(ngModel)]="uarf.requestFieldValues.customfield_10056[0].id" value="" id="usaa2" ng-true-value="'10077'" (change)="trueFalseValue(10056, '10077')" >
                    <label class="form-check-label" for="usaa2">
                        I acknowledge I am making this request in agreement with the Segregation of Duties Matrix
                    </label>
                </div>
                <!-- <p *ngIf="uarf.requestFieldValues.customfield_10056[0].id === false || uarf.requestFieldValues.customfield_10056[0].id === null && submitted" class="help-block ng-hide" style="color:#a94442">This field is required.</p> -->

                <br>

                <button type="submit" class="btn btn-primary">Send</button>

            </form>
        </div>
    </div>
</div>
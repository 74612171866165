<div cdkDropListGroup class="d-flex flex-row h-100 w-100" style="background-color: rgba(17, 27, 39, 0.5); padding: 10px;">
  <!-- <div class="d-flex flex-column h-100 w-25 columnSolid">
    <div class="d-flex flex-row columnTitle">
      <span class="titleText">Users</span>      
    </div>
    <div class="d-flex flex-row columnSeparator"></div>  
    <div cdkDropList cdkDropListSortingDisabled class="d-flex flex-column columnListContainer" *ngIf="isUsersLoaded" [cdkDropListData]="userArray">
      <div cdkDrag class="userListArray" *ngFor="let ua of userArray" (cdkDragStarted)="dragStarted(ua.userName)" [cdkDragData]="ua">
        <table class="userListItem" (click)="onClick(ua.userName)">
          <tr>
            <td rowspan="3" class="imageCell">
              <img class="profileimage" src="{{ userAssignmentService.ParseImageUrl(ua) }}" />
            </td>
            <td cell="textCell">
              <span class="titleText">{{ ua.userName }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-row columnSeparator"></div>
            </td>
          </tr>
          <tr>
            <td cell="textCell">
              <span class="titleText">{{ ua.role }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div> -->
  <userlist class="d-flex flex-column h-100 w-25 columnSolid" [(userArray)]="userArray" [(isUsersLoaded)]="isUsersLoaded"></userlist>
  <!-- <div class="d-flex flex-column h-100 w-50 columnSolid">
    <div class="d-flex flex-row columnTitle">
      <span class="titleText">Field Admins</span>
    </div>
    <div class="d-flex flex-row columnSeparator"></div>
    <div class="d-flex flex-column columnListContainer columnListContainerTop" *ngIf="isUsersLoaded">
      <div class="d-flex flex-row userAdminContainer" *ngFor="let aa of adminAssign">
        <table class="userAdminItem">
          <tr>
            <td cdkDropList class="userAdminCell" (cdkDropListDropped)="onDrop($event, aa.adminName)">
              <img class="assigneeToggle" src="{{ environmentUrl }}{{ toggleAssignPanel(aa) }}" (click)="onAssignVisibleClick(aa)" *ngIf="aa.assigneeUsers" />
              <span class="titleText">{{ aa.adminName }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-row userAssigneeCell" *ngIf="aa.assigneeUsers != null">
                <div cdkDropList id="{{ aa.adminName }}" class="d-flex flex-column columnListContainer w-100" (cdkDropListDropped)="onDrop($event, aa.adminName)" [cdkDropListData]="aa.assigneeUsers">
                  <table id="cell{{ assu.userName }}" class="userListItem" *ngFor="let assu of aa.assigneeUsers" (click)="onClickAssigned(aa.adminName, assu.userName)" [ngbCollapse]="!aa.assignVisible"
                    [class.userListItemSelected]="aa.adminName == adminSelect.adminName && assu.userName == adminSelect.assigneeName">
                    <tr>
                      <td rowspan="3" class="imageCell">
                        <img class="profileimage" src="{{ userAssignmentService.ParseImageUrl(assu) }}" />
                      </td>
                      <td cell="textCell">
                        <span class="titleText">{{ assu.userName }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex flex-row columnSeparator"></div>
                      </td>
                    </tr>
                    <tr>
                      <td cell="textCell">
                        <span class="titleText">{{ assu.role }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </td>
          </tr>          
        </table>
      </div>
    </div>
  </div> -->
  <adminlist class="d-flex flex-column h-100 w-50 columnSolid" [title]="'Field Admins'" [adminAssign]="adminAssign" [(adminSelect)]="adminSelect" [isUsersLoaded]="isUsersLoaded" [userArray]="userArray" [(userDetails)]="userDetails" [isNested]="false" (adminAssignChange)="checkAdminAssign($event)"></adminlist>
  <div class="d-flex flex-column h-100 w-25">
    <div class="d-flex flex-row h-50">
      <div class="userInfoBackground">
        <div class="text-center textCell">
          <span class="userDetailSubtext">How to use this page</span><img class="plusMinusButton" src="{{ environmentUrl }}{{ helpIconUrl }}" (click)="toggleHelpText()" />
        </div>
        <table id="helpTextPanel" class="w-100" [ngbCollapse]="isCollapsed">
          <tr>
            <td>
              <div class="d-flex flex-row userDetailSeparator"></div>
            </td>
          </tr>          
          <tr>
            <td class="text-center helpCell">
              <span class="userDetailSubtext">The portal at the left include all users you can assign them to multiple crews.</span>
            </td>
          </tr>
          <tr>
            <td class="text-center helpCellBottom">
              <span class="userDetailSubtext">To add a crew member to a crew leader they must be a field admin. Simply drag them under the appropriate field admin to addign them to their crew.</span>
            </td>
          </tr>
        </table>  
      </div>
    </div>
    <div class="d-flex flex-row h-50">
      <div class="userDetailBackground" *ngIf="userDetails.valid">
        <table class="w-100"> 
          <tr>
            <td class="text-center textCell">
              <span class="userDetailText">{{ userDetails.fullName }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex flex-row userDetailSeparator"></div>
            </td>
          </tr>
          <tr>
            <td class="text-center userImageCell">
              <img class="userImage" src="{{ userDetails.image }}" />
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <span class="userDetailJob">{{ userDetails.job }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <span class="userDetailText">{{ userDetails.company }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <span class="userDetailSubtext">{{ userDetails.role }}</span>
            </td>
          </tr>
          <tr>
            <td class="userDetailContactCell">
              <div class="w-100 userDetailSubtext">Phone: {{ userDetails.phone }}</div>
              <div class="w-100 userDetailSubtext">Email: {{ userDetails.email }}</div>
            </td>
          </tr>                              
        </table>
      </div>
    </div>
  </div>
</div>
<kendo-window *ngIf="showConfirm" (close)="onCloseClick()" class="panel panel-primary siPanel">
  <kendo-window-titlebar>
      <div class="k-window-title siTitle">Are you sure?</div>
      <button kendoWindowMaximizeAction></button>
      <button kendoWindowRestoreAction></button>
  </kendo-window-titlebar>
  <div class="panel-body">
    <div class="siTextRow m-2">You are about to delete <span class="textHightlight">{{ adminSelect.assigneeName }}</span> from <span class="textHightlight">{{ adminSelect.adminName }}</span> assignment.</div>
    <div class="siTextRow m-2">Are you sure?</div>
    <div class="m-2">
      <button type="button" class="btn btn-success mr-2 btnWidth" (click)="onDeleteConfirm(true)">Yes</button>
      <button type="button" class="btn btn-danger btnWidth" (click)="onDeleteConfirm(false)">No</button>
    </div>
  </div>
</kendo-window>
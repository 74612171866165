import { Environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProjectRequestService {
    //private baseUrl: string = "http://localhost:63000";
    //private localQauditUrl: string = "http://localhost:62029";
    //private localUtilimeterUrl: string = "http://localhost:64655";
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = Environment.serverPath;
        this.quaditUrl = Environment.quaditPath;
        //private quaditUrl: string = "http://localhost:62029/";
        this.utmeterUrl = Environment.utmeterPath;
        this.sampleObject = {
            rows: [
                {
                    index: 1,
                    fieldName: 'Customer',
                    description: 'Name of the Utility or Company',
                    fieldType: 'textSingle',
                    options: null,
                    required: true,
                    warning: true,
                    numbersAllowed: true,
                    maxLength: 50
                },
                {
                    index: 2,
                    fieldName: 'Project Description',
                    description: 'Description of the services (i.e. GLT, URD, OH, Engineering, etc.) offered and what IT will need to facilitate',
                    fieldType: 'textMulti',
                    options: null,
                    required: false,
                    warning: false,
                    numbersAllowed: false,
                    maxLength: 250
                },
                {
                    index: 3,
                    fieldName: 'Have you contacted IT directly?',
                    description: 'You must contact IT by phone or email regardless of filling out this form.',
                    fieldType: 'radioButtons',
                    options: [
                        'Yes',
                        'No'
                    ],
                    required: true,
                    warning: true,
                    numbersAllowed: true,
                    maxLength: 50
                },
            ]
        };
        this.sampleRecord = {
            entries: []
        };
        this.GetRows = () => {
            return this.sampleRecord;
        };
        this.SetRow = (index, property, data) => {
            this.sampleRecord.entries[index][property] = data;
        };
        this.AddRow = (data) => {
            this.sampleRecord.entries.push(data);
        };
        this.MoveRow = (newObject) => {
            this.sampleRecord = newObject;
        };
        this.GetRequests = () => {
            return this.httpClient.post(this.quaditUrl + '/api/req/getRequests', {
                UserName: ''
            });
        };
        this.UpdateRequest = (data) => {
            return this.httpClient.post(this.quaditUrl + '/api/req/updateRequest', {
                PoleId: null,
                PhaseId: null,
                DetailType: null,
                ReportId: null,
                ChartId: null,
                Delete: null,
                OptionalLabel: null,
                AuxiliaryData: data
            });
        };
        this.ReorderRequest = (data) => {
            return this.httpClient.post(this.quaditUrl + '/api/req/reorderRequest', {
                PoleId: null,
                PhaseId: null,
                DetailType: null,
                ReportId: null,
                ChartId: null,
                Delete: null,
                OptionalLabel: null,
                AuxiliaryData: data
            });
        };
        this.SubmitRequest = (data) => {
            return this.httpClient.post(this.quaditUrl + '/api/req/submitRequest', {
                PoleId: null,
                PhaseId: null,
                DetailType: null,
                ReportId: null,
                ChartId: null,
                Delete: null,
                OptionalLabel: null,
                AuxiliaryData: data
            });
        };
        this.DeleteRequest = (data) => {
            return this.httpClient.post(this.quaditUrl + '/api/req/deleteRequest', {
                PoleId: null,
                PhaseId: null,
                DetailType: null,
                ReportId: null,
                ChartId: null,
                Delete: null,
                OptionalLabel: null,
                AuxiliaryData: data
            });
        };
        this.FileUpload = (data) => {
            return this.httpClient.post(this.quaditUrl + "/api/req/uploadRequestFile", {
                PoleId: null,
                PhaseId: null,
                DetailType: null,
                ReportId: null,
                ChartId: null,
                Delete: null,
                OptionalLabel: null,
                AuxiliaryData: data
            });
        };
    }
    arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
    // Test Function
    GetMarkets(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.get(this.quaditUrl + '/api/qm/markets');
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/mktcontrol/usermarkets', {
                    UserName: userName
                });
                break;
        }
    }
    GetRoles(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/utiliRole/gridroles', {
                    UserName: userName
                });
                break;
        }
    }
    GetUsers(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
        }
    }
}
ProjectRequestService.authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
});
ProjectRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectRequestService_Factory() { return new ProjectRequestService(i0.ɵɵinject(i1.HttpClient)); }, token: ProjectRequestService, providedIn: "root" });

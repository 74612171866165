import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './myaccount.component';
import { HttpClientModule } from '@angular/common/http';
import { UseraccountModule } from '../useraccount/useraccount.module'
@NgModule({
  imports: [
    UseraccountModule,
    HttpClientModule,
    CommonModule
  ],
  declarations: [MyAccountComponent]
})
export class MyAccountModule { }

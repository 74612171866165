import $ from 'jquery';
import { AppService } from 'src/app/app.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConsoleLogger } from '../common/console.log.service';
import { Environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { Toastr } from '../common/toastr.service';
import { UserCredentials } from 'src/models/interfaces/user.credentials';

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("recaptcha", { static: true }) recaptchaElement: ElementRef;

  public environmentUrl: string;
  public recaptchaShow: boolean;
  public userCredentials: UserCredentials;

  constructor(
    private appSrv: AppService,
    private consoleLogger: ConsoleLogger,
    private loginService: LoginService,
    private router: Router,
    private toastr: Toastr
  ) {}

  ngOnInit() {
    this.environmentUrl = Environment.assetsUrl;
    this.recaptchaShow = false;
    this.userCredentials = {
      grant_type: "password",
      password: null,
      userName: null,
      webjwt: null,
    };
    this.appSrv.ClearUserProfile();
    this.addRecaptchaScript();

    $(function () {
      $(".sdTab").click(function () {
        $.ajax(
          "https://quesit.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/5epbf3/b/17/e73395c53c3b10fde2303f4bf74ffbf6/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=13159ee9",
          {
            type: "get",
            cache: true,
            dataType: "script",
            success: function (response) {
              window.location.href = "https://support.quantawestllc.com/";
            },
            error: function (response) {
              alert(
                "Unable to lodge support ticket. Please support directly:\nEmail: support@quantawestllc.com\nPhone: (866) 611-1136"
              );
            },
          }
        );
      });
    });
  }

  /***** Recaptcha Setup & Rendering *****/

  addRecaptchaScript() {
    window["grecaptchaCallback"] = () => {
      this.renderReCaptcha();
    };

    (function (d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) {
        obj.renderReCaptcha();
        return;
      }

      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "recaptcha-jssdk", this);
  }

  renderReCaptcha() {
    window["grecaptcha"].render(this.recaptchaElement.nativeElement, {
      sitekey: "6LfkzIMUAAAAAKOT1Az9sNGyvtlnGO2icyYjovWI",
      callback: (response) => {
        this.appSrv.RecaptchaResponseSet(response);
      },
    });
  }

  /***** Login Methods *****/

  FailureReset(failRes: any) {
    this.appSrv.ClearUserProfile();
    this.appSrv.FullNameSet(null);
    this.appSrv.ShowSpinnerSet(false);
    this.toastr.ShowErrorResponse(failRes);
  }

  SaveFullName(loginUn: string) {
    this.loginService.FetchUserName(this.userCredentials.userName).subscribe(
      (success) => {
        this.appSrv.FullNameSet(success.userName);
      },
      (failure) => {
        this.appSrv.FullNameSet(loginUn);
        this.consoleLogger.LogStatusToConsole(
          failure,
          "Fetch User Name Failed."
        );
      }
    );
  }

  SaveUserAvatar(loginUn: string) {
    this.loginService.FetchUserAvatar(this.userCredentials.userName).subscribe(
      (success) => {
        this.appSrv.AvatarImageSet(success.image);
      },
      (failure) => {
        this.appSrv.AvatarImageSet(null);
        this.consoleLogger.LogStatusToConsole(
          failure,
          "Fetch User Avatar Failed."
        );
      }
    );
  }

  /***** Events *****/

  onSubmit() {
    this.appSrv.ShowSpinnerSet(true);

    if (this.recaptchaShow && !this.appSrv.RecaptchaResponseGet()) {
      this.toastr.ShowWarning(
        "Please complete the ReCaptcha challenge.",
        "ReCaptcha Answer Required"
      );
      this.appSrv.ShowSpinnerSet(false);
    } else {
      this.loginService.IsPwReset(this.userCredentials.userName).subscribe(
        (success) => {
          if (success.passwordReset) {
            this.toastr.ShowWarning(
              "Your password has been reset. Check your email for instructions to update with a new password."
            );
          } else {
            this.loginService
              .GenerateSeedApi(this.userCredentials.userName)
              .subscribe(
                (success) => {
                  console.log(success.urlVariable);
                  this.appSrv.UrlVariableSet(success.urlVariable);
                  this.userCredentials.webjwt = decodeURIComponent(
                    success.urlVariable.split("&")[2].split("=")[1]
                  );

                  this.loginService
                    .UserAuthentication(this.userCredentials)
                    .subscribe(
                      (success) => {
                        sessionStorage.setItem("jwt", success.access_token);

                        this.appSrv.RecaptchaResponseSet(null);
                        this.appSrv.SetUserProfile(
                          success.access_token,
                          this.userCredentials.webjwt,
                          this.userCredentials.userName
                        );
                        this.SaveUserAvatar(this.userCredentials.userName);
                        this.SaveFullName(this.userCredentials.userName);

                        this.loginService
                          .SaveJsonWebToken(
                            success.access_token,
                            this.userCredentials.webjwt
                          )
                          .subscribe(
                            () => {
                              this.loginService
                                .GotoMyAccount(this.userCredentials.userName)
                                .subscribe(
                                  (success) => {
                                    this.appSrv.ShowDashboardSet(true);

                                    if (success.goToMemberAccount) {
                                      this.appSrv.GotoMyAccountSet(true);
                                      this.appSrv.SetCurrentState("myaccount");
                                      this.appSrv.ShowSpinnerSet(false);
                                      this.router.navigate(["myaccount"]);
                                    } else {
                                      this.appSrv.GotoMyAccountSet(false);
                                      this.appSrv.ShowSpinnerSet(false);

                                      if (this.appSrv.FileDownloadGet()) {
                                        this.appSrv.SetCurrentState(
                                          "filedownload"
                                        );
                                        this.router.navigate(["filedownload"]);
                                      } else {
                                        this.appSrv.SetCurrentState("linkmenu");
                                        this.router.navigate(["linkmenu"]);
                                      }
                                    }
                                  },
                                  (failure) => {
                                    this.FailureReset(failure);
                                  }
                                );
                            },
                            (failure) => {
                              this.FailureReset(failure);
                            }
                          );
                      },
                      (failure) => {
                        console.log(failure);
                        if (
                          failure.error.error ==
                          this.appSrv.AppSettingsGet().reCaptchaError
                        ) {
                          this.recaptchaShow = true;
                        } else {
                          this.recaptchaShow = false;
                        }
                        this.FailureReset(failure);
                      }
                    );
                },
                (failure) => {
                  this.FailureReset(failure);
                }
              );
          }
        },
        (failure) => {
          this.FailureReset(failure);
        }
      );
    }
  }
}

import { AdminSelect } from 'src/models/interfaces/admin.select';
import { Environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAssign } from 'src/models/interfaces/user.assign';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NewUserService {

  static readonly authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
  });

  static readonly fileHeader = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'multipart/form-data'
  });

  private baseUrl: string = Environment.serverPath;
  private quaditUrl: string = Environment.quaditPath;
  private utmeterUrl: string = Environment.utmeterPath;

  private localUrl: string = "http://localhost:63000";
  private localQauditUrl: string = "http://localhost:62029";
  //private localUtilimeterUrl: string = "http://localhost:64655";

  constructor(private httpClient: HttpClient) { }

  SetStagingUser(stagingUser) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/stagingfirststep',
    { 
        Company: stagingUser.company,
        ConfirmPassword: stagingUser.confirmPassword !== null ? stagingUser.confirmPassword : null,
        Email: stagingUser.email,
        FirstName: stagingUser.firstname,
        LastName: stagingUser.lastname,
        Password: stagingUser.password !== null ? stagingUser.password : null,
        Portalrole: stagingUser.role,
        UserName: stagingUser.username,
        Id: stagingUser.username
    },
    { headers: NewUserService.authHeaders });
  }

  setStagingLinks = (sites, admin, user) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/stagingsecondstep',
    { 
        SitesSelected: sites,
        AdminUserName: admin, 
        UserName: user
    },
    { headers: NewUserService.authHeaders });
  };

  setStagingDetails = (user, siteDetailsSelected) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/stagingthirdstep',
    { 
        UserName: user,
        SitesDetailsSelected: siteDetailsSelected
    },
    { headers: NewUserService.authHeaders });
  };

  setMarketStagingDetails = (user, siteDetailsSelected) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/stagingfourthstep',
    { 
        UserName: user,
        SitesDetailsSelected: siteDetailsSelected
    },
    { headers: NewUserService.authHeaders });
  };

  finalize = (user, edit) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/finalize',
    {
        UserName: user,
        SubUserName: user,
        IsEdit: edit
    },
    { headers: NewUserService.authHeaders })
  }

  cancelUser = (user) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/deletestaging',
    {
      UserName: user
    },
    { headers: NewUserService.authHeaders });
  }

  uploadAvatar = (avatar, user) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/uploadAvatar',
    {
      UserName: user
    },
    { headers: NewUserService.fileHeader });
  }

  // Test Function
  GetMarkets(site:string, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/subordinatemarkets',
        {
          userName: userName
        }, 
        { headers: NewUserService.authHeaders });
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/mktcontrol/usermarkets',
        {
          UserName: userName
        }, 
        { headers: NewUserService.authHeaders });
        break;
      default:
        let observable = Observable.create(observer => {
          setTimeout(() => {
            let users = "No roles for the selected sites";
        
            observer.next(users); // This method same as resolve() method from Angular 1
            observer.complete();//to show we are done with our processing
            // observer.error(new Error("error message"));
          });
        
        })
        return observable;
    }
    
  }

  GetRoles(site:String, userName:String) {
    switch (site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
      case "utilimeter" :
        return this.httpClient.post<any>(this.utmeterUrl + '/api/utiliRole/gridroles',
        {
        	UserName : userName
        });
        break;
      default:
        //let observable = of('test');
        let observable = Observable.create(observer => {
          setTimeout(() => {
            let users = "No roles for the selected sites";
        
            observer.next(users); // This method same as resolve() method from Angular 1
            observer.complete();//to show we are done with our processing
            // observer.error(new Error("error message"));
          });
        
        })
        return observable;
    }
  }

  GetUsers(site:string, userName:String) {
    switch(site) {
      case "qmanage" :
        return this.httpClient.post<any>(this.quaditUrl + '/api/qm/gridroles',
        {
          IsWebAdmin : true,
        	UserName : userName
        });
        break;
    }
  }

  CheckUserName(userName:String) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/checkusername',
      {
        UserName : userName
      });
  }
  
  CheckEmail(email:String) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/checkemail',
      {
        email : email
      });
  }

  GetAllLinks() {
    return this.httpClient.get<any>(this.baseUrl + '/api/utililink/getalllinks')
  }

  GetGridUsers(userName:string) {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/gridusers',
    { 
      UserName: userName, 
    },
    { headers: NewUserService.authHeaders });
  }

  GetCurrentUserInfo = (userName:string, isWebAdmin:boolean) => {
    return this.httpClient.post<any>(this.baseUrl + '/api/accounts/currentuserinfo',
    { 
      UserName: userName, 
      subUserName: userName,
      //isWebAdmin: isWebAdmin
    },
    { headers: NewUserService.authHeaders });
  };

  /***** Parsing Methods *****/

  ParseImageUrl(url: UserAssign): string {
  
    if (url == undefined) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
    if (url.image == null) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';

    return 'data:image/JPEG;base64,' + url.image;
  }

  ParseUserDetailImageUrl(userImage: string): string {
  
    if (userImage == undefined || userImage == null) return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';

    return 'data:image/JPEG;base64,' + userImage;
  }  
}
import { AppService } from '../app.service';
import { Component, OnInit } from '@angular/core';
import { Environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: "user-access",
  templateUrl: "./useraccessrequest.component.html",
  styleUrls: ["./useraccessrequest.component.scss"],
})
export class UserAccessRequestComponent implements OnInit {
  /* static readonly anonHeaders = new HttpHeaders({
    'Anonymous': 'true',
    'Content-Type': 'application/json'
  }); */

  private baseUrl: string = Environment.serverPath;
  private localUrl: string = "http://localhost:63000";
  private localQauditUrl: string = "http://localhost:62029";
  locations: Array<String> = [];
  teststring: string = "STRING HERE";
  submitted: boolean = false;
  loading: boolean = false;
  userform: boolean;
  userAccessReqTypes: Array<string> = [
    "New User Access",
    "Update User Access",
    "Revoke User Access",
  ];
  commonRadioOptions: Array<string> = ["Yes", "No", "Not Applicable"];
  uarf: any = {
    serviceDeskId: "3",
    requestTypeId: "27",
    requestFieldValues: {
      customfield_10037: null, //Name Field
      customfield_10040: "", //Email Address
      customfield_10041: [{ id: null }], //User single access account
      customfield_10044: null, //Title
      customfield_10046: { value: null }, //Location
      customfield_10047: null, //Group
      customfield_10048: { value: null }, //User Access Request Type
      customfield_10049: [{ value: "" }], //Windows Account
      customfield_10050: [{ value: "" }], //Domain File Share Access
      customfield_10051: { id: "-1" }, //File Share Access Rights
      customfield_10053: { value: null }, //Does the requested access adhere to the defined access privelege levels
      customfield_10054: { value: null }, //Will this User Access Request affect the current Segregation fo Duties Matrix
      customfield_10055: { value: null }, //Has the Segregation of Duties Matrix been updated
      customfield_10056: [{ id: null }], //Access Acknowledgement
      customfield_10057: { id: null }, //Does this user need an email created for them
      description: null,
    },
    requestParticipants: [],
  };
  form: any = document.forms.namedItem("userForm");

  constructor(
    private router: Router,
    private appSrv: AppService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    //this.getCurrentUser();
    var tempArray = [];
    this.getCurrentUser();
    //this.getRequestParticipants(this.getCurrentUser()).subscribe(data => { this.setLocations(data.locations) });
    //this.createJiraRequest(this.getCurrentUser()).subscribe(data => { console.log(data) });
  }

  setLocations(locations) {
    this.locations = locations;
  }

  goToSupportDesk() {
    window.open("https://support.quantawestllc.com/", "_blank");
  }

  getCurrentUser() {
    //console.log(this.appSrv.GetUserProfileObservable());
    /* const userSubscription = this.appSrv.GetUserProfileObservable().subscribe({
      next(data) { return data.jwt }
    }) */
    this.appSrv.GetUserProfileObservable().subscribe((success) => {
      this.getRequestParticipants(success.jwt).subscribe((success) => {
        console.log(success);
        this.setLocations(success.locations);
      });
    });
  }

  createJiraRequest(jwt: any) {
    console.log(this.uarf);
    /* var scope;

    scope.userAccessReqTypes = ["New User Access", "Update User Access", "Revoke User Access"];
    scope.locations = [];
    scope.commonRadioOptions = ["Yes", "No", "Not Applicable"];
    scope.submitted = false;
    scope.userInformation;
    scope.uarf = {};
    scope.loading = false; */

    /* var uarf = {
      "serviceDeskId": "3",
      "requestTypeId": "27",
      "requestFieldValues": {
        "customfield_10037": null, //Name Field
        "customfield_10040": "", //Email Address
        "customfield_10041": [{ "id": null }], //User single access account
        "customfield_10044": null, //Title
        "customfield_10046": { "value": null }, //Location
        "customfield_10047": null, //Group
        "customfield_10048": { "value": null }, //User Access Request Type
        "customfield_10049": [{ "value": "" }], //Windows Account
        "customfield_10050": [{ "value": "" }], //Domain File Share Access
        "customfield_10051": { "id": "-1" }, //File Share Access Rights
        "customfield_10053": { "value": null }, //Does the requested access adhere to the defined access privelege levels
        "customfield_10054": { "value": null }, //Will this User Access Request affect the current Segregation fo Duties Matrix
        "customfield_10055": { "value": null }, //Has the Segregation of Duties Matrix been updated
        "customfield_10056": [{ "id": null }], //Access Acknowledgement
        "customfield_10057": { "id": null }, //Does this user need an email created for them
        "description": null
      },
      "requestParticipants": []
    } */

    var formData = new FormData(document.forms.namedItem("userForm"));
    formData.append("data", this.uarf);
    console.log(formData);
    return this.httpClient.post<any>(
      this.localUrl + "/api/jira/createrequest",
      {
        headers: {
          Authorization: "Bearer " + jwt,
          "X-ExperimentalApi": "opt-in",
          "X-Atlassian-Token": "no-check",
          "Content-Type": undefined,
          transformRequest: (arg) => {
            return arg;
          },
          data: JSON.stringify(this.uarf),
        },
        isArray: false,
      }
    );
  }

  getRequestParticipants(jwt: any) {
    //let params = new HttpParams().set('userName', loginUn);

    return this.httpClient.get<any>(
      this.baseUrl + "/api/jira/jiraservicedeskconfig",
      {
        headers: {
          Authorization: "Bearer " + jwt,
        },
      }
    );
  }

  /* getRequestParticipants(jwt:any) {
    return $resource(buildSettings.serverPath + "/api/jira/jiraservicedeskconfig",
        null,
        {
            query: {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + jwt
                },
                isArray: false
            }
        });
  } */

  submitForm(e) {
    e.preventDefault();
    console.log(this.uarf);
    this.createJiraRequest(this.getCurrentUser()).subscribe((data) => {
      console.log(data);
    });
  }

  trueFalseValue(input, value) {
    this.uarf.requestFieldValues["customfield_" + input][0].id = value;
  }

  setValue(e) {
    this.uarf.requestFieldValues.customfield_10046.value =
      e.currentTarget.value;
  }

  navigateSideNav(e) {
    var location = e.currentTarget.innerText.trim().toLowerCase();

    switch (location) {
      case "account":
        this.router.navigate(["myaccount"]);
        break;
      case "dashboard":
        this.router.navigate(["linkmenu"]);
        break;
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './userlist.component';
import { DragDropModule  } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    DragDropModule,
    NgbModule
  ],
  declarations: [
    UserListComponent
  ],
  exports: [
    UserListComponent
  ]
})

export class UserListModule { }
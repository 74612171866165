import { Environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserAssignmentService {
    //private baseUrl: string = "http://localhost:63000";
    //private localQauditUrl: string = "http://localhost:62029";
    //private localUtilimeterUrl: string = "http://localhost:64655";
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = Environment.serverPath;
        this.quaditUrl = Environment.quaditPath;
        this.utmeterUrl = Environment.utmeterPath;
        this.localUrl = "http://localhost:63000";
        this.GetRolesForSelectedSites = (sites, userName) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/rolesforselectedsites', {
                SelectedSites: sites,
                UserName: userName,
                SubUserName: userName,
                IsWebAdmin: true
            }, { headers: UserAssignmentService.authHeaders });
        };
        this.GetMarketsForSelectedSites = (sites, userName) => {
            return this.httpClient.post(this.baseUrl + '/api/accounts/marketsforselectedsites', {
                SelectedSites: sites,
                UserName: userName,
                SubUserName: userName,
                IsWebAdmin: true
            }, { headers: UserAssignmentService.authHeaders });
        };
    }
    DeleteAdminAssignment(asKvp) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/deleteAssign', asKvp, { headers: UserAssignmentService.authHeaders });
    }
    GetAdminAssigned() {
        return this.httpClient.post(this.baseUrl + '/api/accounts/getAdminAssigned', null, { headers: UserAssignmentService.authHeaders });
    }
    GetRoleNameAvatar() {
        //console.log(this.localUrl + '/api/accounts/getAdminAssignmentUsers');
        return this.httpClient.post(this.baseUrl + '/api/accounts/getAdminAssignmentUsers', null, { headers: UserAssignmentService.authHeaders });
    }
    GetShowUserDetails({ un }) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/getUserDetails', { userName: un }, { headers: UserAssignmentService.authHeaders });
    }
    SetAdminAssignment(aName, assName) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/addAssign', { adminName: aName, assigneeName: assName }, { headers: UserAssignmentService.authHeaders });
    }
    SetSiteRole(roleId, adminName, userName) {
        //console.log(roleId + " " + userName + " " + adminName);
        return this.httpClient.post(this.quaditUrl + '/api/qm/setrole', {
            SetRoleId: roleId,
            SubUserName: userName,
            UserName: adminName
        }, { headers: UserAssignmentService.authHeaders });
        //return { SetRoleId: roleId, adminName: adminName, userName: userName };
    }
    // Test Function
    GetMarkets(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/subordinatemarkets', {
                    IsWebAdmin: true,
                    SubUserName: userName,
                    UserName: userName
                });
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/mktcontrol/usermarkets', {
                    UserName: userName
                });
                break;
        }
    }
    GetRoles(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/utiliRole/gridroles', {
                    UserName: userName
                });
                break;
        }
    }
    GetUsers(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
        }
    }
    /***** Parsing Methods *****/
    ParseImageUrl(url) {
        if (url == undefined)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        if (url.image == null)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        return 'data:image/JPEG;base64,' + url.image;
    }
    ParseUserDetailImageUrl(userImage) {
        if (userImage == undefined || userImage == null)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        return 'data:image/JPEG;base64,' + userImage;
    }
}
UserAssignmentService.authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
});
UserAssignmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAssignmentService_Factory() { return new UserAssignmentService(i0.ɵɵinject(i1.HttpClient)); }, token: UserAssignmentService, providedIn: "root" });

import { Environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserManagementService {
    //private baseUrl: string = "http://localhost:63000";
    //private localQauditUrl: string = "http://localhost:62029";
    //private localUtilimeterUrl: string = "http://localhost:64655";
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.baseUrl = Environment.serverPath;
        this.quaditUrl = Environment.quaditPath;
        this.utmeterUrl = Environment.utmeterPath;
        this.localUrl = 'http://localhost:63000';
    }
    SetStagingUser(stagingUser) {
        console.log({
            //Company: stagingUser.company,
            ConfirmPassword: '',
            Email: stagingUser.email,
            FirstName: stagingUser.firstname,
            LastName: stagingUser.lastname,
            Password: '',
            Portalrole: stagingUser.role,
            UserName: stagingUser.username,
            Id: stagingUser.username
        });
        return this.httpClient.post(this.baseUrl + '/api/accounts/stagingfirststep', {
            //Company: stagingUser.company,
            ConfirmPassword: 'testpassword',
            Email: stagingUser.email,
            FirstName: stagingUser.firstname,
            LastName: stagingUser.lastname,
            Password: 'testpassword',
            Portalrole: stagingUser.role,
            UserName: stagingUser.username,
            Id: stagingUser.username
        }, { headers: UserManagementService.authHeaders });
        //return { SetRoleId: roleId, adminName: adminName, userName: userName };
    }
    // Test Function
    GetMarkets(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.get(this.quaditUrl + '/api/qm/subordinatemarkets');
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/mktcontrol/usermarkets', {
                    UserName: userName
                });
                break;
        }
    }
    GetRoles(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
            case "utilimeter":
                return this.httpClient.post(this.utmeterUrl + '/api/utiliRole/gridroles', {
                    UserName: userName
                });
                break;
        }
    }
    GetUsers(site, userName) {
        switch (site) {
            case "qmanage":
                return this.httpClient.post(this.quaditUrl + '/api/qm/gridroles', {
                    IsWebAdmin: true,
                    UserName: userName
                });
                break;
        }
    }
    GetGridUsers(userName) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/gridusers', {
            UserName: userName,
        }, { headers: UserManagementService.authHeaders });
    }
    LockUsers(userList) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/batchlock', {
            LockList: userList
        }, { headers: UserManagementService.authHeaders });
    }
    UnlockUsers(userList) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/batchunlock', {
            LockList: userList
        }, { headers: UserManagementService.authHeaders });
    }
    ResendInvite(userList) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/batchreinvite', {
            LockList: userList
        }, { headers: UserManagementService.authHeaders });
    }
    DeleteUser(userId) {
        return this.httpClient.post(this.baseUrl + '/api/accounts/delete', {
            deleteId: userId
        }, { headers: UserManagementService.authHeaders });
    }
    /***** Parsing Methods *****/
    ParseImageUrl(url) {
        if (url == undefined)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        if (url.image == null)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        return 'data:image/JPEG;base64,' + url.image;
    }
    ParseUserDetailImageUrl(userImage) {
        if (userImage == undefined || userImage == null)
            return Environment.assetsUrl + '/assets/images/avatars/noavatar.jpg';
        return 'data:image/JPEG;base64,' + userImage;
    }
}
UserManagementService.authHeaders = new HttpHeaders({
    'Anonymous': 'false',
    'Content-Type': 'application/json'
});
UserManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });

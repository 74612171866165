import { AppService } from '../app.service';
import { Component, OnInit } from '@angular/core';
import { Environment } from '../../environments/environment';
import { IMenuLink } from 'src/models/interfaces/IMenuLink';
import { LinkmenuService } from './linkmenu.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/internal/operators';
import { UserProfile } from 'src/models/interfaces/user.profile';

@Component({
  selector: "linkmenu",
  templateUrl: "./linkmenu.component.html",
  styleUrls: ["./linkmenu.component.scss"],
})
export class LinkMenuComponent implements OnInit {
  public linkMenuObsersable$: Observable<IMenuLink>;
  private alive: boolean = true;

  constructor(
    private appService: AppService,
    private linkMenuService: LinkmenuService,
    private router: Router
  ) {}

  ngOnInit() {
    this.GetUserProfile();
  }

  GetUserProfile = () => {
    this.appService
      .GetUserProfileObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((profile: UserProfile) => this.GetMenuLinks(profile.userName));
  };

  GetMenuLinks = (userName: string) =>
    (this.linkMenuObsersable$ = this.linkMenuService.GetMenuLinks(userName));

  GetIconImage = (icon): string => `${Environment.assetsUrl}/assets/${icon}`;

  NavigationTo = (url): string =>
    (window.location.href = `${url}/?${this.appService.UrlVariableGet()}`);

  NavigateRouter = () => {
    //this.router.navigate(['projectrequest']);
    window.location.href = "http://localhost:4200/projectrequest";
  };

  ngOnDestroy(): void {
    console.log("this.linkMenuObsersable$ = ", this.linkMenuObsersable$);
    this.alive = false;
  }
}
